<template>
  <div class="guides-page">
    <div>
      <h1>
        Taobao Shopping Guide using TokCommerce
      </h1>
      <p>
        Posted on 8 April 2022
      </p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#preparation">Preparation</a></li>
        <li><a href="#choose-products">Choose your Products from the Taobao Website</a></li>
        <li>
          <a href="#place-order">Placing a Shopping Order with TokCommerce</a>
          <ul>
            <li><a href="#direct-buy">Direct Buy using URL</a></li>
            <li><a href="#expert-buy">Expert Buy</a></li>
          </ul>
        </li>
        <li><a href="#first-payment">First Payment (Product Price + Domestic Shipping Fee)</a></li>
        <li><a href="#special-arrangements">Special Shopping Order Arrangements</a></li>
        <li><a href="#second-payment">Second Payment (International Freight Forwarding Fee)</a></li>
        <li><a href="#manage-order">Managing your Order using the TokCommerce Website</a></li>
      </ol>
      <p>
        Taobao is one of China’s largest and most famous shopping websites with a wide variety of products at a cheap price.
      </p>
      <p>
        Since the Taobao website is entirely in Chinese, you may find it difficult and frustrating to navigate through
        the website by yourself if you do not know Chinese. Hence, we have written this English step-by-step guide will
        take you through the process of purchasing products from Taobao using TokCommerce as your shopping agent.
      </p>
    </div>
    <div>
      <b id="preparation" class="subtitle">
        Preparation
      </b>
      <p>
        In order to shop from Taobao using TokCommerce as your shopping agent, a TokCommerce account is all you need.
        That’s right, you don’t even need Taobao account!
      </p>
      <p>
        If you already have an existing account, you can log into your account <a href="/login">here.</a>
      </p>
      <p>
        Otherwise, you can register for a new account <a href="/register">here.</a>
      </p>
      <p>
        <em>
          When registering for a new account, make sure that you are using a valid email address that you are active on,
          since we will be sending emails to your provided email address in the future.
        </em>
      </p>
    </div>
    <div>
      <b id="choose-products" class="subtitle">
        Choose your Products from the Taobao Website
      </b>
      <p>
        From the plethora of products that Taobao offers, choose those that are to your liking and note down the URL
        of the product page of the products that you wish to purchase.
      </p>
      <img src="/img/guides/taobao-product.png"/><br><br>
    </div>
    <div>
      <b id="place-order" class="subtitle">
        Placing a Shopping Order with TokCommerce
      </b>
      <p>
        Once you have the URL of the product page of a product that you wish to purchase from Taobao, you are ready to
        place a shopping order with us on the TokCommerce website.
      </p>
      <p>
        <em>
          If you have multiple products or variations of the same product you wish to purchase, please submit each
          product and variation as a single shopping order. You can choose to pay for and ship all of them together
          when the products arrive at our warehouse.
        </em>
      </p>
      <p>
        After logging into your TokCommerce account, click on the “Shopping Agent” tab in the header section of the
        TokCommerce website to be redirected to the Shopping Service Page. You may also directly access this page
        <a href="/search">here.</a>
      </p>
      <u id="direct-buy" class="subtitle">
        Direct Buy using URL
      </u>
      <p>
        In the top section of the Shopping Service page titled “Shopping Service”, enter the URL of the product page of
        the product you wish to purchase from Taobao in the search bar and click on the search icon.
      </p>
      <img src="/img/guides/product-url.png"/><br><br>
      <p>
        This should open a new browser tab displaying information on the product on the TokCommerce website,
        similar to the screenshot shown below.
      </p>
      <img src="/img/guides/tokcommerce-product-page.png"/><br><br>
      <p>
        However, we may not be able to fetch information on some products offered by Taobao, and you may encounter
        an error screen, indicating that your product could not be found.
      </p>
      <p>
        Fret not, for we have got you covered! Skip ahead to the <a href="#expert-buy">“Expert Buy” section</a> of this
        guide to find out how to purchase these types of products.
      </p>
      <p>
        Now that you can view the product information on the TokCommerce website, you are only a few steps away from
        placing your shopping order.
      </p>
      <p>
        First, check that the product information displayed are the same as that displayed
        on the Taobao website, which includes the product pictures, product name and product price. If the product has
        additional specifications, such as colour, size, etc., please also check that the different variations of the
        product are displayed correctly as per the Taobao website.
      </p>
      <p>
        If you notice any discrepancies with any of the product information, we would, once again, recommend that you
        skip ahead to the <a href="#expert-buy">“Expert Buy” section</a> of this guide to find out how to purchase
        these types of products using our Expert Buy feature.
      </p>
      <p>
        Once you have confirmed that all the product information is identical between the TokCommerce and Taobao
        website, select the quantity and variation of the product you wish to purchase, wherever applicable.
      </p>
      <p>
        You are almost there! Do one final check on the product and the additional information that you are required
        to fill in and provide, and then click on the “Add to cart” button to submit your shopping order.
      </p>
      <u id="expert-buy" class="subtitle">
        Expert Buy
      </u>
      <p>
        For products from the Taobao website for which its product information our website cannot obtain, we also have
        the Expert Buy feature so that our customers are still able to purchase these products.
      </p>
      <p>
        Navigate to the Expert Buy page by clicking the “Expert Buy” button under the section titled “Expert Buy”
        on the Shopping Service page. You may also access this page <a href="/service/source">here.</a>
      </p>
      <p>
        Enter the relevant information into the form on the Expert Buy page.
      </p>
      <p>
        <em>
          The product URL is the URL of the product page of the product that you wish to purchase, similar to that
          mentioned in the previous section.
        </em>
      </p>
      <p>
        <em>
          As for the product screenshot, you can simply screenshot one of the product images provided on the product
          page of the product on the Taobao website.
        </em>
      </p>
      <p>
        <em>
          Please also fill in product remarks such as the size, colour, product version, etc. of the product, wherever
          applicable.
        </em>
      </p>
      <p>
        <em>
          Please ensure that the product information that is provided within the form is identical to that on the
          product page of the product on the Taobao website. This is because our staff will use the information
          provided to verify the product URL.
        </em>
      </p>
      <p>
        Once you have filled in and verified the relevant product information, click on the “Add to cart” button at the
        bottom of the form to submit your Expert Buy shopping order.
      </p>
    </div>
    <div>
      <b id="first-payment" class="subtitle">
        First Payment
      </b>
      <p>
        The first payment is made for the acquisition of the product from Taobao. This includes the price of the
        product that you are buying, as well as the domestic shipping fee of the product from the seller to our
        TokCommerce warehouse, if applicable.
      </p>
      <p>
        Once you have submitted shopping orders for all the products that you wish to purchase from Taobao,
        it is time to check them out and pay for them.
      </p>
      <p>
        Navigate to your cart by clicking on the corresponding tab in the header section of the TokCommerce website.
      </p>
      <p>
        Check that the information on each of your individual shopping orders are correct before checking it out.
        Once you have selected all the shopping orders you wish to checkout, click the “Pay Now” button to be
        redirected to the Confirm Purchase page.
      </p>
      <p>
        On this page, take note of the freight cost displayed under the freight column in the table. This is the
        domestic shipping fee of the product as explained in an earlier paragraph. Select your destination country
        where you wish to forward the products to, and click on the “Submit Order” button to be redirected to the
        Checkout page.
      </p>
      <p>
        Lastly, make payment for your products by clicking on the “Pay Now” button and following the payment
        instructions on the subsequent pages. It may also be useful to take note of the order number of your shopping
        orders as displayed on the page.
      </p>
      <p>
        Once you have made your first payment, sit tight while we source and buy your products for you from Taobao!
      </p>
    </div>
    <div>
      <b id="special-arrangements" class="subtitle">
        International Freight Forwarding Arrangements
      </b>
      <p>
        As soon as your product arrives at our TokCommerce warehouse, we will send an email to your verified email
        address to update you on your order.
      </p>
      <p>
        <em>
          If you have any special requests that you would like to make with regards to your products before shipping
          them, you can also inform us via email and we will service them to the best of our abilities.
        </em>
      </p>
      <p>
        Navigate to the My Orders page by clicking on the “Account” tab in the header section of the TokCommerce
        website and selecting "My Orders" in the dropdown menu.
      </p>
      <p>
        After being redirected, click the “In Warehouse” tab in the left panel to view the shopping orders for which
        the products have arrived at our TokCommerce warehouse.
      </p>
      <p>
        Select the shopping orders for which its products you want to ship, and then click the “Submit to Ship”
        button at the top of the page.
      </p>
      <p>
        <em>
          Selecting multiple orders at this step indicates that you wish to ship them together. If you would like to ship
          multiple groups of orders together, please complete this step separately for each group.
        </em>
      </p>
      <p>
        <em>
          You may also choose to ship your shopping and freight forwarding orders together at this step. Just wait for all your
          product(s) or package(s) to arrive at our TokCommerce warehouse before selecting the corresponding orders
          to indicate that you wish to ship them together.
        </em>
      </p>
      <p>
        Enter the delivery address you wish to ship your products to and double check that the address is correct before
        proceeding.
      </p>
      <p>
        Next, select the freight forwarding option of your choice from the list of shipping lines that are available to
        ship to your destination country. The freight forwarding costs and estimated shipping times for each shipping line
        are also made available to you to allow you to make a more informed decision based on your needs.
      </p>
      <p>
        Once you have confirmed and submitted the details for the freight forwarding of your orders, you can then move on to making
        payment for your freight forwarding fee.
      </p>
    </div>
    <div>
      <b id="second-payment" class="subtitle">
        Second Payment
      </b>
      <p>
        The second payment is made for the international freight forwarding fee.
      </p>
      <p>
        Navigate to the My Orders page, and click the "Int'l Parcels" tab in the left panel. From here, you should be
        able to view the orders that you have submitted for freight forwarding. Orders
        that are to be forwarded together will be displayed together under the same Delivery Number.
      </p>
      <p>
        Simply choose the order for which you would like to make payment for and click on the corresponding "Pay Order"
        button in the rightmost column.
      </p>
      <p>
        Follow the instructions on the subsequent pages to complete the payment of your freight forwarding fee.
      </p>
      <p>
        Once we have confirmed receipt of your payment, we will package and prepare your products for international
        freight forwarding at our warehouse.
      </p>
    </div>
    <div>
      <b id="manage-order" class="subtitle">
        Managing your Orders with the TokCommerce Website
      </b>
      <p>
        Once you have completed both payments, all that is left to do is to wait for your product to arrive right at
        your doorstop!
      </p>
      <p>
        You may use the My Orders page mentioned in the previous section to track the progress of your orders.
      </p>
      <ul>
        <li>
          Orders that have been submitted but have yet to be paid for the first payment or have not arrived at our
          warehouse will appear under the "My Orders" tab.
        </li>
        <li>
          Orders that have arrived at our warehouse but have yet to be paid for the second payment or have not been
          shipped will appear under the "In Warehouse" tab.
        </li>
        <li>
          Orders that have been submitted for freight forwarding or have been shipped out of our warehouse
          will appear under the "Int’l Parcels" tab.
        </li>
        <li>
          Orders that have been completed will appear under the "Order History" tab.
        </li>
      </ul>
      <p>
        Alternatively, you can also join any of our social media communities and post your queries there for our
        community members or customer staff members to reply to them.
      </p>
    </div>
    <b class="subtitle">Happy shopping on Taobao and we look forward to serving you again in the future!</b>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideTaobaoTokCommerce",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'Best Taobao Agent From China',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: How to shop from Taobao by TokCommerce.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Best Taobao Agent From China' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: How to shop from Taobao by TokCommerce.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-taobao-tokcommerce' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
