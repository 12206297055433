import request_api from '@/utils/request_api'

//订单分页获取
export function getOrderList(data) {
  return request_api({
    url: '/api/order/get_order_list',
    method: 'post',
    data
  })
}

//订单详情获取
export function getOrderDetail(data) {
  return request_api({
    url: '/api/order/get_order_detail',
    method: 'post',
    data
  })
}


//服务 - 订单
export function sendOrderRequest(data) {
  return request_api({
    url: '/api/order/send_order_request',
    method: 'post',
    data
  })
}

export function virtualHandlePayment(data) {
  return request_api({
    url: '/api/pay/virtual_handle_payment',
    method: 'post',
    data
  })
}

export function payOrder(data) {
  return request_api({
    url: '/api/pay/pay_order',
    method: 'post',
    data
  })
}

export function getOrderRecords(data) {
  return request_api({
    url: '/api/order/get_order_records',
    method: 'post',
    data
  })
}


//采购 - 订单
export function sendSourceOrderRequest(data) {
  return request_api({
    url: '/api/order/send_source_order_request',
    method: 'post',
    data
  })
}

// 修改订单地址
export function updateOrderAddress(data) {
  return request_api({
    url: '/api/order/update_order_address',
    method: 'post',
    data
  })
}

// 获取信用卡签名
export function getCreditCardSign(data) {
  return request_api({
    url: '/api/pay/get_credit_car_sign',
    method: 'post',
    data
  })
}
