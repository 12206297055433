<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        :style="'background-image: url(' + show_data.bg_img + ')'"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">{{$t('page_seller.title')}}</h1>
          <div class="text-center" style="padding-top: 50px;">
            <h3>{{show_data.content}}</h3>

            <div style="width: 350px; margin: auto;">
              <div style="text-align: left;">
                <strong>{{$t('page_seller.step_line1')}}</strong>
                <router-link to="/search" style="color: #ffffff;">
                  {{$t('page_seller.step_line2')}}
                </router-link>
                <strong>{{$t('page_seller.step_line3')}}</strong>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="section_title">
          <strong>{{$t('page_shopping.category')}}</strong>
          <div style="display: flex; " class="row">
            <el-button v-for="(item, key) in (category_list)" :key="key" plain round @click="search(key)">{{item.title}}</el-button>

          </div>
          <el-divider></el-divider>
        </div>


        <div style="display: flex; justify-content: space-between; flex-wrap:wrap;">
          <div v-for="(item, key) in show_data.sellers" :key="key" style="width: 300px; min-width: 300px; margin: auto;">
            <el-card :body-style="{ padding: '0px' }" style="height: 350px; margin-top: 5px;">
              <div style="text-align: center; padding-top: 3px;">
                <el-image
                        style="width: 200px; height: 200px; margin: auto;"
                        :src="item.logo_link"
                        fit="fit">
                </el-image>
              </div>
              <div style="padding: 14px;">
                <strong style="margin: auto;">{{item.seller_name}}</strong><br>
                <div class="bottom clearfix">
                  <el-button type="text" style="margin: auto;" @click="turnTo(item.store_link)">View Products</el-button>
                </div>
              </div>
            </el-card>
          </div>
        </div>

        <div style="height: 100px;"></div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "TrustedSeller",
  components: {},
  metaInfo() {
    return {
      title: this.$t('meta.trusted_seller_title'),
      meta: [
        { hid: 'description', name: 'description', content: this.$t('meta.trusted_seller_des') },
        { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
        { hid: 'og:type', name: 'og:type', content: 'website' },
        { hid: 'og:title', name: 'og:title', content: this.$t('meta.trusted_seller_title') },
        { hid: 'og:description', name: 'og:description', content: this.$t('meta.trusted_seller_des') },
        { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
        { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
      ],
    }
  },
  created() {
    this.current_category = this.$route.query.current_category || 'Hanfu'

    this.show_data = this.category_list[this.current_category]

  },
  data(){
    return {
      current_category: "Hanfu",
      show_data: {},
      category_list: {
        "Hanfu": {
          title: "Hanfu",
          content: this.$t('page_seller.buy_hanfu_title'),
          bg_img: '/img/services/seller-hanfu.png',
          sellers: [
            {seller_name: 'Prosperous Years Chinese Culture Trend Store', logo_link: 'https://gd2.alicdn.com/imgextra/i3/2212983153275/O1CN01tSL7bn1a3zrRIGIku_!!2212983153275.jpg_400x400.jpg', store_link: 'https://shop185827032.taobao.com/category.htm?spm=2013.1.w4010-24126970383.2.1c015a0eoJ2VN4&search=y'},
            {seller_name: 'Yuncai Embroidery Art Store', logo_link: 'https://img.alicdn.com/imgextra/i1/2964468634/O1CN01S5lCvb2DeQJRcEQFz_!!2964468634.jpg_400x400.jpg', store_link: 'https://yuncaixiuyi.taobao.com/category.htm?spm=2013.1.w4010-14910810167.2.788c2c83upousm&search=y'},
            {seller_name: 'Fengtai Qu original Hanfu Store', logo_link: 'https://img.alicdn.com/bao/uploaded/i1/835609894/O1CN01NH4FO42MxVGlLoZBS_!!835609894.png_400x400.jpg', store_link: "https://shop491052799.taobao.com/search.htm?spm=a1z10.1-c.w5002-18406501099.1.1ce94ebesXLSKO&search=y"},
            {seller_name: 'Luanyu Xianyuange original Hanfu Store', logo_link: 'https://img.alicdn.com/imgextra/i4/1642519976/O1CN01d0yvtk2NZ3q2TptpH_!!1642519976.jpg_400x400.jpg', store_link: "https://shop103143467.taobao.com/?spm=2013.1.1000126.3.4fef2d034govyp"},
            {seller_name: 'Ifashion Hanfu Store', logo_link: 'https://gdp.alicdn.com/imgextra/i3/770167007/TB2hUpAxrlmpuFjSZFlXXbdQXXa_!!770167007.jpg_400x400.jpg', store_link: "https://shop70469190.taobao.com/?spm=2013.1.1000126.3.5aba562dj40S17"},
            {seller_name: 'Yuenan Pavilion Hanfu Store', logo_link: 'https://gd2.alicdn.com/imgextra/i1/3838093949/O1CN01tBL64S1f2go0NdANx_!!3838093949.jpg_400x400.jpg', store_link: "https://shop556928537.taobao.com/?spm=2013.1.1000126.2.78726299xFGC2r"},
          ],
        },
        "Sneaker": {
          title: "Sneaker",
          content: this.$t('page_seller.buy_sneaker_title'),
          bg_img: '/img/services/seller-sneaker.png',
          sellers: [
            {seller_name: 'SP', logo_link: 'https://static.wixstatic.com/media/44d8cc_62b5263e81e7474ebfa7adac7a84d7b9~mv2.jpg/v1/crop/x_0,y_1,w_250,h_248/fill/w_244,h_242,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/wdseller1416021268-48860000017b7d25e0fd0a20e7c7_250_250.jpg', store_link: 'https://weidian.com/?userid=1703496000&p=iphone&wfr=BuyercopyURL&share_relation=99a7ffdc083b55f2_1346777660_1&spider_token=7168&tabType=all'},
            {seller_name: 'Top Dreamer', logo_link: 'https://static.wixstatic.com/media/44d8cc_6a99ebc706a2414585532fa4b3ce2194~mv2.jpg/v1/crop/x_0,y_1,w_250,h_248/fill/w_244,h_242,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/wdseller1437660918-216d000001787dcdf9f70a21c2a8_250_250_jpg_w%3D250%26h%3D250%26cp%3D1.jpg', store_link: 'https://shop1697744208.v.weidian.com/?userid=1697744208'},
            {seller_name: 'Sneaker', logo_link: 'https://si.geilicdn.com/wdseller312177264-384f0000016f74febdee0a21c2a8_250_250.jpg', store_link: "https://weidian.com/?userid=312177264&share_relation=0db9c14f3242109d_964246902_1&wfr=wxBuyerShare"},
            {seller_name: 'Sneaker-TG', logo_link: 'https://si.geilicdn.com/weidian1227227688-2fe700000175a3e67b380a21c2a7_1080_1080.jpg', store_link: "https://shop1227227688.v.weidian.com/?userid=1227227688&share_relation=bd41fae3502d746f_964246902_1&wfr=BuyercopyURL"},
            {seller_name: 'Sneaker Fashion', logo_link: 'https://si.geilicdn.com/weidian1583964083-12a60000017d8a706a610a20e273_1056_1056.jpg', store_link: "https://shop1802098530.v.weidian.com/?userid=1802098530&share_relation=bc938d8ca2fb950c_964246902_1&wfr=BuyercopyURL"},
            {seller_name: 'BlackWhite-CASHCLUB', logo_link: 'https://si.geilicdn.com/login1532533740-4fa80000017f0d61b3d40a20e2c5_960_960.jpg', store_link: "https://shop1810023772.v.weidian.com/?userid=1810023772&share_relation=b291cf197657e4cd_964246902_1&wfr=BuyercopyURL"},
            // {seller_name: '', logo_link: '', store_link: ""},
            // {seller_name: '', logo_link: '', store_link: ""},
          ],
        },
        "Cosplay": {
          title: "Cosplay",
          content: this.$t('page_seller.buy_cosplay_title'),
          bg_img: '/img/services/cosplay.png',
          sellers: [
            {seller_name: 'Fireball Wand', logo_link: 'https://cbu01.alicdn.com/img/ibank/O1CN012bicp51NbLjbQZ24a_!!971571588-0-cib.jpg', store_link: "https://detail.1688.com/offer/672836525481.html?spm=a261y.7663282.10811813088311.4.2bc65d91YMWW1R&sk=order"},
            {seller_name: 'Lardoo STore', logo_link: 'https://gd3.alicdn.com/imgextra/i3/117248794/O1CN01LcLjoR2Ephlpk3muO_!!117248794.jpg_400x400.jpg_.webp', store_link: "https://lardoo.taobao.com/?spm=2013.1.1000126.3.79c73b1fUhiiY8"},
            {seller_name: 'Uwowo Store', logo_link: 'https://gd2.alicdn.com/imgextra/i2/194730645/O1CN01xpvJxq1GdS9rfqcm8_!!194730645.jpg_400x400.jpg_.webp', store_link: "https://shop107799339.taobao.com/?spm=2013.1.1000126.3.181d6297iVCyls"},
            {seller_name: '漫果威廉', logo_link: 'https://img.alicdn.com/imgextra/i2/886334117/O1CN018D6WB41gHdLmbYzyH_!!886334117.jpg_400x400.jpg_.webp', store_link: "https://manguoweilian.taobao.com/?spm=2013.1.1000126.3.54722b693nVbhH"},
            {seller_name: '猫与三町目', logo_link: 'https://img.alicdn.com/imgextra/i3/1680439480/O1CN01r7M8Jc2JttSMItmNw_!!1680439480.jpg_400x400.jpg_.webp', store_link: "https://cat3dm.taobao.com/?spm=2013.1.1000126.3.6ac62513G6wMPF"},
            {seller_name: '漫谷COS', logo_link: 'https://img.alicdn.com/imgextra/i4/3559923623/O1CN01xQDvve1cdNdIlh8KG_!!3559923623.jpg_400x400.jpg_.webp', store_link: "https://shop306117054.taobao.com/category.htm?spm=2013.1.w5001-24394967295.7.45e873ac25orPL&search=y&scene=taobao_shop"},
            {seller_name: '三分妄想', logo_link: 'https://img.alicdn.com/imgextra/i3/85470570/O1CN01gCLlGS1G56XJeqcbR_!!85470570.jpg_400x400.jpg_.webp', store_link: "https://delusion3.taobao.com/?spm=2013.1.1000126.3.42bb1c39g5L54w"},
          ],
        },
      }
    }
  },
  methods: {
    search(key) {
      location.href = '/service/trust-seller?current_category=' + key
    },
    turnTo(url) {
      window.open(url)
    }
  },
}
</script>
<style scoped>

</style>
