import request_api from '@/utils/request_api'

//加入新店铺
export function addNewStore(data) {
    return request_api({
        url: '/api/onlinestore/add_new_store',
        method: 'post',
        data
    })
}

//获取所有店铺
export function getStoreList(data) {
    return request_api({
        url: '/api/onlinestore/get_store_list',
        method: 'post',
        data
    })
}

//获取所有商品
export function getProductList(data) {
    return request_api({
        url: '/api/onlinestore/get_product_list',
        method: 'post',
        data
    })
}


//添加商品
export function addNewProduct(data) {
    return request_api({
        url: '/api/onlinestore/add_new_product',
        method: 'post',
        data
    })
}

export function checkOnlineOrders(data) {
    return request_api({
        url: '/api/onlinestore/check_online_orders',
        method: 'post',
        data
    })
}

export function getStoreOrderList(data) {
    return request_api({
        url: '/api/onlinestore/get_store_order_list',
        method: 'post',
        data
    })
}
