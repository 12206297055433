<template>

        <div style="display: flex; width: 310px;height: 210px; flex-direction: column; border-radius: 5px; padding: 5px;">

            <div style="background-color: #f96332;height: 100px;">
                <h3>${{coupon_details.id}}</h3>
            </div>

            <div style="height: 100px;">
                rrrrrrrr
            </div>

        </div>

</template>
<script>

    import { getMyCouponList } from "@/api/coupon"

    export default {
        name: 'CouponList',
        components: {

        },
        props: {
            coupon_details: {type: Object},
        },
        data() {
            return {

            };
        },
        created() {

        },
        methods: {

        }
    };
</script>
