<template>
    <div style="margin-top: 100px;">
        <div class="section section-about-us">
            <div class="container">

                <h1>{{$t('page_taxation.title')}}</h1>

                <h4>[ {{$t('page_taxation.disclaimer')}} ]</h4>
                <p>{{$t('page_taxation.d_line1')}}</p>
                <p>{{$t('page_taxation.d_line2')}}</p>
                <p>{{$t('page_taxation.d_line3')}}</p>
                <p>{{$t('page_taxation.d_line4')}}</p>
                <p>{{$t('page_taxation.d_line5')}}</p>
                <p>{{$t('page_taxation.d_line6')}}</p>
                <p>{{$t('page_taxation.d_line7')}}</p>


            </div>

        </div>
    </div>
</template>

<script>

    export default {
        name: "CompensationTaxation",
        components: {},
        created() {

        },
        data(){
            return {

            }
        },
        methods: {

        },
    }
</script>
<style scoped>

</style>
