import request_api from '@/utils/request_api'


export function checkShipFreight(data) {
  return request_api({
    url: '/api/service/check_ship_freight',
    method: 'post',
    data
  })
}

export function getShipFreightSmall(data) {
  return request_api({
    url: '/api/service/get_ship_freight_small',
    method: 'post',
    data
  })
}

export function getPicUrl(data) {
  return request_api({
    url: '/api/service/get_pic_url',
    method: 'post',
    data
  })
}

export function getShippingLines(data) {
  return request_api({
    url: '/api/service/get_shipping_lines',
    method: 'post',
    data
  })
}
