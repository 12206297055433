import request_api from '@/utils/request_api'

//登录接口
export function login(data) {
  return request_api({
    url: '/api/login',
    method: 'post',
    data
  })
}

//注册接口
export function register(data) {
  return request_api({
    url: '/api/register',
    method: 'post',
    data
  })
}

//激活账号
export function activateAccount(data) {
  return request_api({
    url: '/api/activate_account',
    method: 'post',
    data
  })
}

//校验系统应用app是否有效
export function checkAppExists(data) {
  return request_api({
    url: '/api/check_app_exists',
    method: 'post',
    data
  })
}

//获取账号基本信息
export function getAccountInfo(data) {
  return request_api({
    url: '/api/account/get_account_info',
    method: 'post',
    data
  })
}

//修改账号信息
export function updateAccountInfo(data) {
  return request_api({
    url: '/api/account/update_account_info',
    method: 'post',
    data
  })
}

//获取用户地址信息
export function getAddressList(data) {
  return request_api({
    url: '/api/account/get_address_list',
    method: 'post',
    data
  })
}

//创建用户地址信息
export function addAddress(data) {
  return request_api({
    url: '/api/account/add_address',
    method: 'post',
    data
  })
}

//修改用户地址信息
export function updateAddress(data) {
  return request_api({
    url: '/api/account/update_address',
    method: 'post',
    data
  })
}

//删除用户地址信息
export function deleteAddress(data) {
  return request_api({
    url: '/api/account/delete_address',
    method: 'post',
    data
  })
}

//获取默认地址
export function getDefaultAddress(data) {
  return request_api({
    url: '/api/account/get_default_address',
    method: 'post',
    data
  })
}


//退出登录
export function logout() {
  return request_api({
    url: '/api/logout',
    method: 'post'
  })
}

//退出登录
export function changePassword(data) {
  return request_api({
    url: '/api/account/change_password',
    method: 'post',
    data
  })
}

//
export function getCountryCodes(data) {
  return request_api({
    url: '/api/account/get_country_codes',
    method: 'post',
    data
  })
}

export function getCountryList(data) {
  return request_api({
    url: '/api/account/get_country_list',
    method: 'post',
    data
  })
}

//
export function resetLink(data) {
  return request_api({
    url: '/api/account/reset_link',
    method: 'post',
    data
  })
}

//
export function resetPassword(data) {
  return request_api({
    url: '/api/account/reset_password',
    method: 'post',
    data
  })
}

export function googleLoginCheck(data) {
  return request_api({
    url: '/api/google_login_check',
    method: 'post',
    data
  })
}

export function facebookLoginCheck(data) {
  return request_api({
    url: '/api/facebook_login_check',
    method: 'post',
    data
  })
}

export function checkTheAccountIfActive(data) {
  return request_api({
    url: '/api/check_the_account_if_active',
    method: 'post',
    data
  })
}

export function addUserTag(data) {
  return request_api({
    url: '/api/tag/add_tag',
    method: 'post',
    data
  })
}

export function getNotificationNum(data) {
  return request_api({
    url: '/api/account/nootification/get-num',
    method: 'post',
    data
  })
}

export function getNotificationList(data) {
  return request_api({
    url: '/api/account/nootification/get-list',
    method: 'post',
    data
  })
}

export function readMessage(data) {
  return request_api({
    url: '/api/account/nootification/read-message',
    method: 'post',
    data
  })
}

export function getUpdateInviteCode(data) {
  return request_api({
    url: '/api/account/get_update_invite_code',
    method: 'post',
    data
  })
}

export function getCouponInfo(data) {
  return request_api({
    url: '/api/account/get_user_coupon_nums',
    method: 'post',
    data
  })
}

export function userCreateReview(data) {
  return request_api({
    url: '/api/account/user_create_review',
    method: 'post',
    data
  })
}

export function getInvitationData(data) {
  return request_api({
    url: '/api/account/get_invitation_data',
    method: 'post',
    data
  })
}

export function getChargeDiscountList(data) {
  return request_api({
    url: '/api/account/get_charge_discount_list',
    method: 'post',
    data
  })
}

export function userAddBalance(data) {
  return request_api({
    url: '/api/account/user_add_balance',
    method: 'post',
    data
  })
}


export function getBalanceRecordList(data) {
  return request_api({
    url: '/api/account/get_balance_record_list',
    method: 'post',
    data
  })
}

export function getBalancePayment(data) {
  return request_api({
    url: '/api/account/get_balance_payment',
    method: 'post',
    data
  })
}

export function payBalanceOrder(data) {
  return request_api({
    url: '/api/account/pay_balance_order',
    method: 'post',
    data
  })
}


