<template>
  <div>
    <div class="page-header page-header-small" style="margin-top: 50px;">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/banner_logo_3.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container" style="padding-top: 50px;">
          <h1 class="title">{{$t('business_service')}}</h1>
          <div class="text-center">
            <h5>
              TokCommerce business service is a service designed for independent websites (Shopify, Woocommerce, etc.), third-party platforms (Amazon, Ebay, etc.), offline wholesalers, retailers and other commercial customers. , and is committed to helping business customers more easily buy and ship high-quality goods directly from China, and more conveniently ship directly to end users, helping them improve their business competitiveness.
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="guides-page">
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">Business Service</b>
        </div>
        <b style="font-size: 20px">1. Third party platform sellers：</b><br>
        <img v-lazy="'/img/partners/ebay.jpeg'" alt="ebay" class="rounded" />
        <img v-lazy="'/img/partners/amazon.jpeg'" alt="amazon" class="rounded" />
        <img v-lazy="'/img/partners/lazada.jpeg'" alt="lazada" class="rounded" />
        <img v-lazy="'/img/partners/shopee.jpeg'" alt="shopee" class="rounded" />


        <br>
        <b style="font-size: 20px">2. Independent station sellers：</b><br>
        <img v-lazy="'/img/partners/shopify.jpeg'" alt="shopify" class="rounded" />
        <img v-lazy="'/img/partners/woocomerce.jpeg'" alt="woocommerce" class="rounded" />

        <br>
        <b style="font-size: 20px">3. Offline retailers, wholesalers or self-employed stores：</b><br>
        <img v-lazy="'/img/partners/Wholesale.png'" alt="Wholesale" class="rounded" />

      </div>
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">TokCommerce as your Business Supplier</b>
          <div class="guides-card-row">
            <DropshipCard
                v-for="reason in reasons"
                :key="reason.key"
                :title="reason.title"
                :description="reason.description"
            ></DropshipCard>
          </div>
        </div>
      </div>
      <div class="section_title">
        <b style="font-size: 20px">Choose TokCommerce as your Business Supplier today</b> <br>

        <el-button type="success" style="margin-top: 30px;" @click="$router.push('/search')">Start Your Business Now</el-button>
      </div>


      <div class="dropship_section" style="margin-top: 100px;">
        <div class="section_title">
          <b style="font-size: 25px">Why choose us?</b>
        </div>
        <p>
          Make it easier and faster for more business customers around the world to succeed.
        </p>
        <p>
          We have done a lot of services for many commercial customers, they can get more benefits in a short period of time due to our excellent supply chain service capabilities.
        </p>
        <p>
          With any new client, we always hold them to a higher standard, even if they are just starting out in business. We want to succeed with them.
        </p>
        <p>
          China has a huge product supply chain capacity, and it is an easy and correct choice for global customers to buy goods from China and sell them online.
        </p>
        <p>
          When you are hesitating whether to choose to use our services, we have made a lot of preparations to help you better connect Chinese products to be sold all over the world.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import DropshipCard from "../components/DropshipCard";
export default {
  name: "Business Service",
  components: { DropshipCard },
  metaInfo: {
    title: 'Business Service - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce business service is a service designed for independent websites (Shopify, Woocommerce, etc.), third-party platforms (Amazon, Ebay, etc.), offline wholesalers, retailers and other commercial customers. , and is committed to helping business customers more easily buy and ship high-quality goods directly from China, and more conveniently ship directly to end users, helping them improve their business competitiveness. Make business customers more successful.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Business Service - From China to the world' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce business service is a service designed for independent websites (Shopify, Woocommerce, etc.), third-party platforms (Amazon, Ebay, etc.), offline wholesalers, retailers and other commercial customers. , and is committed to helping business customers more easily buy and ship high-quality goods directly from China, and more conveniently ship directly to end users, helping them improve their business competitiveness. Make business customers more successful.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
    ],
  },
  data(){
    return {
      reasons: [
        {
          title: "Logistics Service",
          description: "We provide very efficient and cheap logistics services. We can ship by air and sea. "
        },
        {
          title: "Order Manage",
          description: "One platform to manage all your orders. Get a clearer view of the order progress."
        },
        {
          title: 'Warehousing Service',
          description: 'We provide very cheap warehousing services to commercial customers. We have a large warehouse and perfect management process to do product quality inspection for you.'
        },
        {
          title: "Purchasing Goods",
          description: "Purchase goods from mainstream Chinese e-commerce platforms or factories. Buy from Taobao/1688/Tmall/Weidian/Yupoo."
        },
      ]
    }
  }
}
</script>
<style scoped>
.guides-page{
  margin: 30px 10%;
}

.guides-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 30px 20%;
  }
  .guides-card-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
  }
}

.dropship_section {
  margin-bottom: 50px;
}

.section_title {
  text-align: center;
  margin-bottom: 15px;
}

.first_para {
  margin-top: 15px;
}
</style>
