<template>
    <div class="page-header clear-filter" filter-color="orange">
        <div
                class="page-header-image"
                style="background-image: url('/img/login.jpg')"
        ></div>
        <div class="content">
            <div class="container">
                <div class="col-md-5 ml-auto mr-auto">

                    <div
                            class="section section-signup"
                            style="background: none;padding-top: 50px;"
                    >
                        <div class="container">
                            <div>
                                <h2>Pay Shipping Success</h2>
                                <p>
                                    You have successfully paid for your shipping order. We are ready to ship for you.
                                </p>


                                <el-button type="success" plain style="margin: auto;" @click="$router.push('/manage/main?path=orderList-ShippedParcels')">Back to my Order</el-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    import { Card, FormGroupInput, Button } from '@/components';
    import { register, googleLoginCheck, facebookLoginCheck, resetLink } from '@/api/user';
    import { setToken, setAccountName, getToken, setTmpAccountNo, getTmpAccountNo, getInvite} from '@/utils/auth';

    export default {
        components: {

        },
        data() {
            return {

            }
        },
        methods: {

        },
    };
</script>


<style></style>
