<template>
    <div style="margin-top: 75px;">
      <h3>Manage Online Stores</h3>
      <el-alert
          title="Add your online store, bind store products and Taobao/1688 products information, and make it easier for you to manage online shopping orders."
          type="warning">
      </el-alert>

      <el-button type="success" @click="add_store_dialog_visible=true" style="margin-top: 30px">Add New Store</el-button>
      <el-row style="justify-content: space-between;margin-top: 10px;">
        <el-form
            :inline="true"
            :model="store_list_search_params"
            ref="store_list_search_params"
            class="demo-form-inline"
            label-position="top" >
          <el-form-item prop="store_name">
            <el-input placeholder="Store Name" v-model="store_list_search_params.store_name" class="input-with-select"></el-input>
          </el-form-item>
          <el-form-item prop="store_link">
            <el-input placeholder="Store Link" v-model="store_list_search_params.store_link" class="input-with-select"></el-input>
          </el-form-item>
          <el-form-item prop="order_type">
            <el-select placeholder="Store Type" v-model="store_list_search_params.store_type" filterable>
              <el-option
                  v-for="type in store_type_options"
                  :key="type.value"
                  :value="type.label"
                  :label="type.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-button size="small" @click="searchStore()">Search</el-button>
        </el-form>
      </el-row>



      <el-table
        empty-text="No Store Added Yet"
        :data="store_list"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 30px;"
        v-loading="loading"
        >
        <el-table-column
          width="275px"
          prop="store_name"
          label="Store Name"
          ></el-table-column>
        <el-table-column
          width="400px"
          prop="store_link"
          label="Store Link"
          ></el-table-column>
        <el-table-column
          width="100"
          prop="store_type"
          label="Store Type"
          ></el-table-column>

        <el-table-column
                label="Operate"
                width="150">
          <template slot-scope="scope">
            <el-button size="small" round @click="turnToManageProduct(scope.row)">Manage Product</el-button><br>
            <el-button size="small" round @click="turnToManageOrder(scope.row)" style="margin-top: 3px;" type="success">Manage Order</el-button><br>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination
          style="margin-top: 30px;"
          layout="prev, pager, next"
          :total="total_count"
          :current-page="current_page"
          @current-change="changePage"
      >
      </el-pagination>



      <el-dialog
        title="Add New Store"
        :visible.sync="add_store_dialog_visible"
        width="80%"
        >
        <el-form
          :inline="false"
          :model="add_store_form"
          ref="add_store_form"
          class="demo-form-inline"
          label-position="top"
          >
          <el-row class="order-form-row">
            <el-col :span="11">
              <el-form-item label="Store Name" required prop="store_name">
                <el-input placeholder="Input your store name" v-model="add_store_form.store_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="Store Link" required prop="store_link">
                <el-input placeholder="Input your store link" v-model="add_store_form.store_link"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="order-form-row">
            <el-col :span="11">
              <el-form-item label="Store Type" required prop="store_type">
                <el-select
                    v-model="add_store_form.store_type"
                    placeholder="Select platform"
                    >
                  <el-option
                    v-for="type in store_type_options"
                    :key="type.value"
                    :label="type.label"
                    :value="type.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11"></el-col>
          </el-row>
          <div style="display: flex;justify-content: center;margin-top: 30px;">
            <el-button size="small" plain type="success" @click="addNewStore('add_store_form')" style="margin: auto;">Add Store</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
</template>
<script>


  import {addNewStore, getStoreList} from "@/api/onlinestore";


    export default {
        name: 'MyStore',
        components: {

        },
        props: {

        },
        data() {
            return {
              add_store_dialog_visible: false,
              add_store_form: {
                store_name: '',
                store_link: '',
                store_type: {
                  label: '',
                  value: ''
                }
              },
              total_count: 0,
              current_page: 1,
              store_list: [],
              store_list_params: {},
              store_type_options: [
                {
                  value: 'Shopify',
                  label: 'Shopify'
                },
                {
                  value: 'Wix',
                  label: 'Wix'
                }
              ],
              store_list_search_params: {
                store_name: '',
                store_link: '',
                store_type: ''
              },
              loading: true,
            };
        },
        created() {
          this.getStoreList()
        },
        methods: {
          addNewStore(form_name){
            this.$message({message: 'Adding new store, please wait...', type: 'warning'})
            this.$refs[form_name].validate((valid => {
              if (!valid) {
                this.$message({message: 'Please check your input', type: 'warning'});
                return
              }
              addNewStore(this.add_store_form).then((res) => {
                if (res.code === 0){
                  this.$message({message: 'New store added', type: 'success'});
                  this.add_store_dialog_visible = false
                  this.$refs[form_name].resetFields();
                  this.getStoreList()
                } else {
                  this.$message({message: 'Failed to add new store', type: 'error'});
                }
              })
            }))
          },
          getStoreList(){
            let params = this.store_list_search_params
            params.page = this.current_page
            params.page_size = 10

            this.$message({message: 'Fetching your store list', type: 'warning'});
            getStoreList(params).then(res => {
              if (res.code === 0){
                this.$message({message: 'Store list fetched successfully', type: 'success'});
                this.store_list = res.data.list || []
                this.total_count = res.data.total || 0
              } else {
                this.$message({message: 'Failed to fetch store list', type: 'error'});
              }

              this.loading = false
            }).catch(error => {
              this.loading = false
            })
          },
          changePage(page) {
            this.current_page = page
            this.getStoreList();
          },
          searchStore(){
            this.current_page = 1
            this.getStoreList()
          },
          turnToManageProduct(data) {
            window.open('/manage/main?path=OnlineStore-MyStoreProduct&store_id=' + data.id + '&store_name=' + data.store_name)
          },
          turnToManageOrder(data) {
            window.open('/manage/main?path=OnlineStore-MyStoreOrder&store_id=' + data.id + '&store_name=' + data.store_name + '&store_type=' + data.store_type)
          }
        }
    };
</script>
