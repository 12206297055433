<template>
    <div style="margin-top: 75px;">
<!--        <el-alert-->
<!--                title="Orders that have arrived at the warehouse will be displayed here.You need to submit to ship and post your address."-->
<!--                type="warning">-->
<!--        </el-alert>-->

        <el-row style="display:flex;justify-content: space-between;margin-top: 10px;">
            <el-col :span="24" style="display: flex;align-items: center;">
                <el-form :inline="true" :model="search_form" ref="search_form" class="demo-form-inline" label-position="top" >
                    <el-form-item required prop="order_status">
                        <el-select placeholder="Please select" v-model="search_form.order_status" filterable>
                            <el-option
                                    v-for="item in order_status_list"
                                    :key="item.status"
                                    :value="item.status"
                                    :label="item.status_text">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="search_text">
                        <el-input :placeholder="$t('page_shopping_payment.order_no')" v-model="search_form.search_text" class="input-with-select"></el-input>
                    </el-form-item>
                    <el-button size="large" @click="searchOrder()">{{$t('page_my_order.search')}}</el-button>
                </el-form>
            </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row style="display:flex;justify-content: space-between;margin-top: 10px;">
            <el-col :span="8" style="display: flex;align-items: center;">
                {{$t('page_calculator.product_weight')}}: {{total_weight}} KG

                <el-tooltip class="item" effect="light" placement="top-start">
                    <div slot="content">
                        How is the weight calculated? <br>
                        If the volume weight is larger than the actual weight, take the volume weight. <br>
                        If the actual weight is larger than the volume weight, take the actual weight.<br>
                        (volume weight = parcel length * parcel height * parcel width / 5000)
                    </div>
                    <i class="el-icon-question"></i>
                </el-tooltip>
            </el-col>
            <el-col :span="16" style="display: flex;align-items: center;">
                <el-button size="small" plain @click="turnToCalculator()">{{$t('shipping_calculator')}}</el-button>
                <el-button v-if="search_form.order_status == 7" size="small" plain type="success" @click="submitToShip()">{{$t('my_warehouse.submit_to_ship')}}</el-button>
            </el-col>
        </el-row>

        <br>
        <el-table
                empty-text="You have no parcel in our warehouse now"
                :data="order_list"
                tooltip-effect="dark"
                style="width: 100%;margin-top: 30px;"
                ref="multipleTable"
                v-loading="loading"
                @selection-change="handleSelectionChange">
            <el-table-column
                    type="selection">
            </el-table-column>
            <el-table-column
                    width="100px;"
                    prop="order_id"
                    :label="$t('page_shopping_payment.order_no')">
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.item')"
                    width="150px;">
                <template slot-scope="scope">
                    {{$t('page_my_order.product_pic')}}<br>
                    <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.product_image_url"
                            :preview-src-list="[scope.row.product_image_url]">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <br>
                    {{$t('page_my_order.product_qc')}}<br>
                    <div v-for="item in scope.row.qc_image_url" :key="item.url" style="display:inline">
                        <el-image
                                style="width: 60px; height: 60px;margin-left: 3px;"
                                :src="item.url"
                                :preview-src-list="[item.url]">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    width="170px;"
                    :label="$t('page_cart.product')">
                <template slot-scope="scope">
                    <p>{{scope.row.product_name}}</p>
                    <p v-if="scope.row.product_link"><a :href="scope.row.product_link" target="_blank">View Product</a></p>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_wallet.info')"
                    width="200"
            >
                <template slot-scope="scope">
                    <p>{{$t('page_my_order.type')}}: {{scope.row.order_type_text}}</p>
                    <p>{{$t('page_wallet.status')}}: <span style="color: #f96332;">{{scope.row.order_status_text}}</span></p>
                    <p>{{$t('page_my_order.details')}}: {{scope.row.product_sku}}</p>
                    <p>{{$t('page_my_order.remark')}}: {{scope.row.product_remark}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('my_warehouse.actual_weight')"
                    width="150">
                <template slot-scope="scope">
                    {{scope.row.product_weight}} KG
<!--                    <el-tooltip class="item" effect="light" placement="top-start">-->
<!--                        <div slot="content">-->
<!--                            How is the weight calculated? <br>-->
<!--                            If the volume weight is larger than the actual weight, take the volume weight. <br>-->
<!--                            If the actual weight is larger than the volume weight, take the actual weight.<br>-->
<!--                        </div>-->
<!--                        <i class="el-icon-question"></i>-->
<!--                    </el-tooltip>-->
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.operate')">
                <template slot-scope="scope">
                    <el-button size="small" @click="viewOrderDetail(scope.row.order_id)">{{$t('page_my_order.details')}}</el-button>
                </template>
            </el-table-column>
        </el-table>


        <el-pagination
                style="margin-top: 30px;"
                layout="prev, pager, next"
                :total="total_count"
                :current-page="current_page"
                @current-change="changePage"
        >
        </el-pagination>


        <!--提交发货单 流程图-->
        <el-dialog :title="$t('my_warehouse.submit_to_ship')" :visible.sync="shipping_visible" width="90%" style="margin-top: 30px;" @close="refreshForm()">
            <el-steps :active="active" finish-status="success">
                <el-step :title="$t('my_warehouse.add_address')"></el-step>
                <el-step :title="$t('my_warehouse.select_method')"></el-step>
                <el-step :title="$t('my_warehouse.pay_order')"></el-step>
            </el-steps>

            <el-form :inline="false" :model="shipping_form" ref="shipping_form" class="demo-form-inline" label-position="top" style="margin-top: 15px;">

                <div v-if="active == 0">
                    <el-collapse style="margin-bottom: 15px" v-model="active_names">
                      <el-collapse-item title="My Address List" name="address_list">
                        <div>
                          <el-button
                              v-for="address in address_list"
                              :key="address.id"
                              @click="handleAddressSelection(address)"
                              style="width: 300px;display: inline-grid;margin: 10px;">
                            <AddressCard
                                :address="address"
                            >
                            </AddressCard>
                          </el-button>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                    <el-row class="order-form-row">
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_name')" required prop="consignee_name" >
                                <el-input v-model="shipping_form.consignee_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.country_code')" required prop="country_code">
                                <el-select v-model="shipping_form.country_code" filterable placeholder="Please select" style="width: 100%;">
                                    <el-option
                                            v-for="country in country_list"
                                            :key="country.country_code"
                                            :value="country.country_code"
                                            :label="country.en_name + ' - ' + country.cn_name">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="order-form-row">
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_province')" required prop="consignee_province" >
                                <el-input v-model="shipping_form.consignee_province"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_city')" required prop="consignee_city" >
                                <el-input v-model="shipping_form.consignee_city"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="order-form-row">
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_address_detail')" required prop="consignee_address_detail" >
                                <el-input v-model="shipping_form.consignee_address_detail"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_postcode')" required prop="consignee_postcode" >
                                <el-input v-model="shipping_form.consignee_postcode"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="order-form-row">
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_phone')" required prop="consignee_phone" >
                                <el-input v-model="shipping_form.consignee_phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item :label="$t('my_warehouse.consignee_email')" prop="consignee_email" >
                                <el-input v-model="shipping_form.consignee_email"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div style="display: flex;justify-content: center;margin-top: 30px;">
                        <el-button size="small" plain type="success" @click="checkSave()" style="margin: auto;">{{$t('my_warehouse.next')}}</el-button>
                    </div>
                </div>

                <div v-if="active == 1">

                    <h3>{{$t('my_warehouse.shipping_cost')}}: {{shipping_form.total_amount || 0.00}}</h3>

                    <div v-if="product_channels.length !== 0" class="freight-price-table" style="margin-top: 50px;">
                        <el-table
                                :data="product_channels"
                                highlight-current-row
                                @current-change="handleCurrentChange"
                                style="width: 100%">
                            <el-table-column
                                    type="index"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    :label="$t('page_calculator.shipping_method')"
                                    :width="table_column_width">
                                <template slot-scope="scope">
                                    <div style="text-align: center;">
                                        <img :src="scope.row.logo" width="50px;"> <br>
                                        <strong>{{scope.row.channel_name}}</strong>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="channel_days"
                                    :label="$t('page_calculator.delivery_time')"
                                    :width="table_column_width">

                            </el-table-column>
                            <el-table-column
                                    :label="$t('my_warehouse.shipping_cost') + '(USD)'"
                                    :width="table_column_width">
                                <template slot-scope="scope">
                                    <p>{{scope.row.freight_price}} USD</p>
                                    <p>{{scope.row.freight_price_cny}} CNY</p>
                                </template>
                            </el-table-column>

                        </el-table>

                        <div style="display: flex;justify-content: center;margin-top: 30px;">
                            <el-button size="small" plain type="success" @click="selectShippingMethod()">{{$t('my_warehouse.next')}}</el-button>
                        </div>
                    </div>
                    <div v-else>
                        <el-empty :description="$t('my_warehouse.method_note')">
                            <el-button size="small" plain type="success" @click="selectNoneMethod()">{{$t('my_warehouse.next')}}</el-button>
                        </el-empty>
                    </div>
                </div>

                <div v-if="active == 2">
                    <!--这里展示 订单基础数据-->
                    <el-card class="box-card">
                        <div class="text item">{{$t('my_warehouse.country_code')}}: {{shipping_form.country_code}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_name')}}: {{shipping_form.consignee_name}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_province')}}: {{shipping_form.consignee_province}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_city')}}: {{shipping_form.consignee_city}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_address_detail')}}: {{shipping_form.consignee_address_detail}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_postcode')}}: {{shipping_form.consignee_postcode}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_phone')}}: {{shipping_form.consignee_phone}}</div>
                        <div class="text item">{{$t('my_warehouse.consignee_email')}}: {{shipping_form.consignee_email}}</div>
                    </el-card>

                    <el-card class="box-card">
                        <div class="text item">{{$t('page_calculator.shipping_method')}}: {{shipping_form.shipping_method_code}}</div>
                        <div class="text item">{{$t('my_warehouse.shipping_cost')}}: {{shipping_form.total_amount}}USD</div>

                        <p>{{$t('page_wallet.discount_info')}}:</p>
                        <el-select v-model="shipping_form.coupon_code" filterable placeholder="Select" style="width: 100%;">
                            <el-option
                                    v-for="coupon in coupon_list"
                                    :key="coupon.id"
                                    :value="coupon.coupon_code"
                                    :label="coupon.coupon_name">
                            </el-option>
                        </el-select>
                    </el-card>

                    <el-card class="box-card">
                        <div>{{$t('my_warehouse.parcel_remark')}}</div>
                        <div>
                            {{$t('my_warehouse.such_as_parcel')}}
                        </div>
                        <el-input type="textarea" v-model="shipping_form.user_parcel_note"></el-input>

                    </el-card>

                    <div style="display: flex;justify-content: center;margin-top: 30px;">
                        <el-button size="small" plain type="success" @click="submitOrder()">{{$t('my_warehouse.submit_order')}}</el-button>
                    </div>
                </div>
            </el-form>


        </el-dialog>

    </div>
</template>
<script>

    import { getShoppingOrders, submitDeliveryOrder } from '@/api/shopping'
    import { getCountryList } from "@/api/user";
    import { getShippingLines } from '@/api/service'
    import {getAddressList} from "../../api/user";
    import AddressCard from "../../pages/components/AddressCard";
    import { getCanUseCouponList } from "@/api/coupon"

    export default {
        name: 'MyOrders',
        components: {
          AddressCard
        },
        props: {

        },
        data() {
            return {
                address_list_params: {},
                address_list: [],
                order_list: [],
                search_text: '',
                multipleSelection: [],
                total_count: 0,
                current_page: 1,
                loading: true,
                total_weight: 0,
                total_length: 0,
                total_width: 0,
                total_height: 0,
                shipping_visible: false,
                active: 0,
                active_names: [],
                shipping_form: {
                    order_ids: '',
                    shipping_method_code: '',
                    country_code: '',
                    consignee_name: '',
                    consignee_province: '',
                    consignee_city: '',
                    consignee_address_detail: '',
                    consignee_postcode: '',
                    consignee_phone: '',
                    consignee_email: '',//邮件使用客户的邮箱即可
                    total_amount: 0,//应收国际物流费
                    total_weight: '',
                    total_length: '',
                    total_width: '',
                    total_height: '',
                    coupon_code: '',
                    user_parcel_note: '',
                },
                country_list: [],
                currentRow: null,
                product_channels: [],
                search_form: {
                    search_text: '',
                    order_status: 7,
                },
                order_status_list: [
                    {
                        status: 6,
                        status_text: 'On the way',
                    },
                    {
                        status: 7,
                        status_text: 'In Warehouse',
                    },
                    {
                        status: 4,
                        status_text: 'Cancel',
                    },
                ],
                table_column_width: '200px',
                coupon_list: [],
            };
        },
        created() {
            this.getOrderList()
        },
        methods: {
            getOrderList() {
                let params = this.search_form

                params.page = this.current_page
                params.page_size = 60 //最大合并量

                getShoppingOrders(params).then(response => {
                    if (response.code === 0) {
                        this.order_list = response.data.list || []
                        this.total_count = response.data.total_count || 0
                    }

                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
            handleSelectionChange(values) {
                //很重要 这里是运费试算的关键

                this.multipleSelection = values
                let total_weight = 0
                // let length = 0
                // let width = 0
                // let height = 0



                for (let i in values) {
                    let  true_weight= parseFloat(values[i].product_weight)
                    //先计算材积重 /5000 对比材积重和实重 谁大取谁即可
                    let volume_weight = (parseInt(values[i].product_length) * parseInt(values[i].product_width) * parseInt(values[i].product_height)) / 5000
                    let weight = true_weight > volume_weight ? true_weight : volume_weight
                    total_weight += weight
                }

                let weight_origin = parseFloat(total_weight.toFixed(3))
                if (weight_origin > 0 && weight_origin <= 2) {
                    weight_origin += 0.4
                } else if (weight_origin > 2 && weight_origin <= 5) {
                    weight_origin += 0.9
                } else if (weight_origin > 5 && weight_origin <= 10) {
                    weight_origin += 1.5
                } else if (weight_origin > 10 && weight_origin <= 20) {
                    weight_origin += 1.8
                } else {
                    weight_origin += 2.5
                }
                weight_origin = parseFloat(weight_origin.toFixed(3))

                this.total_weight = weight_origin
            },
            searchOrder() {
                this.current_page = 1
                this.getOrderList()
            },
            turnToCalculator() {
                window.open('/service/shipping-calculate')
            },
            submitToShip() {
                //提交发货订单 初始化
                if (this.multipleSelection.length <= 0) {
                    this.$message({message: 'Please select items!', type: 'error'});
                    return
                }
                this.getCountrys()
                this.getAddressList()
                this.shipping_visible = true
                let order_ids = []
                for (let i in this.multipleSelection) {
                    order_ids.push(this.multipleSelection[i].order_id)
                }
                this.shipping_form.order_ids = order_ids
            },
            next() {
                if (this.active++ > 2) this.active = 0;
            },
            checkSave() {
                //校验地址是否已经都填写完毕
                if (this.shipping_form.country_code == '') {
                    this.$message({message: 'Country can not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_name == '') {
                    this.$message({message: 'Consignee Name not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_province == '') {
                    this.$message({message: 'State/Province not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_city == '') {
                    this.$message({message: 'City not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_address_detail == '') {
                    this.$message({message: 'Address Detail not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_postcode == '') {
                    this.$message({message: 'Postcode not be empty', type: 'error'});
                    return
                }
                if (this.shipping_form.consignee_phone == '') {
                    this.$message({message: 'Phone not be empty', type: 'error'});
                    return
                }

                //获取运费试算
                this.checkFreight()
                this.shipping_form.total_amount = 0.00
                this.shipping_form.shipping_method_code = ''
                this.next()
            },
            selectShippingMethod() {
                //有渠道可以选择
                // if (this.shipping_form.shipping_method_code == '') {
                //     this.$message({message: 'Please select Shipping Method', type: 'error'});
                //     return
                // }
                this.$message({message: 'Getting coupons now...', type: 'warning'});
                getCanUseCouponList({total_amount: this.shipping_form.total_amount}).then(response => {
                    if (response.code === 0) {
                        this.coupon_list = response.data.list || []
                    }
                })

                this.next()
            },
            selectNoneMethod() {
                //无渠道可以选择
                this.next()
            },
            submitOrder() {
                //可以提交订单啦 校验基础数据
                this.$message({message: 'Submit now.Please wait.', type: 'error'});
                this.shipping_form.total_weight = this.total_weight
                this.shipping_form.total_length = this.total_length
                this.shipping_form.total_width = this.total_width
                this.shipping_form.total_height = this.total_height
                submitDeliveryOrder(this.shipping_form).then(response => {
                    if (response.code === 0) {
                        this.$message({message: 'Submit Success', type: 'success'});
                        //直接跳转到支付页面
                        this.$router.push('/manage/main?path=orderList-ShippedParcels')
                        window.location.reload()
                    } else {
                        this.$message({message: response.msg || 'Try Again', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Try Again', type: 'error'});
                })

            },
            getCountrys() {
                getCountryList().then(res => {
                    this.country_list = res.data || []
                })
            },
            checkFreight() {
                //我的仓库包裹 运费试算 运费计算
                this.product_channels = []
                return //只能管理员后台手动添加


                //这里只是拿到运费试算而已
                let submit_form = {
                    country_code: this.shipping_form.country_code,
                    package_weight: this.total_weight,
                    warehouse: 'TokCommerce Warehouse',
                    goods_type: '0',
                }

                //如果包裹重量或包裹数超出限制 则需要管理员报价
                if (parseInt(this.total_weight) > 10 || this.shipping_form.order_ids.length > 10) {

                    this.product_channels = []
                    return
                }

                for (let i in this.multipleSelection) {
                    if (this.multipleSelection[i].product_type == '' || this.multipleSelection[i].product_type == [] || this.multipleSelection[i].product_type == '[]') {
                        //正常包裹无备注信息 不需要管理员报价
                    } else {
                        let f_product_flag = false
                        if (this.multipleSelection[i].product_type.length > 0) {
                            if (this.multipleSelection[i].product_type.indexOf('仿牌') || this.multipleSelection[i].product_type.indexOf('品牌牌')) {
                                f_product_flag = true
                                submit_form.goods_type = '4' // F货 return
                                break
                            }
                        }
                    }
                }
                this.$message({message: 'Getting shipping lines, please wait.', type: 'warning'});

                getShippingLines(submit_form).then(response => {
                    if (response.code === 0) {
                        let response_data = response.data.list || []
                        this.product_channels = response_data
                    } else {
                        this.product_channels = []
                    }
                })
            },
            handleCurrentChange(val) {
                this.currentRow = val;
                this.shipping_form.shipping_method_code = val.channel_name || ''
                this.shipping_form.total_amount = val.freight_price || 0.00
            },
            refreshForm() {
                this.active = 0
            },
            changePage(page) {
                this.current_page = page
                this.getOrderList();
            },
            viewOrderDetail(order_id) {
                // this.$router.push('/shipping/order-detail-new?order_id=' + order_id)
                window.open('/shipping/order-detail-new?order_id=' + order_id)
            },
          getAddressList(){
            let params = this.address_list_params
            params.page = this.current_page
            params.page_size = 4

            this.$message({message: 'Fetching your addresses', type: 'warning'});
            getAddressList(params).then(res => {
              if (res.code === 0){
                this.address_list = res.data.list || []
                this.total_count = res.data.total || 0
              } else {
                this.$message({message: 'Failed to fetch addresses', type: 'error'});
              }
            })
          },
          handleAddressSelection(address){
            this.shipping_form.consignee_name = address['user_name']
            this.shipping_form.country_code = address['country_code']
            this.shipping_form.consignee_province = address['province']
            this.shipping_form.consignee_city = address['city']
            this.shipping_form.consignee_address_detail = address['address_detail']
            this.shipping_form.consignee_postcode = address['postal_code']
            this.shipping_form.consignee_phone = address['phone']
            this.shipping_form.consignee_email = address['email']
            this.active_names = []
          }
        }
    };
</script>
