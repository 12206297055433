import request_api from '@/utils/request_api'


export function getMyCouponList(data) {
  return request_api({
    url: '/api/coupon/get_my_coupon_list',
    method: 'post',
    data
  })
}

export function getCanUseCouponList(data) {
  return request_api({
    url: '/api/coupon/get_can_use_coupon_list',
    method: 'post',
    data
  })
}

