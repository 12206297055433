<template>
  <div class="guides-page">
    <div>
      <h1>
        Freight Forwarding Guide using TokCommerce
      </h1>
      <p>
        Posted on 13 April 2022
      </p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#preparation">Preparation</a></li>
        <li><a href="#domestic">Domestic Freight Forwarding to our Warehouse</a></li>
        <li><a href="#submit-order">Submitting a Freight Forwarding Order</a></li>
        <li><a href="#arrangements">International Freight Forwarding Arrangements</a></li>
        <li><a href="#payment">Payment of Freight Forwarding Fee</a></li>
        <li><a href="#manage-order">Managing Your Order using the TokCommerce Website</a></li>
      </ol>
    </div>
    <div>
      <b id="preparation" class="subtitle">
        Preparation
      </b>
      <p>
        To forward your freight using TokCommerce, you need to first have a TokCommerce account.
      </p>
      <p>
        If you already have an existing account, you can log into your account <a href="/login">here.</a>
      </p>
      <p>
        Otherwise, you can register for a new account <a href="/register">here.</a>
      </p>
      <p>
        <em>
          When registering for a new account, make sure that you are using a valid email address that you are active
          on, since we will be sending emails to your provided email address in the future.
        </em>
      </p>
    </div>
    <div>
      <b id="domestic" class="subtitle">
        Domestic Freight Forwarding to our Warehouse
      </b>
      <p>
        You must first send your product(s) or package(s) to our TokCommerce warehouse for processing and storage.
      </p>

      <br>
      <p>
        Arrangements for a domestic courier should be made before submitting a freight forwarding order on our
        TokCommerce website. This is because the tracking number of your product(s) or package(s) has to be provided
        to us within the freight forwarding order.
      </p>
    </div>
    <div>
      <b id="submit-order" class="subtitle">
        Submitting a Freight Forwarding Order
      </b>
      <p>
        Once you have arranged for domestic shipping of your product(s) or package(s) to our TokCommerce warehouse,
        you can submit a freight forwarding order on our TokCommerce website.
      </p>
      <p>
        Navigate to our Freight Forwarding Service page by clicking on the “Forwarding” tab in the header section of the
        TokCommerce website. You can also directly access this page <a href="/service/forwarding">here.</a>
      </p>
      <p>
        Enter the relevant information into the form on the Freight Forwarding Service page.
      </p>
      <p>
        <em>
          The Destination Country should refer to the country you wish to forward your freight to, while the Product
          Screenshot can simply be a picture of your product(s) or package(s).
        </em>
      </p>
      <p>
        <em>
          Please use the Product Name and Tracking Number as per that provided by the domestic courier since we will
          be using this information to verify your product(s) or package(s) upon arrival at our warehouse.
        </em>
      </p>
      <p>
        <em>
          If you have any special requests with regards to your product(s) or package(s), please leave them under the
          “Product Remarks” field and we will service them to the best of our ability.
        </em>
      </p>
      <p>
        Once you have submitted your freight forwarding order, sit tight while your product(s) or package(s) make
        their way to our TokCommerce warehouse.
      </p>
    </div>
    <div>
      <b id="arrangements" class="subtitle">
        International Freight Forwarding Arrangements
      </b>
      <p>
        As soon as your product(s) or package(s) arrives at our TokCommerce warehouse, we will send an email to your
        verified email address to update you on your order.
      </p>
      <p>
        If you have any special requests that you would like to make with regards to your product(s) or package(s) before shipping
        them, you can also inform us via email and we will service them to the best of our abilities.
      </p>
      <p>
        Navigate to the My Orders page by clicking on the “Account” tab in the header section of the TokCommerce
        website and selecting "My Orders" in the dropdown menu.
      </p>
      <p>
        After being redirected, click the “In Warehouse” tab in the left panel to view the freight forwarding orders for which
        the products have arrived at our TokCommerce warehouse.
      </p>
      <p>
        Select the freight forwarding orders for which its products you want to ship, and then click the “Submit to Ship”
        button at the top of the page.
      </p>
      <p>
        <em>
          Selecting multiple orders at this step indicates that you wish to ship them together. If you would like to ship
          multiple groups of orders together, please complete this step separately for each group.
        </em>
      </p>
      <p>
        <em>
          You may also choose to ship your shopping and freight forwarding orders together at this step. Just wait for all your
          product(s) or package(s) to arrive at our TokCommerce warehouse before selecting the corresponding orders
          to indicate that you wish to ship them together.
        </em>
      </p>
      <p>
        Enter the delivery address you wish to ship your products to and double check that the address is correct before
        proceeding to the next step.
      </p>
      <p>
        Next, select the freight forwarding option of your choice from the list of shipping lines that are available to
        ship to your destination country. The freight forwarding costs and estimated shipping times for each shipping line
        are also made available to you to allow you to make a more informed decision based on your needs.
      </p>
      <p>
        Once you have confirmed and submitted the details for the freight forwarding of your orders, it is time to make
        payment for your freight forwarding fee.
      </p>
    </div>
    <div>
      <b id="payment" class="subtitle">
        Payment of Freight Forwarding Fee
      </b>
      <p>
        Navigate to the My Orders page, and click the "Int'l Parcels" tab in the left panel. From here, you should be
        able to view the orders that you have submitted for freight forwarding. Orders
        that are to be forwarded together will be displayed together under the same Delivery Number.
      </p>
      <p>
        Simply choose the order for which you would like to make payment for and click on the corresponding "Pay Order"
        button in the rightmost column.
      </p>
      <p>
        Follow the instructions on the subsequent pages to complete the payment of your freight forwarding fee.
      </p>
      <p>
        Once we have confirmed receipt of your payment, we will package and prepare your product(s) or package(s) for international
        freight forwarding at our warehouse.
      </p>
    </div>
    <div>
      <b id="manage-order" class="subtitle">
        Managing Your Orders using the TokCommerce Website
      </b>
      <p>
        Once you have completed the payment for the freight forwarding fee, all that is left to do is to wait for your
        product(s) or package(s) to arrive right at your doorstop!
      </p>
      <p>
        You may use the My Orders page mentioned in the previous section to track the progress of your orders.
      </p>
      <ul>
        <li>
          Orders that are awaiting arrival at our warehouse will appear under the “My Orders” tab in the left panel.
        </li>
        <li>
          Orders that are awaiting payment of the freight forwarding fee or have not been shipped out of our warehouse
          will appear under the “In Warehouse” tab.
        </li>
        <li>
          Orders that have been submitted for freight forwarding or have been shipped out of our warehouse
          will appear under the "Int’l Parcels" tab.
        </li>
        <li>
          Orders that have been completed will appear under the "Order History" tab.
        </li>
      </ul>
      <p>
        Alternatively, you can also join any of our social media communities and post your queries there for our
        community members or customer staff members to reply to them.
      </p>
    </div>
    <b class="subtitle">
      Thank you for choosing TokCommerce as your freight forwarder and we look forward to serving
      you again in the future!
    </b>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideFreightForwarding",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'Freight Forwarding Guide',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: How to ship from China by TokCommerce.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Freight Forwarding Guide' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: How to ship from China by TokCommerce.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-freight-forwarding' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
