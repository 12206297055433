<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/services/customer·_review.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">{{$t('customer_review')}}</h1>
          <div class="text-center" style="padding-top: 50px;">
            <h5>{{$t('page_customer_review.des')}}</h5>
            <el-button type="success" round size="small" @click="review_visible = true;">{{$t('page_customer_review.submit_link')}}</el-button>
          </div>
        </div>
      </div>
    </div>


    <div class="guides-page">
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">How to start?</b>
        </div>
        <p>
          Make, post, upload, share, publish, vlog, or any creative way you can to share your experience using our service.
          The more the impressions and referrals you make, the more credit we can give you.
          Link it to us and we'll determine how much credit we'll give to your account!
          What are you waiting for? Review now!
        </p>

      </div>
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">Attention</b>
        </div>
        <p>1. You post content</p>
        <p>2. Submit Review link to us</p>
        <p>3. We process within 15 days</p>
        <p>4. We transfer money to your personal account via PayPal</p>

      </div>
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">Review Platforms</b>
          <div style="text-align: center; margin-top: 30px;">

              <div >
                <el-card :body-style="{ padding: '0px' }">
                  <div style="text-align: center; padding-top: 10px;">
                    <img style="max-width: 400px;margin: auto;" src="/img/reviews/youtube.jpg" class="image">
                  </div>
                  <div style="padding: 14px;">
                    <h3>Youtube Video</h3>
                    <p>Upload a Youtube video regarding using our service and get credits.</p>

                    <div style="max-width: 400px; margin: auto;">
                      <ul style="text-align: center;">
                        <li>100 - 500 Views: $5</li>
                        <li>500 - 5000 Views: $15</li>
                        <li>5000 - 10000 Views: $25</li>
                        <li>10000+ Views: $50</li>
                      </ul>
                    </div>

                    <div class="bottom clearfix">
                      <el-button type="success" round @click="jumpTo('https://www.youtube.com/')">Review Now</el-button>
                    </div>
                  </div>
                </el-card>

                <el-card :body-style="{ padding: '0px' }">
                  <div style="text-align: center; padding-top: 10px;">
                    <img style="max-width: 400px;margin: auto;" src="/img/reviews/reddit.jpg" class="image">
                  </div>
                  <div style="padding: 14px;">
                    <h3>Reddit Post</h3>
                    <p>Publish a post on Reddit about our services.</p>

                    <div style="max-width: 400px; margin: auto;">
                      <ul style="text-align: center;">
                        <li>10-50 Upvotes:$5</li>
                        <li>51-100 Upvotes:$15</li>
                        <li>101-500 Upvotes:$25</li>
                        <li>500+ Upvotes:$50</li>
                      </ul>
                    </div>

                    <div class="bottom clearfix">
                      <el-button type="success" round @click="jumpTo('https://www.reddit.com/')">Review Now</el-button>
                    </div>
                  </div>
                </el-card>

                <el-card :body-style="{ padding: '0px' }">
                  <div style="text-align: center; padding-top: 10px;">
                    <img style="max-width: 400px;margin: auto;" src="/img/reviews/tiktok.png" class="image">
                  </div>
                  <div style="padding: 14px;">
                    <h3>TikTok Video</h3>
                    <p>Upload a TikTok video regarding using our service and get credits.</p>

                    <div style="max-width: 400px; margin: auto;">
                      <ul style="text-align: center;">
                        <li>100 - 500 Views: $5</li>
                        <li>500 - 5000 Views: $15</li>
                        <li>5000 - 10000 Views: $25</li>
                        <li>10000+ Views: $50</li>
                      </ul>
                    </div>

                    <div class="bottom clearfix">
                      <el-button type="success" round @click="jumpTo('https://www.tiktok.com/')">Review Now</el-button>
                    </div>
                  </div>
                </el-card>

                <el-card :body-style="{ padding: '0px' }">
                  <div style="text-align: center; padding-top: 10px;">
                    <img style="max-width: 400px;margin: auto;" src="/img/reviews/facebook.png" class="image">
                  </div>
                  <div style="padding: 14px;">
                    <h3>Facebook Post</h3>
                    <p>Make a review showcasing usage of our service at Facebook.</p>

                    <div style="max-width: 400px; margin: auto;">
                      <ul style="text-align: center;">
                        <li>100 - 500 Likes: $5</li>
                        <li>500 - 5000 Likes: $15</li>
                        <li>5000 - 10000 Likes: $25</li>
                        <li>10000+ Likes: $50</li>
                      </ul>
                    </div>

                    <div class="bottom clearfix">
                      <el-button type="success" round @click="jumpTo('https://www.facebook.com/')">Review Now</el-button>
                    </div>
                  </div>
                </el-card>

                <el-card :body-style="{ padding: '0px' }">
                  <div style="text-align: center; padding-top: 10px;">
                    <img style="max-width: 400px;margin: auto;" src="/img/reviews/ins.png" class="image">
                  </div>
                  <div style="padding: 14px;">
                    <h3>Instagram Post</h3>
                    <p>Make a review showcasing usage of our service at instagram.</p>

                    <div style="max-width: 400px; margin: auto;">
                      <ul style="text-align: center;">
                        <li>100 - 500 Likes: $5</li>
                        <li>500 - 5000 Likes: $15</li>
                        <li>5000 - 10000 Likes: $25</li>
                        <li>10000+ Likes: $50</li>
                      </ul>
                    </div>

                    <div class="bottom clearfix">
                      <el-button type="success" round @click="jumpTo('https://www.instagram.com/')">Review Now</el-button>
                    </div>
                  </div>
                </el-card>


              </div>

          </div>
        </div>
      </div>

      <el-dialog title="Service Review Promotion" style="margin-top: 20px;" :visible="review_visible" width="90%">
        <el-alert
                title="After you submit, we will review your content within 15 days. Once we approve it, we will transfer it to you via PayPal."
                type="warning"
                style="margin-bottom: 10px;">
        </el-alert>

        <el-form
                :inline="false"
                :model="view_form"
                ref="view_form"
                class="demo-form-inline"
                style="width: 100%">
          <el-form-item required prop="review_link">
            <el-input placeholder="Review Link"  v-model="view_form.review_link"></el-input>
          </el-form-item>
          <el-form-item required prop="tok_account">
            <el-input placeholder="TokCommerce Account" v-model="view_form.tok_account"></el-input>
          </el-form-item>
          <el-form-item required prop="paypal_no">
            <el-input placeholder="PayPal Account" v-model="view_form.paypal_no"></el-input>
          </el-form-item>
          <div style="display: flex;justify-content: center;margin-top: 30px;">
            <el-button size="small" plain type="warning" @click="review_visible = false" >
              Cancel
            </el-button>
            <el-button size="small" plain type="success" @click="submitView('view_form')" style="margin-left: 10px;">
              Submit Review
            </el-button>
          </div>
        </el-form>
      </el-dialog>

    </div>
  </div>
</template>

<script>

  import { userCreateReview } from "@/api/user"

export default {
  name: "DropShipping",
  components: { },
  metaInfo: {
    title: 'Customer Review - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'Customer Review: Make, post, upload, share, publish, vlog, or any creative way you can to share your experience using our service.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Customer Review - TokCommerce' },
      { hid: 'og:description', name: 'og:description', content: 'Customer Review: Make, post, upload, share, publish, vlog, or any creative way you can to share your experience using our service.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
    ],
  },
  data(){
    return {
      review_visible: false,
      view_form: {
        review_link: "",
        tok_account: "",
        paypal_no: "",
      },
    }
  },
  methods: {
    jumpTo(url) {
      window.open(url)
    },
    submitView(form_name) {
      this.$message({message: 'Please wait...', type: 'warning'})
      this.$refs[form_name].validate((valid => {
        if (!valid) {
          this.$message({message: 'Please check your input', type: 'warning'});
          return
        }
        userCreateReview(this.view_form).then((res) => {
          if (res.code === 0){
            this.$message({message: 'Submit successfully', type: 'success'});
            this.$refs[form_name].resetFields();
            this.review_visible = false
          } else {
            this.$message({message: 'Failed', type: 'error'});
          }
        })
      }))
    }
  },
}
</script>
<style scoped>
.guides-page{
  margin: 30px 10%;
}

.guides-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 30px 20%;
  }
  .guides-card-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
  }
}

.dropship_section {
  margin-bottom: 50px;
}

.section_title {
  text-align: center;
  margin-bottom: 15px;
}

.first_para {
  margin-top: 15px;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
