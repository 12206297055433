<template>
  <div class="guides-page">
    <div>
      <h1>
        How TokCommerce serves you as a Taobao Agent and Forwarder
      </h1>
      <p>Posted on 11 May 2022</p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#customers">Our Customers</a></li>
        <li><a href="#shopping-agent">Shopping Agent for Chinese E-Commerce Websites</a></li>
        <li><a href="#requests">Special Requests</a></li>
        <li><a href="#qc">Product Quality Check</a></li>
        <li><a href="#forwarding">Preparation for Freight Forwarding</a></li>
        <li><a href="#customer-service">1-on-1 Professional Customer Service</a></li>
      </ol>
    </div>
    <div>
      <b id="customers" class="subtitle">
        Our Customers
      </b>
      <p>
        We aim to serve customers from all walks of life.
      </p>
      <p>
        Whether you are a business owner looking to dropship from the Chinese E-Commerce market, or a customer who
        enjoys shopping on Chinese E-Commerce websites for the wide variety of products at affordable prices,
        TokCommerce can serve as your shopping agent wherever you may be in the world.
      </p>
    </div>
    <div>
      <b id="shopping-agent" class="subtitle">
        Shopping Agent for Chinese E-Commerce Websites
      </b>
      <p>
        We are able to serve as a shopping agent for Taobao and various other major Chinese E-Commerce websites because
        our office and warehouse are based in ShenZhen, China.
      </p>
      <p>
        Hence, it is much easier for us to procure the products that you want to purchase from the aforementioned
        websites and to have them shipped directly to our warehouse.
      </p>
      <p>
        Once we receive your shopping order, our shopping staff will purchase your products directly from the respective
        websites. If our shopping staff are unable to find your product, they will look for a similar product for you
        and liaise with you directly via email to confirm if you want to continue with your purchase.
      </p>
      <p>
        Once your products are purchased, they will be delivered directly to our warehouse.
      </p>
    </div>
    <div>
      <b id="requests" class="subtitle">
        Special Requests
      </b>
      <p>
        If you have any special requests regarding your products, our shipping staff would be happy to assist you to
        the best of their abilities.
      </p>
      <p>
        Simply drop us an email stating your order number and your special request, and we will get back to you as
        soon as possible on whether we can fulfil your request.
      </p>
    </div>
    <div>
      <b id="qc" class="subtitle">
        Product Quality Check
      </b>
      <p>
        Once your products arrive at our warehouse, our shipping staff will first conduct a quality check on them.
        This includes checks for manufacturing defects, as well as damages that may have been incurred during delivery
        of the package. If a product fails the quality check, we will contact the seller directly to have them replace
        it.
      </p>
      <p>
        Our shipping staff will also send you photographs of your products via email for you to visually inspect them.
        If you are unhappy with the quality of your product, our staff can also assist you with the replacement or
        refund process with the seller.
      </p>
      <p>
        Best of all, this quality check comes complimentary with each and every product that we receive at our
        warehouse, so that you can shop with us with a peace of mind.
      </p>
    </div>
    <div>
      <b id="forwarding" class="subtitle">
        Preparation for Freight Forwarding
      </b>
      <p>
        Once we receive your freight forwarding order, our shipping team will then prepare your products and packages
        for freight forwarding. This includes repackaging of your products in order to minimize the dimensions of the
        final package that is to be forwarded.
      </p>
      <p>
        Upon request, we can also help to remove the existing packaging on the products in order to further reduce
        the package dimensions. All this is done to reduce the freight forwarding fees that you would have to pay.
      </p>
    </div>
    <div>
      <b id="customer-service" class="subtitle">
        1-on-1 Professional Customer Service
      </b>
      <p>
        You will have a dedicated customer service staff that will follow through with your shopping or freight
        forwarding orders from the moment we receive it on our platform until your products arrive at your doorstep,
        to ensure continuity of customer support throughout the entire process.
      </p>
      <p>
        This ensures that you are kept updated on the status of your orders every step of the way, and that any
        enquiries that you may have regarding your orders can be promptly answered.
      </p>
      <p>
        At the same time, having a single staff member tending to your order would mean that he/she will have all the
        information regarding your order and would be in the best position to assist you with it.
      </p>
      <p>
        Our customer service team is also highly professional to provide you with the best possible customer service.
      </p>
    </div>
    <b class="subtitle">
      So, what are you waiting for? Try out our TokCommerce shopping and freight forwarding services today!
    </b>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideTokCommerceShoppingFreightForwarding",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'How TokCommerce serves you as a Taobao Agent and Forwarder',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: One-to-one service to help you buy products from Taobao. Best shopping agent from China.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'How TokCommerce serves you as a Taobao Agent and Forwarder' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: One-to-one service to help you buy products from Taobao. Best shopping agent from China.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-tokcommerce-shopping-forwarding' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
