<template>
    <div style="margin-top: 75px;">
<!--        <el-alert-->
<!--                title="Official coupons are displayed here. Enjoy shopping."-->
<!--                type="warning">-->
<!--        </el-alert>-->

        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 10px;padding: 10px;">
            <el-tab-pane :label="$t('page_coupon.available')" name="0" class="row">
                <div  v-for="item in coupon_list" :coupon_detail="item" :key="item.id" style="display: flex; width: 310px;height: 250px; flex-direction: column; margin-top: 10px; margin-left: 20px;border: 1px solid #f96332; padding: 5px;">

                    <div style="background-color: #f96332;height: 150px; text-align: center; color: #ffffff;">
                        <h3>${{item.details.discount_reduce}}</h3>
                        <strong>{{item.details.coupon_title}}</strong><br>
                        <strong>{{item.details.coupon_name}}</strong>
                    </div>

                    <div style="height: 100px;">
                        <p>{{item.details.use_notice}}</p>
                        <p>{{item.effected_at}} ~ {{item.expired_at}}</p>
                    </div>

                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('page_coupon.used')" name="1" class="row">
                <div  v-for="item in coupon_list" :coupon_detail="item" :key="item.id" style="display: flex; width: 310px;height: 250px; flex-direction: column; margin-top: 10px; margin-left: 20px;border: 1px solid #f96332; padding: 5px;">

                    <div style="background-color: #f96332;height: 150px; text-align: center; color: #ffffff;">
                        <h3>${{item.details.discount_reduce}}</h3>
                        <strong>{{item.details.coupon_title}}</strong><br>
                        <strong>{{item.details.coupon_name}}</strong>
                    </div>

                    <div style="height: 100px;">
                        <p>{{item.details.use_notice}}</p>
                        <p>{{item.effected_at}} ~ {{item.expired_at}}</p>
                    </div>

                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('page_coupon.expired')" name="2" class="row">
                <div  v-for="item in coupon_list" :coupon_detail="item" :key="item.id" style="display: flex; width: 310px;height: 250px; flex-direction: column; margin-top: 10px; margin-left: 20px;border: 1px solid #f96332; padding: 5px;">

                    <div style="background-color: #f96332;height: 150px; text-align: center; color: #ffffff;">
                        <h3>${{item.details.discount_reduce}}</h3>
                        <strong>{{item.details.coupon_title}}</strong><br>
                        <strong>{{item.details.coupon_name}}</strong>
                    </div>

                    <div style="height: 100px;">
                        <p>{{item.details.use_notice}}</p>
                        <p>{{item.effected_at}} ~ {{item.expired_at}}</p>
                    </div>

                </div>
            </el-tab-pane>
        </el-tabs>

        <el-empty v-if="coupon_list.length == 0" description="No Available Coupon"></el-empty>

    </div>
</template>
<script>

    import { getMyCouponList } from "@/api/coupon"
    import CouponDetail from "@/components/Account/CouponDetail"

    export default {
        name: 'CouponList',
        components: {

        },
        props: {

        },
        data() {
            return {
                activeName: '0',
                coupon_list: [],
                total: 0,
            };
        },
        created() {
            this.getCouponList(0)
        },
        methods: {
            handleClick(tab, event) {
                let record_status = tab.name

                this.getCouponList(record_status)

            },
            getCouponList(record_status) {
                this.$message({message: 'Loading now...', type: 'warning'});
                this.coupon_list = []
                getMyCouponList({record_status: record_status, page: 1, page_size: 100}).then(response => {
                    if (response.code === 0) {
                        this.coupon_list = response.data.list || []
                        this.total = response.data.total || 0
                    }
                })
            },
        }
    };
</script>
