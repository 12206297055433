<template>

    <div class="section section-about-us" style="margin-top: 100px;min-height: 800px;">
        <div class="container">

            <h2 style="text-align: center;">{{$t('page_shopping_payment.title')}}</h2>

            <el-alert
                    style="margin-top: 30px;"
                    :title="$t('page_shopping_payment.des')"
                    type="success">
            </el-alert>

            <div style="display:flex;justify-content: space-between;margin-top: 30px;">
                <el-col :span="12">
                    <el-card class="box-card">
                        <div class="total_fee">
                            <h4>{{$t('page_shopping_payment.total')}}: $ {{total_amount}}</h4>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin-top: 30px;">
                        <div class="total_fee" v-for="item in order_ids" :key="item">
                            {{$t('page_shopping_payment.order_no')}}: {{item}}
                        </div>
                    </el-card>


                </el-col>
                <el-col :span="12">
                    <el-card class="box-card" >
                        <div style="display: flex; flex-direction: column;">
                            <el-radio v-model="pay_way" label="paypal" border>Paypal</el-radio>
                            <el-radio v-model="pay_way" label="balance" border>Balance({{balance_amount}})</el-radio>
                            <el-radio v-model="pay_way" label="creditcard" border disabled>Credit Card</el-radio>
                        </div>

                        <el-divider></el-divider>



                        <el-button v-if="pay_can_click" type="success" @click="payOrder()">{{$t('page_shopping_payment.pay_now')}}</el-button>


                    </el-card>
                </el-col>
            </div>

            <h2 style="margin-top: 50px;">{{$t('page_shopping_payment.shopping_notice')}}</h2>
            <el-collapse v-model="activeNames" >
                <el-collapse-item title="About transaction fee" name="1">
                    <div>Transaction fees charged by third parties</div>
                </el-collapse-item>
                <el-collapse-item title="Regarding the return" name="2">
                    <div>TokCommerce is just a shopping agent and does not sell any products. The cost of return and refund generated in the middle shall be borne by the customer. The processing fee for a single order return refund is 5USD ~ 30USD</div>
                </el-collapse-item>
                <el-collapse-item title="Does it support refund and return after it has been shipped?" name="3">
                    <div>We do not support, because the international logistics shipping process is very long, we cannot process such orders. Please understand.</div>
                </el-collapse-item>
                <el-collapse-item title="Can TokCommerce help you to send the package to another logistics company?" name="4">
                    <div>We support, and the costs incurred are borne by the customer.</div>
                </el-collapse-item>
            </el-collapse>

        </div>

    </div>
</template>
<script>

    import {Button, FormGroupInput, Card} from '@/components';
    import { payOrder, getPayments, payOrderWithBalance } from '@/api/shopping'
    import { getBalanceRecordList } from "@/api/user"


    export default {

        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                pay_way: 'paypal',
                order_ids: [],
                total_amount: 0.00,
                activeNames: ['1'],
                balance_amount: 0,
                page: 1,
                page_size: 10,
                pay_can_click: true,
            };
        },
        created() {
            let order_ids = this.$route.query.order_ids || ''
            if (order_ids == null || order_ids == '') {
                this.$message({message: 'Can not run', type: 'error'});
                this.$router.push('/')
            }
            this.order_ids = order_ids.split(',')
            this.getPayments(this.order_ids)
            this.getRecordList()
        },
        methods: {
            getPayments(order_ids) {
                //获取支付总金额
                this.$message({message: 'Loading now', type: 'warning'});
                getPayments({order_ids: order_ids}).then(response => {
                    if (response.code === 0 ) {
                      this.total_amount = response.data.total_amount || 0.00
                    } else {
                        //无法支付
                        this.$message({message: 'Can not pay', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Can not pay', type: 'error'});
                })
            },
            payOrder() {
                //判断支付类型
                if (this.pay_way == 'paypal') {
                    //获取支付链接
                    payOrder({order_ids: this.order_ids}).then(response => {
                        if (response.code === 0 && response.data != '') {
                            window.location = response.data || ''
                        } else {
                            this.$message({message: response.msg || 'Can not pay', type: 'error'});
                        }
                    }).catch(error => {
                        this.$message({message: 'Can not pay', type: 'error'});
                    })
                } else if (this.pay_way == 'payoneer') {
                    this.$message({message: 'Please transfer to our Payoneer account.', type: 'error'});
                } else if (this.pay_way == 'balance') {
                    if (this.balance_amount <= 0) {
                        this.$message({message: 'Your balance is below 0', type: 'error'});
                        return
                    }
                    if (parseFloat(this.balance_amount) < parseFloat(this.total_amount)) {
                        this.$message({message: 'Please top up...', type: 'error'});
                        return;
                    }

                    this.$message({message: 'Create payment now, please wait...', type: 'warning', duration: 5000});
                    this.pay_can_click = false
                    payOrderWithBalance({ order_ids: this.order_ids }).then(response => {
                        if (response.code === 0) {
                            this.$router.push('/shopping/pay_success')
                        } else {
                            this.$message({message: response.msg || 'Pay failed', type: 'error'});
                        }
                        this.pay_can_click = true
                    })
                } else {
                    this.$message({message: 'Please select pay way', type: 'error'});
                }
            },
            getRecordList() {
                let params = {
                    page: this.page,
                    page_size: this.page_size,
                }
                getBalanceRecordList(params).then(response => {
                    if(response.code === 0) {
                        this.balance_amount = response.data.balance_amount || 0
                    }
                })
            },
        }


    };
</script>
<style></style>
