import Cookies from 'js-cookie'

const TokenKey = 'shopping_user_permission_token'
const AccountName = 'shopping_user_account_name'
const TEMP_ACCOUNT_NO = 'shopping_user_tmp_number'
const ORDER_SHIPPING_DETAILDS = 'order_shipping_details'; //收件人地址
const INVITE_CODE = 'invite_code'
const SHOW_HOME_COMMUNITY_DIALOG = 'show_home_community_dialog'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function getAccountName() {
    return Cookies.get(AccountName)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { expires: 30 })
}

export function setAccountName(accountName) {
    return Cookies.set(AccountName, accountName, { expires: 30 })
}

export function setTmpAccountNo(account_no) {
    return Cookies.set(TEMP_ACCOUNT_NO, account_no, { expires: 30 })
}

export function getTmpAccountNo() {
    return Cookies.get(TEMP_ACCOUNT_NO)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function removeAccountName() {
    return Cookies.remove(AccountName)
}

export function removeTEMPACCOUNTNO() {
    return Cookies.remove(TEMP_ACCOUNT_NO)
}

export function setShippingDetails(address) {
    return Cookies.set(ORDER_SHIPPING_DETAILDS, JSON.stringify(address))
}

export function getShippingDetails() {
    // return localStorage.getItem(ORDER_SHIPPING_DETAILDS)
    let data = Cookies.get(ORDER_SHIPPING_DETAILDS)
    return data
}

export function getInvite() {
    let i = Cookies.get(INVITE_CODE);
    return i ? i : '';
}

export function setInvite(code) {
    return Cookies.set(INVITE_CODE, code, { expires: 7 })
}

export function removeInvite() {
    return Cookies.remove(INVITE_CODE)
}

export function setHomeCommunity() {
    return Cookies.set(SHOW_HOME_COMMUNITY_DIALOG, 1, { expires: 1 })
}

export function getHomeCommunity() {
    return Cookies.get(SHOW_HOME_COMMUNITY_DIALOG)
}
