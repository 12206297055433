<template>
    <div style="margin-top: 75px;">
<!--        <el-alert-->
<!--                title="Invite more friends to use our service."-->
<!--                type="success">-->
<!--        </el-alert>-->

        <div style="padding: 20px;">
            <p>{{$t('page_invite.your_share_link')}}: </p>
            <h5>{{invite_code}}</h5>
        </div>

        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <h3>{{$t('page_invite.invitation_service')}}</h3>
            </div>
            <div class="text item">
                {{$t('page_invite.get_cash_benefits')}}
            </div>
            <div class="text item">
                {{$t('page_invite.cheap_cost')}}
            </div>
            <div class="text item">
                {{$t('page_invite.find_product')}}
            </div>
        </el-card>

        <el-card class="box-card" style="margin-top: 10px;">
            <div slot="header" class="clearfix">
                <h3>{{$t('page_invite.invitation_analysis')}}</h3>
            </div>
            <div class="text item">
                {{$t('page_invite.invitation_users')}}: {{invite_num}}
            </div>
            <div class="text item">
                {{$t('page_invite.invitation_amount')}}: {{total_sell}} USD
            </div>
        </el-card>

    </div>
</template>
<script>

    import { getUpdateInviteCode, getInvitationData } from "@/api/user"

    export default {
        name: 'InviteFriend',
        components: {

        },
        props: {

        },
        data() {
            return {
                invite_code: '',
                invite_num: 0,
                total_sell: 0,
            };
        },
        created() {
            this.getInviteCode()
            this.getInvitationData()
        },
        methods: {
            getInviteCode() {
                getUpdateInviteCode().then(response => {
                    if (response.code === 0) {
                        this.invite_code = "https://tokcommerce.com?invitecode=" + response.data
                    } else {
                        this.invite_code = "https://tokcommerce.com?invitecode=nwdMITy52i"
                    }
                })
            },
            getInvitationData() {
                getInvitationData().then(response => {
                    if (response.code === 0) {
                        this.invite_num = response.data.invite_num || 0
                        this.total_sell = response.data.total_sell || 0
                    } else {

                    }
                })
            }

        }
    };
</script>
