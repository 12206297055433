import axios from 'axios'
import {getToken, removeToken} from '@/utils/auth'
import router from '../router'


const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

//请求数据改造
service.interceptors.request.use(
    config => {
        config.headers['token'] = getToken()
        config.headers['lang'] = localStorage.getItem('langSet') == null ? "en" : localStorage.getItem('langSet')

        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        if (response.data != undefined && response.data.code == 9003) {
            removeToken()
            router.push('/login')
            return response.data || {}
        }
        const res = response.data || {}
        return res
    },
    error => {
        return Promise.reject(error)
    }
)

export default service

