<template>
  <div class="guides-page">
    <div>
      <h1>
        Best Taobao Agent Of 2022 | TokCommerce
      </h1>
      <p>
        Posted on 5 May 2022
      </p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#FindProduct">Find your product from Taobao</a></li>
        <li><a href="#AddToCart">Add to cart</a></li>
        <li><a href="#SubmitPay">Submit and pay for product</a></li>
        <li><a href="#WarehouseQc">Warehouse QC</a></li>
        <li><a href="#SubmitToShip">Submit to ship</a></li>
        <li><a href="#ShipParcel">Ship your parcel</a></li>
      </ol>
      <p>
        Taobao is one of the largest online E-Commerce sites in the world, with a seemingly endless list of products that you could buy.
        However, if you are looking to buy from Taobao from outside of China, you may face many difficulties that prevent you from doing so.
      </p>
      <p>
        A shopping agent can help you to buy directly from Taobao and ship your products directly to your doorstep.
        And what better shopping agent to choose, than TokCommerce!
      </p>
      <p>But why TokCommerce?</p>

      <ul>
        <li>No service fees and hidden costs</li>
        <li>Payment: Paypal~ 3%-5%</li>
        <li>Able to find and purchase any product from most Chinese E-Commerce websites</li>
        <li>Free quality check and warehousing services for your products</li>
        <li>Freight forwarding fee quotation</li>
        <li>Friendly and Helpful Customer Service</li>
      </ul>

    </div>
    <div>
      <b id="FindProduct" class="subtitle">
        Find your product from Taobao
      </b>
      <p>
        You can access the Taobao website
        <a href="https://taobao.com" target="_blank">here.</a>
      </p>
      <p>
        You can search and find many products from Taobao and you can do next if you confirm want to buy which product.
      </p>

    </div>

    <div>
      <b id="AddToCart" class="subtitle">
        Add to cart
      </b>
      <p>
        Submit a shopping agent order to TokCommerce platform.But you need to add to cart first.<br>
        <a href="https://tokcommerce.com/search" target="_blank">Copy product information and add to cart</a>
      </p>
      <p>
        <a href="https://tokcommerce.com/shopping/my-cart" target="_blank">Shopping cart</a>
      </p>
    </div>

    <div>
      <b id="SubmitPay" class="subtitle">
        Submit and pay for product
      </b>
      <p>
        Now, you can submit shopping order to TokCommerce platform.But remember pay for product.<br>
        <a href="https://tokcommerce.com/manage/main?path=orderList-MyOrders" target="_blank">Shopping Order</a>
      </p>

    </div>

    <div>
      <b id="WarehouseQc" class="subtitle">
        Warehouse QC
      </b>
      <p>
        You can check whether your package has arrived at our warehouse through this page. We will take photos of your products for free.
      </p>
      <p>
        <a href="https://tokcommerce.com/manage/main?path=orderList-MyWarehouse" target="_blank">Check whether in Warehouse</a>
      </p>

    </div>

    <div>
      <b id="SubmitToShip" class="subtitle">
        Submit to ship
      </b>
      <p>
        <a href="https://tokcommerce.com/manage/main?path=orderList-MyWarehouse" target="_blank">Submit to ship</a>
      </p>
      Once you have confirmed that your package needs to be shipped, please submit a shipping order on this page. And pay the international shipping fee. We will ship it to you.

    </div>

    <div>
      <b id="ShipParcel" class="subtitle">
        Ship your parcel
      </b>
      <p>
        We will pack again and then ship your parcel.You can check the tracking info by this page.
      </p>
      <p>
        <a href="https://tokcommerce.com/manage/main?path=orderList-ShippedParcels" target="_blank">Tracking</a>
      </p>

    </div>

    <div>
      Is it easy to operate? If you need it, you can use our service.
    </div>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideTaobaoTokCommerce",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'Best Taobao Agent Of 2022  | TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: A high-quality Taobao agent platform. Cheap, convenient, efficient and good service.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Best Taobao Agent Of 2022  | TokCommerce' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: A high-quality Taobao agent platform. Cheap, convenient, efficient and good service..' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
