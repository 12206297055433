<template>
  <div id="app">
    <router-view name="header" />

    <div style="background-color: #f2f2f2; position: fixed;top: 0; right: 0;left: 0;z-index: 1030;height: 30px;">
      <div class="container" style="display: flex; justify-content: space-between;">
        <p style="margin-top: 2px;">{{date}} (UTC+8)</p>
        <el-dropdown>
          <span class="el-dropdown-link" style="display: flex;align-items: center;margin-top: 5px;">
            Language<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changeLang('en')">English</el-dropdown-item>
            <el-dropdown-item @click.native="changeLang('cn')">中文(简体)</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: this.getDate()
    };
  },
  mounted() {
    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = this.getDate(); // 修改数据date
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
  methods: {
    getDate() {
      var date=new Date();
      var year=date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
      var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
      var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
      var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
      // 拼接
      return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    },
    changeLang(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('langSet', lang)

      if (this.$route.query.lang && this.$route.query.lang != lang) {
        //切换的语言和url参数有冲突, 以切换的为主
        let old_url = window.location.href
        let new_url = old_url.replace('lang=' + this.$route.query.lang, 'lang=' + lang)
        window.location.href = new_url
      } else {
        location.reload()
      }
    },
  },
};
</script>
