<template>
    <div style="margin-top: 75px;">
      <h3>Manage Store Products - {{store_type}}</h3>
      <el-alert
          title="Bind store products and Taobao/1688 products information"
          type="warning">
      </el-alert>

      <el-button type="success" @click="add_store_dialog_visible=true" style="margin-top: 30px">Add New Product</el-button>

      <el-row style="justify-content: space-between;margin-top: 10px;">
        <el-form
            :inline="true"
            :model="store_list_search_params"
            ref="store_list_search_params"
            class="demo-form-inline"
            label-position="top" >

          <el-form-item prop="store_product_sku">
            <el-input placeholder="Store Product Sku" v-model="store_list_search_params.store_product_sku" class="input-with-select"></el-input>
          </el-form-item>
          <el-form-item prop="product_name">
            <el-input placeholder="Product Name" v-model="store_list_search_params.product_name" class="input-with-select"></el-input>
          </el-form-item>

          <el-button size="small" @click="search()">Search</el-button>
        </el-form>
      </el-row>



      <el-table
        empty-text="No Product Added Yet"
        :data="product_list"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 30px;"
        v-loading="loading"
        >
        <el-table-column
          width="200"
          prop="store_product_sku"
          label="Store Product SKU"
          ></el-table-column>
        <el-table-column
          width="200"
          label="Taobao/1688 Product Link"
          >
          <template slot-scope="scope">
            <a :href="scope.row.product_link" target="_blank">View Product</a>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          prop="product_sale_price_cny"
          label="Product Price"
          >
        </el-table-column>
        <el-table-column
                width="200"
                prop="product_name"
                label="Product Name"
        >
        </el-table-column>
        <el-table-column
                width="200"
                prop="product_remark"
                label="Product Remark"
        >
        </el-table-column>

        <el-table-column
                label="Operate"
                width="150">
          <template slot-scope="scope">
            <el-button size="small" round @click="add_store_form = scope.row; add_store_dialog_visible = true;">Edit</el-button><br>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination
          style="margin-top: 30px;"
          layout="prev, pager, next"
          :total="total_count"
          :current-page="current_page"
          @current-change="changePage"
      >
      </el-pagination>



      <el-dialog
        :title="'Add New Product - ' + store_type"
        :visible.sync="add_store_dialog_visible"
        width="80%"
        >
        <el-alert
                title="Bind store products and Taobao/1688 products information.You only need to export and import orders with one click, and it is very easy to manage each of your online orders.Once you add products to your store, remember to add related product information on this page. The system will recognize all your order information."
                type="warning">
        </el-alert>

        <el-form
          :inline="false"
          :model="add_store_form"
          ref="add_store_form"
          class="demo-form-inline"
          label-position="top"
          >
          <el-row class="order-form-row">
            <el-col :span="11">
              <el-form-item label="Store Product SKU" required prop="store_product_sku">
                <el-input placeholder="Input Your Store Product SKU" v-model="add_store_form.store_product_sku"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="Taobao/1688 Product Link" required prop="product_link">
                <el-input placeholder="Input Taobao/1688 Product Link" v-model="add_store_form.product_link"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="order-form-row">
            <el-col :span="11">
              <el-form-item label="Unit Price(CNY/RMB)" required prop="product_sale_price_cny">
                <el-input placeholder="Taobao/1688 Product Price" type="number" :min="0" v-model="add_store_form.product_sale_price_cny"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="Product Name" prop="product_name">
                <el-input placeholder="Taobao/1688 Product Name or Store Product Name" v-model="add_store_form.product_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="order-form-row">
            <el-col :span="22">
              <el-form-item label="Product Remarks" prop="product_remark">
                <el-input placeholder="Such as size, color, product version..." v-model="add_store_form.product_remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div style="display: flex;justify-content: center;margin-top: 30px;">
            <el-button size="small" plain type="success" @click="addNewProduct('add_store_form')" style="margin: auto;">Save Product</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
</template>
<script>


import {addNewProduct, getProductList} from "@/api/onlinestore";
    import {getAddressList} from "@/api/user";

    export default {
        name: 'MyStoreProduct',
        components: {

        },
        props: {

        },
        data() {
            return {
              add_store_dialog_visible: false,
              add_store_form: {
                store_product_sku: '',
                product_link: '',
                product_sale_price_cny: '',
                product_name: '',
                product_remark: '',
                store_id: '',
              },
              total_count: 0,
              current_page: 1,
              product_list: [],
              store_list_params: {},
              store_list_search_params: {
                store_id: '',
                store_product_sku: '',
                product_name: ''
              },
              loading: true,
              store_name: '',
              store_type:  '',
            };
        },
        created() {
          let store_id = this.$route.query.store_id || ''
          let store_name = this.$route.query.store_name || ''
          this.add_store_form.store_id = store_id
          this.store_list_search_params.store_id = store_id
          this.store_name = store_name

          this.getProductList()
        },
        methods: {
          addNewProduct(form_name){
            this.$message({message: 'Adding new product, please wait...', type: 'warning'})
            this.$refs[form_name].validate((valid => {
              if (!valid) {
                this.$message({message: 'Please check your input', type: 'warning'});
                return
              }
              addNewProduct(this.add_store_form).then((res) => {
                if (res.code === 0){
                  this.$message({message: 'New Product Added', type: 'success'});
                  this.add_store_dialog_visible = false
                  this.$refs[form_name].resetFields();
                  this.getProductList()
                } else {
                  this.$message({message: 'Failed to add new product', type: 'error'});
                }
              })
            }))
          },
          getProductList(){
            let params = this.store_list_search_params
            params.page = this.current_page
            params.page_size = 10

            this.$message({message: 'Fetching your product list', type: 'warning'});
            getProductList(params).then(res => {
              if (res.code === 0){
                this.$message({message: 'Product list fetched successfully', type: 'success'});
                this.product_list = res.data.list || []
                this.total_count = res.data.total || 0
              } else {
                this.$message({message: 'Failed to fetch product list', type: 'error'});
              }

              this.loading = false
            }).catch(error => {
              this.loading = false
            })
          },
          changePage(page) {
            this.current_page = page
            this.getProductList();
          },
          search(){
            this.current_page = 1
            this.getProductList()
          }
        }
    };
</script>
