/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Meta from 'vue-meta'
import VueI18n from 'vue-i18n'

Vue.use(Meta)


Vue.use(ElementUI);

Vue.config.productionTip = false;

Vue.use(NowUiKit);

Vue.use(VueI18n)


function getQueryVariable(variable)
{
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] == variable){return pair[1];}
  }

  return(false);
}

let lang = getQueryVariable('lang')
if (!lang) {
  lang = localStorage.getItem('langSet') == null ? "en" : localStorage.getItem('langSet')
}
localStorage.setItem('langSet', lang)

//设置默认语言
const i18n = new VueI18n({
  locale: lang,    // 语言标识
  messages: {
    'cn': require('./utils/lang/cn'),   // 中文语言包
    'en': require('./utils/lang/en')    // 英文语言包
  },
})

new Vue({
  router,
  data: function(){
    return {
      cart_num: 0,
      isLogin: false,
      notice_num: 0,
    }
  },
  render: h => h(App),
  i18n,
}).$mount('#app');
