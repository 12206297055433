<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/services/dropshipping_banner.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">{{$t('dropshipping')}}</h1>
          <div class="text-center" style="padding-top: 50px;">
            <h5>{{$t('page_dropshipping.des')}}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="guides-page">
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">What is Dropshipping?</b>
        </div>
        <p>
          Dropshipping is a business model where a seller does not keep the products it sells in stock.
        </p>
        <p>
          Instead, the seller will collect orders from its customers and send these orders to a third party platform known
          as a dropshipping supplier.
        </p>
        <p>
          The dropshipping supplier will then purchase, prepare and ship the orders to the customers, fulfilling the order
          on behalf of the seller.
        </p>
        <p>
          If you are looking to dropship from Chinese E-Commerce websites such as Taobao or 1688, TokCommerce can serve
          you as your dropshipping supplier today! Read on to find out more!
        </p>
      </div>
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">Why should you Dropship?</b>
        </div>
        <b style="font-size: 20px">1. Low Capital Investment</b>
        <p class="first_para">
          It is possible to launch a dropshipping business without having to spend lots of money on inventory or warehousing
          to store your products, since the products are purchased on demand and shipped directly to your customers via your
          dropshipping supplier.
        </p>
        <p>
          At the same time, the products are only purchased when an order from a customer is made and paid for, minimizing
          the risk of over-stocking and wastage of capital on inventory purchased up front.
        </p>
        <b style="font-size: 20px">2. Get started easily</b>
        <p class="first_para">
          Without the need to handle the logistics of your customers' order, a dropshipping business can be set up easily.
          In fact, many dropshipping business are set up from the comfort of the owners' own homes with just a laptop,
          making the barriers to entry very low as compared to traditional businesses.
        </p>
        <b style="font-size: 20px">3. Be anywhere you want to be</b>
        <p class="first_para">
          As long as you are able to liaise with your customers and suppliers, you can operate your dropshipping business
          from anywhere in the World, literally!
        </p>
        <b style="font-size: 20px">4. Huge range of products</b>
        <p class="first_para">
          Since you are not purchasing inventory upfront, you are not limited to the products you can sell.
        </p>
        <p>
          As long as your dropshipping supplier is able to source and purchase it, you are able to list it. In this way,
          you can keep up with the latest trends and changing tastes and preferences of your customers at almost no cost
          at all.
        </p>
      </div>
      <div class="dropship_section">
        <div class="section_title">
          <b style="font-size: 25px">TokCommerce as your Dropshipping Supplier</b>
          <div class="guides-card-row">
            <DropshipCard
                v-for="reason in reasons"
                :key="reason.key"
                :title="reason.title"
                :description="reason.description"
            ></DropshipCard>
          </div>
        </div>
      </div>
      <div class="section_title">
        <b style="font-size: 20px">Choose TokCommerce as your Dropshipping Supplier today</b> <br>

        <el-button type="success" style="margin-top: 30px;" @click="$router.push('/search')">Start Dropshipping</el-button>
      </div>

    </div>
  </div>
</template>

<script>
import DropshipCard from "../components/DropshipCard";
export default {
  name: "DropShipping",
  components: {DropshipCard},
  metaInfo: {
    title: 'Dropshipping - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'Dropshipping: Dropshipping is an order fulfillment method where a business doesn’t keep the products it sells in stock. Instead, the seller purchases inventory as needed from a third party—usually a wholesaler or manufacturer—to fulfill orders.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Dropshipping - TokCommerce' },
      { hid: 'og:description', name: 'og:description', content: 'Dropshipping: Dropshipping is an order fulfillment method where a business doesn’t keep the products it sells in stock. Instead, the seller purchases inventory as needed from a third party—usually a wholesaler or manufacturer—to fulfill orders.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
    ],
  },
  data(){
    return {
      reasons: [
        {
          title: "SHOP FROM ANY E-COMMERCE WEBSITE",
          description: "We can help you to buy from all of the biggest Chinese E-Commerce websites, such as Taobao, 1688, TMall, etc.!"
        },
        {
          title: "HASSLE-FREE ORDER FULFILLMENT",
          description: "Our dropshipping staff will ensure that your orders are fulfilled with quality and efficiency, so that you can conduct your business with a peace of mind!"
        },
        {
          title: 'PERSONALIZED CUSTOMER SERVICE',
          description: 'Our professional customer service staff will guide you through the entire dropshipping process, answer any of your queries and even provide you advice about sourcing products if you are unfamiliar with them!'
        },
        {
          title: "COMPLEMENTARY QUALITY CHECK",
          description: "Our dropshipping staff will conduct a quality check on the products as soon as they arrive at our warehouse, and also provide you with photos of them for you to do your own quality check. Best of all, its free-of-charge!"
        }
      ]
    }
  }
}
</script>
<style scoped>
.guides-page{
  margin: 30px 10%;
}

.guides-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 30px 20%;
  }
  .guides-card-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
  }
}

.dropship_section {
  margin-bottom: 50px;
}

.section_title {
  text-align: center;
  margin-bottom: 15px;
}

.first_para {
  margin-top: 15px;
}
</style>
