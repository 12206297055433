<template>
  <div style="display: flex;justify-content: center;margin-top: 10px">
<!--    <el-button @click="backToTop">Back to Top</el-button>-->
    <el-button type="success" @click="backToHome">Start Using TokCommerce</el-button>
  </div>
</template>

<script>
export default {
  name: "BackToTopAndHomeButtons",
  // props: {
  //   'top_id': String
  // },
  methods: {
    // backToTop(){
    //   this.$router.push('/guides/' + this.top_id)
    // },
    backToHome(){
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
