module.exports = {
  app_name: 'TokCommerce',
  shopping_agent: 'Shopping Agent',
  forwarding: 'Forwarding',
  services: 'Services',
  about: 'About',
  expert_buy: 'Expert Buy',
  shipping_calculator: 'Shipping Calculator',
  customer_review: 'Customer Review',
  dropshipping: 'Dropshipping',
  business_service: 'Business Service',
  trusted_seller: 'Trusted Seller',
  blog: 'Blog',
  user_guides: 'User Guides',
  terms_of_service: 'Terms of Service',
  terms_of_shipping: 'Terms of Shipping',
  privacy_policy: 'Privacy Policy',
  faq: 'FAQ',
  home: 'Home',
  notice: 'Notice',
  cart: 'Cart',
  me: 'Me',
  service_menu: 'Services Menu',
  account: 'Account',
  user_center: 'User Center',
  my_order: 'My Order',
  my_store: 'My Store',
  log_out: 'Logout',
  sign_in: 'Sign in',
  sign_up: 'Sign Up',
  compensation_taxation: 'Compensation and Taxation',
  tok_forum: 'Forum',
  get_community_help: 'Join our forum',
  community_des: 'Join our forum to help and get helped',
  page_home: {
    title: 'Buy from China, Ship from China',
    description: 'The Most Affordable Agent',
    search_product_text: 'Paste Product URL',
    who_we_are_title: 'Who We Are',
    who_we_are_line1: 'We are a professional shopping agent for Taobao/Tmall/1688/Weidian/Yupoo and other mainstream Chinese e-commerce platform.',
    who_we_are_line2: 'A better China Freight Forwarder for you. We provide cheapest and efficient shipping services.',
    our_services: 'Our Services',
    service_shopping_agent: 'Shop for a wide variety of products at low prices from Chinese E-Commerce websites',
    service_forwarding_agent: 'Ship your packages from China to anywhere you may be in the World, at the most affordable prices',
    service_expert_buy: 'Our experienced shopping staff will source the best products for you',
    service_dropshipping: 'Fulfill orders for your online business at your fingertips with TokCommerce',
    shop_now: 'Shop Now',
    ship_now: 'Ship Now',
    logistics_service_title: 'We provide very efficient and cheap shipping services',
    calculate_now: 'Calculate your shipping costs from here',
    calculate: 'Shipping Calculator',
    products: 'Products',
    products_des: 'TokCommerce provides a plethora of quality products at the lowest prices from Chinese E-Commerce websites',
    warehousing: 'Warehousing',
    warehousing_des: 'Store and consolidate your products at our TokCommerce warehouse for up to 30 days for free',
    packing: 'Packaging',
    packing_des: 'Complementary repackaging for all of your products using quality packaging to reduce your freight forwarding fees',
    forwarding_des: 'Our logistics partners ensure quality and efficient shipping and delivery of your packages at cheap and affordable prices',
    quality_check: 'Quality Check',
    quality_check_des: 'Free quality check for all your products, and pictures for you to visually inspect them',
    dropshipping_des: 'Reliable product sourcing and order fulfillment for your dropshipping business',
    latest_shopping: 'Latest Shopping',
    why_tokcommerce: 'Why TokCommerce?',
    why_tokcommerce_line1: 'A more professional shopping and forwarding agent from China. We focus on providing Taobao/1688/Weidian/Yupoo shopping and forwarding services for global customers. The one-stop shopping experience makes it easier for every customer to use. Professional customer service and high-quality after-sales service solve all your troubles.',
    why_tokcommerce_line2: 'As a professional shopping service provider, we help you to buy a variety of products at ultra-low prices from China\'s largest domestic online shopping sites. We are your exclusive agent to find, buy, inspect and deliver goods from mainstream Chinese e-commerce platforms.',
    why_tokcommerce_line3: 'We provide efficient and cheap international shipping services, serving the United States, Canada, the United Kingdom, Australia, France, Singapore, Belgium, Sweden, South Africa and many other countries. Wherever you are, we deliver packages directly to your door. We provide online tracking information for all overseas shipments.',
    contact_use_title: 'Contact us',
    contact_use_des: 'Simply submit a question form, and we will reply you via email.',
    submit_question: 'Submit Question',
    video_title: 'Beginner\'s Tutorial',
    customer_support: 'Customer Support',
    service_email: 'Service Email',
    b2b_email: 'B2B and marketing cooperation inquiry',
    destination: 'Destination',
    shipping_price: 'Price',
    method: 'Method',
  },
  page_shopping: {
    shopping_des: 'Shop from China with TokCommerce',
    shopping_step: '1.Find & Search Product  2.Pay for items  3.Buy for you  4.Pay for shipping  5.Ship for you',
    category: 'Category',
    load_more_items: 'Load more items',
    note: 'Disclaimer: All products available for shopping agent service displayed on TokCommerce are from third-party shopping platforms and not directly sold by TokCommerce. Thus, TokCommerce does not take liability and legal responsibility for issues caused by intellectual property rights infringement and goods copyright infringement.',
    bottom_text: 'As a professional Taobao agent, we help you to buy a lot of products at super low prices from China\'s largest shopping sites. We are your Taobao agent to find, buy, inspect and deliver items from Taobao.com,1688.com. Our quotation includes sourcing from China, purchasing and international shipping. TokCommerce provides the biggest discounts for international shipping from China to all countries, including the US, Canada, UK, Australia, France, Singapore, Mexico, Brazil, Chile, and more. Wherever you are, we deliver parcels directly to your door. We provide online tracking information for all shipments. We have professional customer service managers to help you shop and ship successfully.',
    process_data: 'Process Data',
    process_des: 'You can know everything about your order. Every time you care about, we have data to tell you everything.',
  },
  page_forwarding: {
    title: 'International Freight Forwarding',
    des: 'Ship your parcel from China',
    forwarding_step: '1.Buy yourself 2.Ship to warehouse 3.Quality Inspection 4.Pay for shipping 5.Ship to you',
    country_name: 'Destination Country',
    product_name: 'Product Name',
    tracking_number: 'Tracking Number',
    tracking_number_des: 'Taobao/1688/Weidian tracking number',
    product_limit: 'Product Picture(Limit 1M)',
    submit_text: 'Submit Parcel',
    submit_note: 'Remember to ship your parcel to our warehous; TokCommerce will no longer support #sea freight# services;',
    how_to_use: 'How to use?',
    service_line1: '1. You need to buy from Taobao/1688/JD/Weidian and ship to our warehouse.',
    service_line2: '2. You need to submit a Forwarding Order on this page once you have the parcel tracking number.',
    service_line3: '3. Waiting for your parcel to arrive at our warehouse.We will identify your package based on the tracking number.',
    service_line4: '4. We will let you know by mail if the parcel arrives at the warehouse.',
    service_line5: '5. You need to submit to ship and pay the international shipping fee.',
    service_line6: '6. We ship the parcel to you.',
    cost_note: 'For an estimate of international shipping costs before placing your shipping order, you may use our',
    view_forwarding: 'View Forwarding Order',
    warehouse_address: 'Warehouse Address',
    item_category: 'Item Category',
    warehouse_phone: 'Phone',
    warehouse_contact_name: 'Contact Name',
    warehouse_province: 'Province',
    warehouse_city: 'City',
    warehouse_district: 'District',
    warehouse_street: 'Street',
    warehouse_detail: 'Building',
    warehouse_postcode: 'Postal Code',
    service_name: 'Freight Forwarding Service',
    service_des: 'Our Freight Forwarding service is one of the main services that we provide for you here at TokCommerce. Whether you are buying from a major E-Commerce website like Taobao, or you already have products in China which you wish to ship, TokCommerce has got you covered.By partnering with logistics companies with over 40 years of experience in the industry, such as FedEx, DHL, UPS, etc., TokCommerce aims to provide efficient and quality freight forwarding at an affordable price.Wherever you may be in the World, TokCommerce will find a way to get your package safely to your doorstep, giving you a peace of mind when you are shipping with us.',
  },
  page_expert: {
    des: 'Can not find product? Help global users buy from Taobao/Weidian/1688/Yupoo',
    past_product_name: 'Paste product name here',
    product_url: 'Product Url',
    unit_price: 'Unit Price',
    past_unit_price: 'Paste product price here',
    quality: 'Quality',
    delivery_fee: 'Delivery Fee',
    delivery_fee_note: 'Chinese Domestic Delivery Fee. If not sure, fill 0 in the blank',
    product_remark: 'Product Remarks',
    product_remark_note: 'Such as size, color, product version...',
    add_to_cart: 'Add to cart',
    step_line1: '1. Find your product from Taobao/1688/Weidian/Tmall or other platforms.',
    step_line2: '2. Copy the product information and add to cart.',
    step_line3: '3. Visit your shopping cart to make payment for your product.',
    step_line4: '4. We purchase for you and wait for the parcel to arrive at our warehouse.',
    step_line5: '5. Submit address and pay shipping fee after parcel arrives at our warehouse.',
    about_expert_buy: 'About Expert Buy',
    about_des: 'Our Expert Buy feature is part of the shopping agent services that TokCommerce provides for you. You can use our Expert Buy feature if our Shopping Agent page is unable to fetch data on your products via your product URL link. It supports product URL links from all major Chinese E-Commerce websites, such as Taobao, 1688, TMall, Weidian and many more. If you can find it, we can buy and ship it for you, wherever you may be in the World! Find out more about how to use our Expert Buy feature using our Shopping Agent Guide.',
    product_shipping_note: 'International shipping fee is not included,it will be calculated and charged after gooods arriving our warhouse',
    product_detail_remark: 'Add your note about the product here,such as size color seller contact infomation...',
    expert_btn: 'If unable to add to cart, click the button # Expert Buy #',
    product_show_price: 'Price',
    product_show_quantity: 'Quantity',
    product_show_shipping: 'Shipping',
  },
  page_calculator: {
    des: 'Calculate the global shipping price',
    item_category: 'Item Category',
    product_weight: 'Weight',
    product_length: 'Length',
    product_width: 'Width',
    product_height: 'Height',
    check: 'Check Freight',
    cost_note: 'Note: The parcel weight should not be less than 2.5KG for UPS/DHL/FedEx. Otherwise only can ship by #Tok Line#',
    about_service: 'Our Shipping Calculator is a complementary feature that we offer on our TokCommerce website as part of our shopping agent and freight forwarding services. Using our shipping calculator, you can estimate the approximate shipping fee that you would need to pay given the dimensions of your products or package, and your destination country. You can also use our shipping calculator to check up on the different shipping companies available to ship to your destination country, as well as their respective estimate shipping times.',
    service_title: 'About Calculator',
    common_goods: 'Common goods',
    brand_goods: 'Branded goods',
    liquid_goods: 'Liquid goods',
    battery_goods: 'Battery goods',
    shipping_method: 'Shipping Method',
    delivery_time: 'Delivery Time',
    warehouse: 'Warehouse',
    estimated_cost: 'Estimated Cost',
    method: 'Method',
  },
  page_message: {
    title: 'Message',
    des: 'You need to pay attention to this part of the news, because we will send you a message or email.',
  },
  page_cart: {
    title: 'Shopping Cart',
    item: 'Item',
    product: 'Product',
    price: 'Price',
    quantity: 'Quantity',
    freight: 'Freight',
    total: 'Total',
    info: 'Info',
    operate: 'Operate',
    pay_now: 'Submit Order',
    confirm_buy: 'Confirm to buy？',
    yes:'Yes',
    no:'No',
  },
  page_settlement: {
    title: 'Confirm Purchase',
    information: 'Note',
    note_line1: '1. Take photos of the product for free',
    note_line2: '2. The payment amount includes commodity cost, payment handling fee and service fee',
    note_line3: '3. We will inform you about the progress of the order by email. Please check in time',
    note_line4: '4. When your parcels arrive at warehouse you need to pay shipping cost',
    note_line5: '5. You need to pay transaction fee for every payment(Paypal)',
    address_note: 'Please choose your destination country in order for our Shopping Agent to distinguish whether the item can be delivered or not.',
    submit_order: 'Submit Order',
    confirm_submit: 'Confirm to submit？',
  },
  page_shopping_payment: {
    title: 'Checkout Count',
    des: "Notice: This page can only pay for products. Shipping costs are paid on the #Int'l Parcels#  page.",
    total: 'Total',
    order_no: 'Order No',
    pay_now: 'Pay Now',
    shopping_notice: 'Shopping Notice:',
  },
  page_person: {
    account: 'Account',
    user_center: 'User Center',
    wallet: 'Wallet',
    orders: 'Orders',
    my_order: 'My Order',
    my_warehouse: 'In Warehouse',
    my_parcel: "Int'l Parcels",
    address_book: 'Address Book',
    online_store: 'Online Store',
    my_store: 'My Store',
    activity: 'Activity',
    invite: 'Invite',
    coupons: 'Coupons',
  },
  page_seller: {
    title: 'Trusted Seller',
    buy_hanfu_title: 'Buy Chinese Hanfu from these sellers, then we ship your parcel to you',
    buy_sneaker_title: 'Buy Sneaker from these sellers, then we ship your parcel to you',
    buy_cosplay_title: 'Buy Cosplay from these sellers, then we ship your parcel to you',
    step_line1: '1. Find products from these sellers',
    step_line2: '2. Submit shopping order',
    step_line3: '3. Ship to you',
  },
  page_profile: {
    account: 'Account',
    email: 'Email',
    balance: 'Balance',
  },
  page_wallet: {
    top_up: 'Top Up',
    underpaid: 'Underpaid',
    balance_details: 'Balance Details',
    time: 'Time',
    empty: 'Empty Record',
    amount: 'Amount',
    status: 'Status',
    info: 'Info',
    pay: 'Pay',
    paid: 'Paid',
    confirm: 'Confirm',
    discount_info: 'Discount Info',
    and_get: 'and get',
    get_extra: 'extra',
  },
  page_my_order: {
    product_pic: 'Product Pic',
    product_qc: 'QC Pic',
    store_order_no: 'Store Order No',
    type: 'Type',
    details: 'Details',
    remark: 'Remark',
    process: 'Process',
    cn_track: 'CN Track',
    pay_order: 'Pay Order',
    search: 'Search',
  },
  page_order_detail: {
    title: 'Order Detail',
  },
  my_warehouse: {
    submit_to_ship: 'Submit To Ship',
    actual_weight: 'Actual Weight',
    add_address: 'Add Address',
    select_method: 'Select Method',
    pay_order: 'Pay Order',
    consignee_name: 'Consignee Name',
    country_code: 'Country',
    consignee_province: 'State/Province',
    consignee_city: 'Consignee City',
    consignee_address_detail: 'Address Detail',
    consignee_postcode: 'Consignee Postcode',
    consignee_phone: 'Consignee Phone',
    consignee_email: 'Consignee Email',
    next: 'Next',
    shipping_cost: 'Shipping Cost',
    method_note: 'The administrator will choose a better shipping method for you, you can click Next button',
    submit_order: 'Submit Order',
    parcel_remark: 'Parcel Remark - Ask us to customize your parcel',
    such_as_parcel: '(Such as: Remove boxes)',
  },
  page_my_parcel: {
    delivery_number: 'Delivery Number',
    pay_shipping: 'Pay Shipping',
    address: 'Address',
    tracking: 'Tracking',
  },
  page_parcel_detail: {
    title: 'My Parcel',
    coupon_code: 'Coupon Code',
    user_remark: 'Parcel Remark',
    tracking_info: 'Tracking Info',
  },
  page_customer_review: {
    des: 'Share our services everywhere',
    submit_link: 'Submit Review Link',
  },
  page_dropshipping: {
    des: 'Make your business more successful',
  },
  page_invite: {
    your_share_link: 'Your Share Link',
    invitation_service: 'Invitation Service - You will get more',
    invitation_analysis: 'Invitation Analysis',
    get_cash_benefits: 'Get cash benefits - 0.5USD for one Invite User',
    cheap_cost: 'Get cheaper shipping cost',
    find_product: 'Find cheap products for you freely',
    invitation_users: 'Invitation Users',
    invitation_amount: 'Invitation Order Amount',
  },
  page_coupon: {
    available: 'Available Coupon',
    used: 'Coupon Used',
    expired: 'Coupon Expired',
  },
  page_taxation: {
    title: 'Compensation and Taxation',
    disclaimer: 'Disclaimer',
    d_line1: '1. No compensation will be given for the lost of mail due to force majeure such as natural disasters or customs reasons',
    d_line2: '2. If the delivered items violate the prohibition or restriction regulations, the competent authority will confiscate them or deal with them in accordance with relevant laws and regulations, and no compensation will be given',
    d_line3: '3. No compensation will be given for the lost of the mail or the shortage of the contents due to the responsibility of the customer or the reasons for the sent item itself',
    d_line4: '4. If the international mail is detained, confiscated or destroyed by the destination country according to its domestic laws, no compensation will be given',
    d_line5: '5. The user is responsible for the package that involves paying the tax',
    d_line6: '6. No freight compensation for customs reasons',
    d_line7: '7. Other than the above, compensation actual freight, and the maximum compensation is 400 RMB',
  },
  meta: {
    index_title: 'TokCommerce: Taobao Agent | Tmall Agent | 1688 Agent | Taobao English | Shopping Agent | China Forwarder',
    index_des: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.',
    shopping_agent_title: 'Shopping Agent - TokCommerce',
    shopping_agent_des: 'TokCommerce: Buy China products with shopping agent. Taobao agent, 1688 agent, Weidian agent, Yupoo agent.',
    forwarding_title: 'Forwarding Agent - Ship your parcel from China',
    forwarding_des: 'Professional global transshipment platform, with transshipment warehouses and providing professional logistics services to the United States, the United Kingdom, Canada, Germany, Australia, Singapore, Mexico and other countries, transshipment worldwide',
    expert_buy_title: 'Expert Buy - TokCommerce',
    expert_buy_des: 'Buy products from Taobao Agent, Import from China. Lowest shipping prices. No service fee.',
    calculator_title: 'International logistics freight estimate - TokCommerce',
    calculator_des: 'Help you estimate global logistics costs. Affordable logistics service. Faster logistics service.',
    trusted_seller_title: 'Trusted Seller - TokCommerce',
    trusted_seller_des: 'Trusted Seller: Shop from these sellers, then we ship to you. Taobao sellers, Weidian sellers.',
  },
}
