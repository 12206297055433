<template>
  <el-card class="class-size">
    <b>
      {{ this.title }}
    </b>
    <p>{{this.description}}</p>
  </el-card>
</template>

<script>
export default {
  name: "DropshipCard",
  props: {
    title: String,
    description: String
  }
}
</script>

<style scoped>
.class-size{
  margin-bottom: 20px;
  text-align: center;
  border-radius: 10px;
}
@media screen and (min-width: 1200px){
  .class-size{
    width: 400px;
    /*height: 220px;*/
    margin: 10px;
  }
}
</style>