import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import AboutUs from './pages/AboutUs';
import Login from './pages/Login.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import Register from './pages/Register'
import Search from './pages/Search'
import ShoppingCart from './pages/Shopping/ShoppingCart'
import Settlement from './pages/Shopping/Settlement'
import Payment from './pages/Shopping/Payment'
import ShippingPayment from './pages/Shopping/ShippingPayment'
import PaySuccess from './pages/Shopping/PaySuccess'
import PayFailed from './pages/Shopping/PayFailed'
import BalancePaySuccess from './pages/Shopping/BalancePaySuccess'
import BalancePayFailed from './pages/Shopping/BalancePayFailed'
import ShippingPaySuccess from './pages/Shopping/ShippingPaySuccess'
import ShippingPayFailed from './pages/Shopping/ShippingPayFailed'
import ManageMain from './pages/Manage/Main'

import ManageMainMobile from './pages/Manage/MainMobile'
import UserCenter from './components/Account/MyProfile'
import MyOrders from './components/Orders/MyOrders'
import MyWarehouse from './components/Orders/MyWarehouse'
import ShippedParcels from './components/Orders/ShippedParcels'
import AddressBook from './components/Orders/AddressBook'
import InviteFriend from './components/Activity/InviteFriend'
import CouponList from './components/Account/CouponList'
import Wallet from './components/Account/Wallet'
import WalletPayment from './components/Account/WalletPayment'


import ProductDetail from './pages/Product/Detail'
import ServiceSource from './pages/Service/Source'
import ServiceForwarding from './pages/Service/Forwarding'
import CustomerReview from './pages/Service/CustomerReview'
import ServiceShippingCalculate from './pages/Service/ShippingCalculate'
import VerifyAccount from './pages/VerifyAccount'
import RegisterSuccess from './pages/RegisterSuccess'
import OrderDetail from './pages/OrderDetail'
import DropShipping from "./pages/Service/DropShipping";
import BusinessService from "./pages/Service/BusinessService"
import FaqMain from "./pages/Faq/FaqMain";
import TrustSeller from "./pages/Service/TrustSeller"
import CompensationTaxation from "./pages/Service/CompensationTaxation"


import GuideMain from "./pages/Guides/GuideMain";
import GuideTaobaoTokCommerce from "./pages/Guides/GuideTaobaoTokCommerce";
import GuideFreightForwarding from "./pages/Guides/GuideFreightForwarding";
import GuideUserGuide from "./pages/Guides/GuideUserGuide";
import GuideReasonsShoppingAgent from "./pages/Guides/GuideReasonsShoppingAgent";
import GuideTokCommerceShoppingForwarding from "./pages/Guides/GuideTokCommerceShoppingForwarding";
import TaobaoForwarderCheapest from "./pages/Guides/TaobaoForwarderCheapest"
import Best1688Agent from "./pages/Guides/Best1688Agent"
import HowToChooseTaobaoAgent from "./pages/Guides/HowToChooseTaobaoAgent"
import TaobaoAgent from "./pages/Guides/TaobaoAgent";
import TaobaoForwarderToUsa from "./pages/Guides/TaobaoForwarderToUSA"


import { getToken } from '@/utils/auth'
import ShoppingOrderDetail from './pages/Shopping/OrderDetail'
import ShoppingDeliveryDetail from './pages/Shopping/DeliveryDetail'
import TermsOfService from "./pages/Documents/TermsOfService";
import TermsOfShipping from "./pages/Documents/TermsOfShipping";
import PrivacyPolicy from "./pages/Documents/PrivacyPolicy";
import UserNotice from "./pages/UserNotice"
import BlogList from "./pages/BlogList"
import BlogDetail from "./pages/BlogDetail"
import ServiceMenuMobile from "./pages/ServiceMenuMobile"

Vue.use(Router);


const routes = [
  {
    path: '/',
    name: 'index',
    components: { default: Index, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  {
    path: '/order-detail',
    name: 'order-detail-tmp',
    components: { default: OrderDetail, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  {
    path: '/login',
    name: 'login',
    components: { default: Login, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },
  {
    path: '/register',
    name: 'register',
    components: { default: Register, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },
  {
    path: '/verify_account',
    name: 'verifyAccount',
    components: { default: VerifyAccount, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },
  {
    path: '/register-success',
    name: 'register-success',
    components: { default: RegisterSuccess, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },


  {
    path: '/search',
    name: 'search',
    components: { default: Search, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    }
  },

  {
    path: '/product/detail',
    name: 'detail',
    components: { default: ProductDetail, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },

  {
    path: '/shopping/my-cart',
    name: 'my-cart',
    components: { default: ShoppingCart, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shopping/settlement',
    name: 'settlement',
    components: { default: Settlement, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shopping/payment',
    name: '/shopping/payment',
    components: { default: Payment, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shopping/shipping-payment',
    name: '/shopping/shipping-payment',
    components: { default: ShippingPayment, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shopping/pay_success',
    name: '/shopping/pay_success',
    components: { default: PaySuccess, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shopping/pay_failed',
    name: '/shopping/pay_failed',
    components: { default: PayFailed, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shipping/pay_success',
    name: '/shipping/pay_success',
    components: { default: ShippingPaySuccess, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shipping/pay_failed',
    name: '/shipping/pay_failed',
    components: { default: ShippingPayFailed, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shipping/order-detail-new',
    name: '/shipping/order-detail-new',
    components: { default: ShoppingOrderDetail, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/shipping/delivery-detail-new',
    name: '/shipping/delivery-detail-new',
    components: { default: ShoppingDeliveryDetail, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },









  {
    path: '/manage/main',
    name: 'manage-main',
    components: { default: ManageMain, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },




  {
    path: '/manage/main-mobile',
    name: 'manage-main-mobile',
    components: { default: ManageMainMobile, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/Account-MyProfile',
    name: '/manage/main-mobile/Account-MyProfile',
    components: { default: UserCenter, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/orderList-MyOrders',
    name: '/manage/main-mobile/orderList-MyOrders',
    components: { default: MyOrders, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/orderList-MyWarehouse',
    name: '/manage/main-mobile/orderList-MyWarehouse',
    components: { default: MyWarehouse, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/orderList-ShippedParcels',
    name: '/manage/main-mobile/orderList-ShippedParcels',
    components: { default: ShippedParcels, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/orderList-AddressBook',
    name: '/manage/main-mobile/orderList-AddressBook',
    components: { default: AddressBook, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/Activity-InviteFriend',
    name: '/manage/main-mobile/Activity-InviteFriend',
    components: { default: InviteFriend, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/Account-Coupons',
    name: '/manage/main-mobile/Account-Coupons',
    components: { default: CouponList, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/Account-Wallet',
    name: '/manage/main-mobile/Account-Wallet',
    components: { default: Wallet, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/manage/main-mobile/wallet-payment',
    name: '/manage/main-mobile/wallet-payment',
    components: { default: WalletPayment, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/balance/pay_success',
    name: '/balance/pay_success',
    components: { default: BalancePaySuccess, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },
  {
    path: '/balance/pay_failed',
    name: '/balance/pay_failed',
    components: { default: BalancePayFailed, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },













  {
    path: '/service/source',
    name: 'service-source',
    components: { default: ServiceSource, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  {
    path: '/service/forwarding',
    name: 'service-forwarding',
    components: { default: ServiceForwarding, header: MainNavbar, footer: MainFooter },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  },
  {
    path: '/service/shipping-calculate------',
    name: 'service-shipping-calculate',
    components: {default: ServiceShippingCalculate, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/service/customer-review',
    name: 'service-customer-review',
    components: {default: CustomerReview, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/service/dropshipping',
    name: 'service-dropshipping',
    components: {default: DropShipping, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/service/business-service',
    name: '/service/business-service',
    components: {default: BusinessService, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/service/trust-seller',
    name: '/service/trust-seller',
    components: {default: TrustSeller, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/service/compensation-taxation',
    name: '/service/CompensationTaxation',
    components: {default: CompensationTaxation, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },



  {
    path: '/guides/guide-main',
    name: 'guide-main',
    components: {default: GuideMain, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/guide-taobao-tokcommerce',
    name: 'guide-taobao-tokcommerce',
    components: {default: GuideTaobaoTokCommerce, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/guide-user-guide',
    name: 'guide-user-guide',
    components: {default: GuideUserGuide, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/guide-freight-forwarding',
    name: 'guide-freight-forwarding',
    components: {default: GuideFreightForwarding, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/guide-reasons-shopping-agent',
    name: 'guide-reasons-shopping-agent',
    components: {default: GuideReasonsShoppingAgent, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/taobao-agent',
    name: '/guides/taobao-agent',
    components: {default: TaobaoAgent, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/how-to-choose-taobao-agent',
    name: 'how-to-choose-taobao-agent',
    components: {default: HowToChooseTaobaoAgent, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/guide-tokcommerce-shopping-forwarding',
    name: 'guide-tokcommerce-shopping-forwarding',
    components: {default: GuideTokCommerceShoppingForwarding, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/best1688agent',
    name: '/guides/best1688agent',
    components: {default: Best1688Agent, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/taobao-forwarder-cheapest',
    name: '/guides/taobao-forwarder-cheapest',
    components: {default: TaobaoForwarderCheapest, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/guides/taobao-forwarder-to-usa',
    name: '/guides/taobao-forwarder-to-usa',
    components: {default: TaobaoForwarderToUsa, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },


  {
    path: '/documents/terms-of-service',
    name: 'terms-of-service',
    components: {default: TermsOfService, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/documents/terms-of-shipping',
    name: 'terms-of-shipping',
    components: {default: TermsOfShipping, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/documents/privacy-policy',
    name: 'privacy-policy',
    components: {default: PrivacyPolicy, header: MainNavbar, footer: MainFooter},
    props: {
      header: {colorOnScroll: 400},
      footer: {backgroundColor: 'black'}
    }
  },

  {
    path: '/message/list',
    name: '/message/list',
    components: { default: UserNotice, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
    meta: {
      needLogin: true //需要登录
    }
  },

  {
    path: '/blog/list',
    name: '/blog/list',
    components: { default: BlogList, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
  },
  {
    path: '/blog/detail',
    name: '/blog/detail',
    components: { default: BlogDetail, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
  },
  {
    path: '/faq/faqmain',
    name: '/faq/faqmain',
    components: { default: FaqMain, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
  },
  {
    path: '/service/menu',
    name: '/service/menu',
    components: { default: ServiceMenuMobile, header: MainNavbar, footer: MainFooter},
    props: {
      header: { colorOnScroll: 400 }
    },
  },


  { path: '*', redirect: '/', hidden: true }
];


const router = new Router({
  routes,
  linkExactActiveClass: 'active',
  mode: 'history',
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
})


//判断是否需要登录
router.beforeEach((to, from, next) => {
  let token = getToken()
  if (to.meta.needLogin) { // 判断该路由是否需要登录权限
    if (token) { // 判断是否已经登录
      next()
    } else {
      next({path: '/login'}) //跳转到登录页
    }
  } else {
    next()
  }
})


export default router



