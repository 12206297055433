<template>
  <div style="min-height: 2000px">
    <div class="section-about-us" style="margin-top: 100px;margin-left: 25%;margin-right: 25%">
      <h1 style="text-align: center;margin-bottom: 40px;font-size: 30px;font-weight: bold">Frequently Asked Questions</h1>
      <el-autocomplete
        v-model="search_phrase"
        :fetch-suggestions="searchFaqs"
        placeholder="Search FAQs"
        @select="handleSearch"
        style="width: 100%;margin-bottom: 30px"></el-autocomplete>
      <el-row>
        <el-col span="8">
          <el-menu
              @select="handleSelect"
              class="el-menu-vertical-demo">
            <el-submenu
                v-for="category in faq_category_list"
                :key="category.id"
                :index="category.id">
              <template slot="title">
                <span slot="title">{{category.faq_category_name}}</span>
              </template>
              <template
                  v-for="faq in faq_list">
                <el-menu-item
                    v-if="faq.faq_category_id == category.id"
                    :key="faq.id"
                    :index="faq.id"
                >{{faq.faq_question}}</el-menu-item>
              </template>
            </el-submenu>
          </el-menu>
        </el-col>
        <el-col span="10">
          <div style="margin-left: 30px;margin-top: 10px;">
            <h2 style="font-size: 20px">{{this.current_faq.question}}</h2>
            <vue-markdown :source="current_faq.answer"></vue-markdown>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getFaq, getFaqCategoryList, getFaqList} from "../../api/faq";
import VueMarkdown from "vue-markdown";

export default {
  name: "Main",
  components: {
    VueMarkdown
  },
  metaInfo: {
    title: 'FAQ - TokCommerce',
  },
  data() {
    return {
      faq_list: [],
      faq_category_list: [],
      search_phrase: '',
      timeout: null,
      current_faq: {
        id: 0,
        question: '',
        answer: ''
      }
    }
  },
  created() {
    this.getFaqList()
    this.getFaqCategoryList()
  },
  methods: {
    getFaqList(){
      let params = {}
      getFaqList(params).then((res) => {
        if (res.code === 0){
          this.faq_list = res.data || []
        } else {
          this.$message({message: 'Could not fetch FAQs', type: 'error'});
        }
      })
    },
    getFaqCategoryList(){
      let params = {}
      getFaqCategoryList(params).then((res) => {
        if (res.code === 0){
          this.faq_category_list = res.data
        } else {
          this.$message({message: 'Could not fetch FAQ categories', type: 'error'});
        }
      })
    },
    handleSelect(index, indexPath){
      let params = {}
      params.id = index
      getFaq(params).then((res) => {
        if (res.code === 0){
          this.current_faq.question = res.data[0]['faq_question']
          this.current_faq.answer = res.data[0]['faq_answer']
        } else {
          this.$message({message: 'Could not fetch FAQ', type: 'error'});
        }
      })
    },
    searchFaqs(queryString, cb) {
      let faq_list = faq_list
      let res = queryString ? faq_list.filter((faq) => {
        return (faq['faq_question'].toLowerCase().contains(queryString.toLowerCase()))
      }) : faq_list
      cb(res)
    },
    handleSearch(item){
      this.handleSelect(item['id'])
    }
  }
}
</script>

<style scoped>

</style>
