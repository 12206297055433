<template>
  <div class="page-header clear-filter">
<!--    <div-->
<!--      class="page-header-image"-->
<!--      style="background-image: url('img/login.jpg')"-->
<!--    ></div>-->
    <div class="content">
      <div class="container">
        <div class="col-md-10 ml-auto mr-auto">

            <div style="background: none;padding-top: 50px;">
                <div class="container" style="color: black;">

                    <h2>Order Detail</h2>

                    <div v-if="currentOrder">

                        <div style="text-align: left;">
                            <p>Order ID : {{currentOrder.order_id}}</p>
                            <p>Order Type : {{currentOrder.order_type_text}}</p>
                            <p>Pay Time : {{currentOrder.order_time}}</p>
                            <p>Status : {{currentOrder.order_status_text}}</p>
                            <p>Product cost : {{currentOrder.discount_amount}}</p>
                            <p>Transport cost : {{currentOrder.transport_amount}}</p>
                            <p>Total : {{currentOrder.total_amount}}</p>
                        </div>

                        <el-table
                                empty-text="Your order is empty"
                                :data="currentOrder['items']"
                                tooltip-effect="dark"
                                style="width: 100%;margin-top: 30px;"

                        >
                            <el-table-column
                                    prop="product_name"
                                    label="Name">
                            </el-table-column>
                            <el-table-column
                                    prop="buy_num"
                                    label="Quantity">
                            </el-table-column>
                            <el-table-column
                                    prop="sku_discount_price"
                                    label="Unit Price">
                            </el-table-column>
                            <el-table-column
                                    prop="sum_discount_amount"
                                    label="Total Price">
                            </el-table-column>
                        </el-table>


                    </div>

<!--                    <SfTab title="Update Address" v-if ="currentOrder">-->
<!--                        <SfHeading-->
<!--                                :level="3"-->
<!--                                :title="'Order ID: ' + order_detail_id"-->
<!--                        />-->
<!--                        <ShippingAddressFormOrder-->
<!--                                :address="address_form"-->
<!--                                :isNew="false"-->
<!--                                :showDefault="false"-->
<!--                                @submit="updateAddress"-->
<!--                        />-->
<!--                    </SfTab>-->

                </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
    import { getOrderDetail } from '@/api/orders'
    import { payOrder } from '@/api/orders'
    import { getOrderRecords, updateOrderAddress } from '@/api/orders'

    export default {
        metaInfo: {
            title: 'Order Detail',
            meta: [
                { hid: 'description', name: 'description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Order Detail' },
                { hid: 'og:description', name: 'og:description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {

        },
        data() {
            return {
                search_form: {
                    page: 1,
                    page_size: 20,
                    order_status: ''
                },
                orders: [],
                totalOrders: 0,
                currentOrder: null,
                order_detail_id: "",
                order_id: '',
                order_records: [],
                address_form: {
                    order_id: '',
                    consignee_name: '',
                    consignee_country: '',
                    consignee_province: '',
                    consignee_city: '',
                    consignee_address_detail: '',
                    consignee_postcode: '',
                    consignee_phone: '',
                    consignee_email: '',
                },
                source_list: []
            }
        },
        created() {
            this.getDetail()
        },
        methods: {
            getDetail() {
                this.order_detail_id = this.$route.query.order_id//要处理的订单详情
                if (!this.order_detail_id) {
                    return
                }
                getOrderDetail({order_id: this.order_detail_id}).then(res => {
                    if (res.code === 0) {
                        this.currentOrder = res.data;

                        this.address_form = {}
                        this.address_form.user_name = this.currentOrder.consignee_name
                        this.address_form.country = this.currentOrder.consignee_country
                        this.address_form.province = this.currentOrder.consignee_province
                        this.address_form.city = this.currentOrder.consignee_city
                        this.address_form.address_detail = this.currentOrder.consignee_address_detail
                        this.address_form.postal_code = this.currentOrder.consignee_postcode
                        this.address_form.phone = this.currentOrder.consignee_phone
                        this.address_form.email = this.currentOrder.consignee_email
                        this.address_form.order_id = this.currentOrder.order_id
                    } else {
                        this.$message({message: 'Failed to retrieve order details', type: 'error'});
                    }
                })

                this.getOrderRecordList()
            },
            getOrderStatusClass(order_status) {
                let order_status_class = ''
                let success = [2, 5, 6, 7]
                let warning = [0, 1, 3, 4]
                if (success.includes(order_status)) {
                    order_status_class = 'text-success'
                } else if (warning.includes(order_status)) {
                    order_status_class = 'text-warning'
                }
                return order_status_class
            },
            payOrder(order_data) {
                payOrder(order_data).then(res => {
                    if (res.code === 0 && res.data != '') {
                        window.location = res.data || ''
                    } else {
                        this.$message({message: 'Failed To Pay', type: 'error'});
                    }
                })
            },
            getOrderRecordList() {
                getOrderRecords({order_id: this.order_detail_id}).then(res => {
                    if (res.code === 0) {
                        this.order_records = res.data || []
                    }
                })
            },
            updateAddress(address) {
                this.address_form = {}
                this.address_form.order_id = this.order_detail_id
                this.address_form.consignee_name = address.user_name
                this.address_form.consignee_country = address.country
                this.address_form.consignee_province = address.province
                this.address_form.consignee_city = address.city
                this.address_form.consignee_address_detail = address.address_detail
                this.address_form.consignee_postcode = address.postal_code
                this.address_form.consignee_phone = address.phone
                this.address_form.consignee_email = address.email
                updateOrderAddress(this.address_form).then(response => {
                    if (response.code === 0) {
                        this.$message({message: 'success', type: 'error'});
                    } else {
                        this.$message({message: 'failed', type: 'error'});
                    }
                });
            },
        },
    };
</script>


<style></style>
