<template>

    <div class="section section-about-us" style="margin-top: 100px;min-height: 800px;">
        <div class="container">

            <h2 style="text-align: center;">Checkout Shipping</h2>

            <el-alert
                    style="margin-top: 30px;"
                    title="Notice: We will ship your parcel after you pay shipping cost."
                    type="success">
            </el-alert>

            <div style="display:flex;justify-content: space-between;margin-top: 30px;">
                <el-col :span="12">
                    <el-card class="box-card">
                        <div class="total_fee">
                            <h4>Total: $ {{total_amount}}</h4>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin-top: 30px;">
                        <div class="total_fee">
                            Delivery Number: {{delivery_number}}
                        </div>
                    </el-card>


                </el-col>
                <el-col :span="12">
                    <el-card class="box-card">
                        <div style="display: flex; flex-direction: column;">
                            <el-radio v-model="pay_way" label="paypal" border>Paypal</el-radio>
                            <el-radio v-model="pay_way" label="balance" border>Balance({{balance_amount}})</el-radio>
                            <el-radio v-model="pay_way" label="creditcard" border disabled>Credit Card</el-radio>
                        </div>

                        <el-divider></el-divider>


                        <el-button v-if="pay_can_click" type="success" @click="payOrder()">Pay Now</el-button>


                    </el-card>
                </el-col>
            </div>

            <h2 style="margin-top: 50px;">Shipping Notice:</h2>
            <el-collapse v-model="activeNames" >
                <el-collapse-item title="Tracking info" name="1">
                    <div>We usually upload the tracking number within 1-2 days after payment, please be patient.</div>
                </el-collapse-item>

            </el-collapse>

        </div>

    </div>
</template>
<script>

    import {Button, FormGroupInput, Card} from '@/components';
    import { payOrder, getPayments, payShippingOrder, getShippingPayments, payShippingWithBalance } from '@/api/shopping'
    import { getBalanceRecordList } from "@/api/user"


    export default {

        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                pay_way: 'paypal',
                delivery_number: '',
                total_amount: 0.00,
                activeNames: ['1'],
                balance_amount: 0,
                page: 1,
                page_size: 10,
                pay_can_click: true,
            };
        },
        created() {
            let delivery_number = this.$route.query.delivery_id || ''
            if (delivery_number == null || delivery_number == '') {
                this.$message({message: 'Can not run', type: 'error'});
                this.$router.push('/')
            }
            this.delivery_number = delivery_number
            this.getShippingPayments(delivery_number)
            this.getRecordList()
        },
        methods: {
            getShippingPayments(delivery_number) {
                //获取支付总金额
                this.$message({message: 'Loading now', type: 'warning'});
                getShippingPayments({delivery_number: delivery_number}).then(response => {
                    if (response.code === 0 ) {
                      this.total_amount = response.data.total_amount || 0.00
                    } else {
                        //无法支付
                        this.$message({message: 'Can not pay', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Can not pay', type: 'error'});
                })
            },
            payOrder() {
                //判断支付类型
                if (this.pay_way == 'paypal') {
                    //获取支付链接
                    this.$message({message: 'Please wait', type: 'warning'});
                    payShippingOrder({delivery_number: this.delivery_number}).then(response => {
                        if (response.code === 0 && response.data != '') {
                            window.location = response.data || ''
                        } else {
                            this.$message({message: response.msg || 'Can not pay', type: 'error'});
                        }
                    }).catch(error => {
                        this.$message({message: 'Can not pay', type: 'error'});
                    })
                } else if(this.pay_way == 'payoneer') {
                    this.$message({message: 'Please transfer to our Payoneer account.', type: 'error'});
                } else if (this.pay_way == 'balance') {
                    if (this.balance_amount <= 0) {
                        this.$message({message: 'Your balance is below 0', type: 'error'});
                        return
                    }


                    if (parseFloat(this.balance_amount) < parseFloat(this.total_amount)) {
                        this.$message({message: 'Please top up...', type: 'error'});
                        return;
                    }

                    this.$message({message: 'Create payment now, please wait...', type: 'warning', duration: 5000});
                    this.pay_can_click = false
                    payShippingWithBalance({ delivery_number: this.delivery_number }).then(response => {
                        if (response.code === 0) {
                            this.$router.push('/shipping/pay_success')
                        } else {
                            this.$message({message: response.msg || 'Pay failed', type: 'error'});
                        }

                        this.pay_can_click = true
                    })
                }
                else {
                    this.$message({message: 'Please select pay way', type: 'error'});
                }
            },
            getRecordList() {
                let params = {
                    page: this.page,
                    page_size: this.page_size,
                }
                getBalanceRecordList(params).then(response => {
                    if(response.code === 0) {
                        this.balance_amount = response.data.balance_amount || 0
                    }
                })
            },
        }


    };
</script>
<style></style>
