<template>
  <div class="section section-nucleo-icons" style="margin-top: 50px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12" style="display: flex;align-items: center;max-width: 500px;">
            <img :src="'/img/services/whytokcommerce.png'" alt="China Freight Forwarder" class="rounded" />
        </div>
        <div class="col-lg-6 col-md-12">
          <h2 class="title">{{$t('page_home.why_tokcommerce')}}</h2>
          <div class="description" style="color: black;">
            <h5>
              {{$t('page_home.why_tokcommerce_line1')}}
            </h5>
            <h5>
              {{$t('page_home.why_tokcommerce_line2')}}
            </h5>
            <h5>
              {{$t('page_home.why_tokcommerce_line3')}}
            </h5>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
