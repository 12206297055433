import request_api from '@/utils/request_api'

//购物车分页获取
export function getFaqList(data) {
  return request_api({
      url: '/api/faq/get_faq_list',
      method: 'post',
      data
  })
}

//购物车分页获取
export function getFaqCategoryList(data) {
  return request_api({
      url: '/api/faq/get_faq_category_list',
      method: 'post',
      data
  })
}

//购物车分页获取
export function getFaq(data) {
    return request_api({
        url: '/api/faq/get_faq',
        method: 'post',
        data
    })
}
