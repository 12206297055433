<template>
    <div style="min-height: 1200px;">

        <div class="page-header page-header-small">
            <parallax
                    class="page-header-image"
                    style="background-image:url('/img/services/expert_buy_agent.png')"
            >
            </parallax>
            <div class="content-center" style="margin-top: 0px;">
                <div class="container">
                    <h1 class="title title-for-mobile">{{$t('expert_buy')}}</h1>
                    <h5>{{$t('page_expert.des')}}</h5>

                    <p></p>
                </div>
            </div>
        </div>


        <div class="section-about-us" style="margin-top: 100px;">
            <div class="container">
                <div id="sourcing">
                    <div class="request-ship-order" style="margin-top: 100px; margin-bottom: 30px;">
                        <el-form :inline="false" :model="cart_form" ref="cart_form" class="demo-form-inline" label-position="top">
                            <el-row class="order-form-row">
                                <el-col :span="11">
                                    <el-form-item :label="$t('page_forwarding.product_name')" required prop="product_name">
                                        <el-input v-model="cart_form.product_name" :placeholder="$t('page_expert.past_product_name')"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item :label="$t('page_expert.product_url')" required prop="product_link" >
                                        <el-input v-model="cart_form.product_link" :placeholder="$t('page_home.search_product_text')"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row class="order-form-row">
                                <el-col :span="11">
                                    <el-form-item :label="$t('page_expert.unit_price') + '(CNY)'" required prop="product_sale_price_cny">
                                        <el-input v-model="cart_form.product_sale_price_cny" :placeholder="$t('page_expert.past_unit_price')" type="number" min="1"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item :label="$t('page_expert.quality')" required prop="quantity">
                                        <el-input-number v-model="cart_form.quantity" :min="1" :max="1000" style="width: auto"></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row class="order-form-row">
                                <el-col :span="11">
                                    <el-form-item :label=" $t('page_expert.delivery_fee') + '(CNY)'" required prop="product_freight_amount_cny">
                                        <el-input-number v-model="cart_form.product_freight_amount_cny" :placeholder=" $t('page_expert.delivery_fee_note') "></el-input-number>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="11">
                                    <el-form-item :label="$t('page_expert.product_remark')" required prop="product_remark">
                                        <el-input v-model="cart_form.product_remark" :placeholder="$t('page_expert.product_remark_note')"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row class="order-form-row">
                                <el-col :span="17">
                                    <el-form-item :label="$t('page_forwarding.product_limit')" required prop="product_image_url">
                                        <el-upload
                                                class="avatar-uploader"
                                                action="#"
                                                :show-file-list="false"
                                                :http-request="uploadFile"
                                                accept=".png,.jpg,.jpeg,">
                                            <img v-if="cart_form.product_image_url" :src="cart_form.product_image_url" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>


                                        <el-popover
                                                placement="top-start"
                                                trigger="hover"
                                                class="pop-over-icon"
                                        >
                                            <div class="pop-over-title">
                                                <strong>Please upload a screenshot of the specifications of the product you wish to purchase.(Limit 1M)</strong>
                                            </div>
                                            <i class="el-icon-info info-icon" slot="reference"></i>
                                        </el-popover>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5">


                                </el-col>
                            </el-row>



                            <el-row style="text-align: center;margin-top: 30px;">
                                <el-form-item>
                                    <el-button type="success" round @click="addToCart('cart_form')">{{$t('page_expert.add_to_cart')}}</el-button>
                                </el-form-item>
                            </el-row>
                        </el-form>
                    </div>

                    <el-card class="box-card" style="max-width: 90%;margin: auto;">
                        <!--                        <el-alert-->
                        <!--                                title="Can't find your favorite product? Submit your shopping order here."-->
                        <!--                                type="error">-->
                        <!--                        </el-alert>-->


                        <h2 style="margin-top: 30px;">{{$t('page_forwarding.how_to_use')}}</h2>
                        <div class="text item">
                            {{$t('page_expert.step_line1')}}
                        </div>
                        <div class="text item">
                            {{$t('page_expert.step_line2')}}
                        </div>
                        <div class="text item">
                            {{$t('page_expert.step_line3')}}
                        </div>
                        <div class="text item">
                            {{$t('page_expert.step_line4')}}
                            <a href="/manage/main?path=orderList-MyWarehouse" target="_blank">
                                In Warehouse page
                            </a>
                        </div>
                        <div class="text item">
                            {{$t('page_expert.step_line5')}}
                            <a href="/manage/main?path=orderList-MyWarehouse" target="_blank">
                                Submit To Ship
                            </a>
                        </div>
                        <div class="text item">
                            {{$t('page_forwarding.service_line6')}}
                        </div>
                    </el-card>

                    <el-row style="display:flex;justify-content: space-between;margin: 50px 10%;">
                        <el-col style="text-align: center">
                            <div class="source-info-container__info-title">
                                <h1>{{$t('page_expert.about_expert_buy')}}</h1>
                            </div>
                            <div class="source-info-container__info-description">
                              <p>
                                  {{$t('page_expert.about_des')}}
                              </p>
                            </div>
                        </el-col>
                    </el-row>

                </div>

                <div class="section section-contact-us text-center">
                    <div class="container">
                        <h2 class="title">{{$t('page_shopping.process_data')}}</h2>
                        <p>{{$t('page_shopping.process_des')}}</p>
                        <div class="row">
                            <div class="col-lg-10 text-center ml-auto mr-auto col-md-10">

                                <img v-lazy="'/img/services/process.png'" style="display: block;margin: 25px auto;width: 100%;"/>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    import { getToken } from '@/utils/auth';
    import { getPicUrl } from '@/api/service'
    import { addToCart } from "@/api/shopping";
    import { getMyCartNums } from '@/api/shopping'

    export default {
        name: 'SourceServiceRequest',
        metaInfo () {
          return {
              title: this.$t('meta.expert_buy_title'),
              meta: [
                  { hid: 'description', name: 'description', content: this.$t('meta.expert_buy_des') },
                  { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                  { hid: 'og:type', name: 'og:type', content: 'website' },
                  { hid: 'og:title', name: 'og:title', content: this.$t('meta.expert_buy_title') },
                  { hid: 'og:description', name: 'og:description', content: this.$t('meta.expert_buy_des') },
                  { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                  { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/service/source' },
              ],
          }
        },
        // metaInfo: {
        //     title: 'Expert Buy - TokCommerce',
        //     meta: [
        //         { hid: 'description', name: 'description', content: 'Buy products from Taobao Agent, Import from China. Lowest shipping prices. No service fee.' },
        //         { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
        //         { hid: 'og:type', name: 'og:type', content: 'website' },
        //         { hid: 'og:title', name: 'og:title', content: 'Expert Buy' },
        //         { hid: 'og:description', name: 'og:description', content: 'The best shopping agent in China' },
        //         { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
        //         { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/service/source' },
        //     ],
        // },
        components: {

        },
        data() {
            return {
                page: 1,
                page_size: 20,
                addresses: [],
                total: 0,
                savedAddressesVisible: false,
                num_requests: 1,
                country_list: [],
                isNewAddress: false,
                sourceYouTubeVideo: '',
                website_icons: [
                    {
                        src:"/icons/Others/tmall-icon.png",
                        alt:"Alibaba",
                        link: "https://www.1688.com/",
                    },
                    {
                        src:"/icons/Others/1688.png",
                        alt:"1688",
                        link: "https://www.1688.com/",
                    },
                    {
                        src:"/icons/Others/Taobao.png",
                        alt:"Taobao",
                        link: "https://www.taobao.com/",
                    },
                    {
                        src:"/icons/Others/Pinduoduo.png",
                        alt:"Pinduoduo",
                        link: "https://pinduoduo.com/",
                    },
                    {
                        src:"/icons/Others/JD.png",
                        alt:"JD",
                        link: "https://www.jd.com/",
                    },
                ],
                has_add_to_cart: false,
                cart_form: {
                    quantity: 1,
                    product_name: '',
                    product_link: '',
                    product_sale_price_cny: '',
                    product_freight_amount_cny: '',
                    sku_code_note: '',
                    product_remark: '',
                    product_image_url: '',
                    product_sku_id: '',
                }
            }
        },
        created() {
            this.loadVideoURL()
        },
        methods: {
            loadVideoURL () {
                const youtubeURL = 'https://www.youtube.com/watch?v=WLx4UeugSdE&ab_channel=TokCommerceofficial'
                const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
                const url = youtubeURL.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
                const YId = url[2] !== undefined ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
                const embededYouTubeVideo = youtubeEmbedTemplate.concat(YId)
                this.sourceYouTubeVideo = embededYouTubeVideo
            },
            uploadFile(file) {
                this.$message({message:'Uploading Now, please wait!', type:'warning'})
                const formData = new FormData()
                formData.append('file', file.file)
                formData.append('file_name', file.file.name)
                formData.append('type', 'market')

                getPicUrl(formData).then(response => {
                    if (response.code === 0){
                        this.$message({message:'Upload Successfully', type:'success'})
                        let url = response.data || ''
                        this.cart_form.product_image_url = url
                    } else {
                        this.$message({message:'Failed.Please compress the image, limit 1M', type:'warning'})
                    }
                }).catch(error => {
                    this.$message({message:'Failed.Please compress the image, limit 1M', type:'warning'})
                })
            },
            addToCart(form_name) {
                this.$refs[form_name].validate((valid) => {
                    if (!valid) {
                        this.$message({message: 'Please check your input', type: 'error'});
                        return false
                    }


                    this.$message({message: 'Add now! Please wait...', type: 'success'});
                    addToCart(this.cart_form).then(response => {
                        if (response.code === 0) {
                            this.has_add_to_cart = true
                            this.$message({message: 'Add success', type: 'success'});

                            this.$refs[form_name].resetFields();
                            this.getCartNums()
                        } else {
                            this.$message({message: response.msg || 'Add failed', type: 'error'});
                        }
                    }).catch(error => {
                        this.$message({message: 'Add failed', type: 'error'});
                    })
                })
            },
            getCartNums() {
                const token = getToken() || ''
                if (token) {
                    getMyCartNums().then(response => {
                        if (response.code === 0) {
                            this.$root.cart_num = response.data || 0
                        }
                    })
                }
            },
        }
    };
</script>


<style>
    .request-ship-order {
        padding-top: 20px;
        padding-bottom: 80px;
        max-width: 90%;
        margin: auto;
        border: 1px solid #DBDBDB;
    }
    .order-form-row {
        display: flex;
        justify-content: space-between;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
