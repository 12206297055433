<template>
  <div>


  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    style="background-color: #2c2c2c;color: #ffffff;"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="/about-us">{{$t('about')}}</a>
          </li>
          <li>
            <a href="/guides/guide-main">{{$t('user_guides')}}</a>
          </li>
          <li>
            <a href="/documents/terms-of-service">{{$t('terms_of_service')}}</a>
          </li>
          <li>
            <a href="/documents/terms-of-shipping">{{$t('terms_of_shipping')}}</a>
          </li>
          <li>
            <a href="/documents/privacy-policy">{{$t('privacy_policy')}}</a>
          </li>
          <li>
            <a href="/faq/faqmain">{{$t('faq')}}</a>
          </li>
          <li>
            <a href="/service/compensation-taxation">{{$t('compensation_taxation')}}</a>
          </li>
        </ul>
      </nav>
      <div class="copyright">

      </div>
    </div>
  </footer>

    <div v-if="checkIsMobile()" style="height: 65px;"></div>

    <div v-if="checkIsMobile()" style="position: fixed;bottom: 0px; width: 100%;background-color: white; height: 65px;">
      <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" style="display:flex;justify-content: space-between;border-top: 1px solid #f96332;background-color: white;">
        <el-menu-item index="1" style=""><router-link to="/">{{$t('home')}}</router-link></el-menu-item>
        <el-menu-item index="2" style=""><router-link to="/service/menu">{{$t('services')}}</router-link></el-menu-item>
        <el-menu-item index="5" style=""><router-link to="/message/list">{{$t('notice')}}</router-link></el-menu-item>
        <el-menu-item index="3" style=""><router-link to="/shopping/my-cart">{{$t('cart')}}</router-link></el-menu-item>
        <el-menu-item index="4" style=""><router-link to="/manage/main-mobile">{{$t('me')}}</router-link></el-menu-item>
      </el-menu>
      <div class="line"></div>
    </div>

  </div>

</template>
<script>
  import { checkIsMobile } from '@/utils/screen'

export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods: {
    checkIsMobile() {
      return checkIsMobile()
    },
    handleSelect() {

    },
  }
};
</script>
<style>

</style>
