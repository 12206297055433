<template>
  <div class="guides-page">
    <div>
      <h1 class="centralise-text">
        TokCommerce Blog
      </h1>
      <h5 class="centralise-text">

      </h5>
    </div>

    <div>
      <div class="guides-card-section" style="min-height: 800px;">
        <div class="guides-card-row">
          <GuidesCard
              v-for="item in list"
              :key="item.id"
              :link="'/blog/detail?id=' + item.id"
              :title="item.title"
              :description="item.description"
              />
        </div>

        <el-pagination
                style="margin-top: 30px;"
                layout="prev, pager, next"
                :total="total"
                :current-page="page"
                @current-change="changePage"
        >
        </el-pagination>

      </div>
    </div>
  </div>
</template>

<script>
import GuidesCard from "./components/GuidesCard";

import { getArticleList } from "@/api/articles"

export default {
  name: "GuideMain",
  components: {GuidesCard},
  metaInfo: {
    title: 'Blog - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: sharing more about shopping agent and forwarding agent.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Blog' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: sharing more about shopping agent and forwarding agent..' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/blog/list' },
    ],
  },
  data() {
    return {
      list: [],
      page: 1,
      page_size: 9,
      total: 0,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      let params = {
        page: this.page,
        page_size: this.page_size,
      }

      this.$message({message: 'Loading now, please wait.', type: 'warning'});
      getArticleList(params).then(response => {
        if (response.code === 0) {
          this.list = response.data.list || []
          this.total = response.data.total || 0
        }
        else {
          this.$message({message: 'No more data', type: 'warning'});
        }
      }).catch(error => {
        this.$message({message: 'Empty data', type: 'warning'});
      })
    },
    changePage(page) {
      this.page = page
      this.getList()
    },
  },
}
</script>

<style scoped>
.centralise-text{
  text-align: center;
}
.guides-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.guides-card-section{
  margin-top: 40px;
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 15%;
  }
  .guides-card-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
  }
}
</style>
