<template>
  <div
    class="section section-download"
    id="#download-section"
    data-background-color="black"
  >
    <div class="container">
<!--      <div class="row justify-content-md-center">-->
<!--        <div class="text-center col-md-12 col-lg-8">-->
<!--          <h3 class="title">Do you love this Bootstrap 4 UI Kit?</h3>-->
<!--          <h5 class="description">-->
<!--            Cause if you do, it can be yours for FREE. Hit the button below to-->
<!--            navigate to Creative Tim or Invision where you can find the kit in-->
<!--            HTML or Sketch/PSD format. Start a new project or give an old-->
<!--            Bootstrap project a new look!-->
<!--          </h5>-->
<!--        </div>-->
<!--        <div class="text-center col-md-12 col-lg-8">-->
<!--          <a-->
<!--            href="https://www.creative-tim.com/product/vue-now-ui-kit"-->
<!--            class="btn btn-primary btn-lg btn-round"-->
<!--            role="button"-->
<!--          >-->
<!--            Download HTML-->
<!--          </a>-->
<!--          <a-->
<!--            href="https://www.invisionapp.com/now"-->
<!--            target="_blank"-->
<!--            class="btn btn-primary btn-lg btn-simple btn-round"-->
<!--            role="button"-->
<!--          >-->
<!--            Download PSD/Sketch-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->

      <div class="row text-center mt-5">
        <div class="col-md-8 ml-auto mr-auto">
          <h2>{{$t('page_home.customer_support')}}</h2>
          <h5 class="description">
<!--              If you need customer service assistance, you can join our community for help, or email us directly.-->
<!--            <br><br>-->

<!--            <p><a target="_blank" href="https://discord.gg/zGdJaUjt99">-Join Our Community: Discord-</a></p>-->
            <p>{{$t('page_home.service_email')}}: service@tokcommerce.com</p>
<!--            <p>{{$t('page_home.b2b_email')}}: eric@tokcommerce.com</p>-->
            <p>(08:00 ~ 20:00)</p>

              <br><br>
          </h5>
        </div>
<!--        <div class="col-md-12">-->
<!--          <a-->
<!--            href="http://demos.creative-tim.com/vue-now-ui-kit-pro"-->
<!--            class="btn btn-neutral btn-round btn-lg"-->
<!--            target="_blank"-->
<!--          >-->
<!--            <i class="now-ui-icons arrows-1_share-66"></i> Get Help-->
<!--          </a>-->
<!--        </div>-->
      </div>
<!--      <br />-->
<!--      <br />-->
      <div class="row justify-content-md-center text-center">
<!--        <div class="text-center col-md-12 col-lg-8">-->
<!--          <h3>Communities</h3>-->
<!--        </div>-->
        <div class="text-center col-md-12 col-lg-8">
            <a
                    target="_blank"
                    href="https://forum.tokcommerce.com/"
                    class="btn btn-neutral btn-icon btn-round btn-lg"
                    style="margin: 0 10px"
            >
                <img src="/img/icons/community.svg" style="margin-top: 3px" alt="TokCommerce Forum">
            </a>
<!--          <a-->
<!--            target="_blank"-->
<!--            href="https://discord.gg/zGdJaUjt99"-->
<!--            class="btn btn-neutral btn-icon btn-round btn-lg"-->
<!--            style="margin: 0 10px"-->
<!--          >-->
<!--            <img src="/img/icons/discord.svg" style="margin-top: 3px">-->
<!--          </a>-->
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCLxG4Sy4iQ7O9STvSETTr6Q/featured"
            class="btn btn-neutral btn-icon btn-facebook btn-round btn-lg"
            style="margin: 0 10px"
          >
            <img src="/img/icons/youtube.svg" style="margin-top: 10px">
          </a>
          <a
            target="_blank"
            href="https://www.reddit.com/r/TokCommerce"
            class="btn btn-neutral btn-icon btn-linkedin btn-lg btn-round"
            style="margin: 0 10px"
          >
            <img src="/img/icons/reddit.svg" style="display: block;margin: auto">
          </a>
            <a
                    target="_blank"
                    href="https://www.instagram.com/tokcommerceofficial/"
                    class="btn btn-neutral btn-icon btn-linkedin btn-lg btn-round"
                    style="margin: 0 10px"
            >
                <img src="/img/icons/instagram.svg" style="display: block;margin: auto">
            </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      socials: [
        {
          id: 5,
          title: 'tiktok',
          url: 'https://www.tiktok.com/@tokcommerce',
        },
        {
          id: 1,
          title: 'facebook',
          url: 'https://www.facebook.com/tokcommerce',
        },
        {
          id: 2,
          title: 'youtube',
          url: 'https://www.youtube.com/channel/UCygsJoXVnSfIbgcILwP-kJA',
        },
        {
          id: 3,
          title: 'reddit',
          url: 'https://www.reddit.com/r/TokCommerce',
        },
        {
          id: 4,
          title: 'discord',
          url: 'https://discord.gg/zGdJaUjt99',
        },
      ],
    }
  }
};
</script>
<style></style>
