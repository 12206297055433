<template>
  <el-card class="class-size">
    <a :href="link">
      <p>
        {{ this.title }}
      </p>
    </a>
    <p>{{this.description}}</p>
  </el-card>
</template>

<script>
export default {
  name: "GuidesCard",
  props: {
    title: String,
    link: String,
    description: String
  }
}
</script>

<style scoped>
.class-size{
  margin-bottom: 20px;
  text-align: left;
}
@media screen and (min-width: 1200px){
  .class-size{
    width: 320px;
    /*height: 220px;*/
    margin: 10px;
  }
}

.description {
  color: black;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
</style>
