<template>
    <div style="margin-top: 75px;">
        <el-card class="box-card">
            <div slot="header" style="display:flex;justify-content: space-between;">
                <div>
                    <strong>{{$t('page_profile.balance')}}:</strong> <br>
                    <strong>{{balance_amount}} USD</strong> <br>
                </div>

                <div>
<!--                    <el-button round size="small" type="success" @click="topup_visible = true; getChargeDiscountList()">{{$t('page_wallet.top_up')}}</el-button> |-->
<!--                    <el-tooltip class="item" effect="light" placement="top">-->
<!--                        <div slot="content">-->
<!--                            You can recharge some USD to your <br>-->
<!--                            TokCommerce account wallet. <br>-->
<!--                            Directly using the balance to pay can save you time. <br>-->
<!--                        </div>-->
<!--                        <i class="el-icon-question"></i>-->
<!--                    </el-tooltip>-->
                </div>
            </div>


            <div class="text item">
                {{$t('page_wallet.underpaid')}}

                : 0.00 USD
            </div>
        </el-card>

        <h3 style=" margin-top: 30px;">{{$t('page_wallet.balance_details')}}</h3>

        <div class="container">
            <el-table
                    :empty-text="$t('page_wallet.empty')"
                    :data="record_list"
                    style="width: 100%;margin-top: 30px;">
                <el-table-column
                        prop="date"
                        :label="$t('page_wallet.time')"
                        :width="table_column_width">
                </el-table-column>
                <el-table-column
                        prop="amount"
                        :label="$t('page_wallet.amount') + '(USD)'"
                        :width="table_column_width">
                </el-table-column>
                <el-table-column
                        prop="current_balance"
                        :label="$t('page_profile.balance')"
                        :width="table_column_width">
                </el-table-column>
                <el-table-column
                        :label="$t('page_wallet.status')"
                        width="150">
                    <template slot-scope="scope">
                        {{scope.row.order_status_text}}
                    </template>
                </el-table-column>
                <el-table-column
                        :label="$t('page_wallet.info')"
                        width="180px"
                >
                    <template slot-scope="scope">
                        <p>Category: {{scope.row.category}}</p>
                        <p>Type: {{scope.row.amount_type_text}}</p>
                        <p>Remark: {{scope.row.remark}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                        :label="$t('page_cart.operate')"
                        width="80">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.order_status == 0" type="success" @click="goToPayment(scope.row.order_id)">{{$t('page_wallet.pay')}}</el-button>
                        <p v-else>{{$t('page_wallet.paid')}}</p>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    style="margin-top: 30px;"
                    layout="prev, pager, next"
                    :total="record_total"
                    :current-page="page"
                    @current-change="changePage">
            </el-pagination>
        </div>



        <el-dialog
                :title="$t('page_wallet.top_up')"
                :visible.sync="topup_visible"
                width="80%">
            <el-form :model="add_form" ref="add_form" class="demo-form-inline" label-position="top" label-width="200">

                <el-form-item prop="amount" required :label="$t('page_wallet.amount') + '/USD'">
                    <el-input-number v-model="add_form.amount"  :min="10" :max="10000" label="Amount" :step="10"></el-input-number>
                </el-form-item>

                <el-button size="large" type="success" @click="addBalance('add_form')">{{$t('page_wallet.confirm')}}</el-button>

            </el-form>

            <el-card class="box-card" style="margin-top: 30px;">
                <div slot="header" class="clearfix">
                    <span>{{$t('page_wallet.discount_info')}}</span>
                </div>
                <div class="text item" v-for="(item, key) in discount_list" :key="key">
                    <el-radio v-model="add_form.amount" :label="item.max" border>{{$t('page_wallet.top_up')}} {{item.max}}USD {{$t('page_wallet.and_get')}} {{item.discount_value}}USD {{$t('page_wallet.get_extra')}}</el-radio>
                </div>
            </el-card>
        </el-dialog>

    </div>
</template>
<script>

    import { getChargeDiscountList, userAddBalance, getBalanceRecordList } from "@/api/user"


    export default {
        name: 'Wallet',
        components: {

        },
        props: {

        },
        data() {
            return {
                balance_amount: 0.00,
                wallet_record_list: [],
                topup_visible: false,
                add_form: {
                    amount: '',
                },
                discount_list: [],
                page:1,
                page_size: 10,
                record_list: [],
                record_total: 0,
                table_column_width: '',
            };
        },
        created() {
            this.getRecordList()
        },
        methods: {
            addBalance(form_name) {
                this.$refs[form_name].validate((valid) => {
                    if (!valid) {
                        this.$message({message: 'Please check your input', type: 'error'});
                        return false
                    }
                    this.$message({message: 'Create payment now...', type: 'warning'});
                    userAddBalance(this.add_form).then(response => {
                        if (response.code === 0 && response.data) {
                            this.$router.push('/manage/main-mobile/wallet-payment?order_id=' + response.data)
                        } else {
                            this.$message({message: 'Create payment failed', type: 'error'});
                        }
                    }).catch(error => {
                        this.$message({message: 'Create payment failed', type: 'error'});
                    })

                })
            },
            getChargeDiscountList() {
                getChargeDiscountList().then(response => {
                    if (response.code === 0) {
                        this.discount_list = response.data || []
                    }
                })
            },
            changePage(page) {
                this.page = page
                this.getRecordList()
            },
            getRecordList() {
                let params = {
                    page: this.page,
                    page_size: this.page_size,
                }
                getBalanceRecordList(params).then(response => {
                    if(response.code === 0) {
                        this.record_list = response.data.list || []
                        this.record_total = response.data.total || 0
                        this.balance_amount = response.data.balance_amount || 0
                    }
                })
            },
            goToPayment(order_id) {
                this.$router.push('/manage/main-mobile/wallet-payment?order_id=' + order_id)
            },
        }
    };
</script>

<style>
    .text {
        font-size: 14px;
    }

    .item {
        margin-bottom: 18px;
    }



    .box-card {
        width: auto;
    }
</style>
