<template>
  <div class="guides-page">
    <div>
      <div>
        <h1>
          {{data.title}}
        </h1>
        <p>
          {{data.description}}
        </p>
      </div>
      <el-divider></el-divider>
      <div>
        <vue-markdown :source="data.content"></vue-markdown>
      </div>
      <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
    </div>
  </div>
</template>

<script>
  import BackToTopAndHomeButtons from "./components/BackToTopAndHomeButtons";
  import VueMarkdown from "vue-markdown";
  import { getArticleDetail } from "@/api/articles"

  export default {
    name: "GuideTaobaoTokCommerce",
    components: {
      BackToTopAndHomeButtons,
      VueMarkdown,
    },
    metaInfo() {
      return {
        title: this.data.title,
        meta: [
          {hid: 'og:title', name: 'og:title', content: this.data.title},
          {
            hid: 'description',
            name: 'description',

          },
          {
            hid: 'og:description',
            name: 'og:description',
          },
        ]}
    },
    data() {
      return {
        data: {},
      }
    },
    created() {
      let id = this.$route.query.id || ''
      if (!id) {
        this.$router.push('/')
      }

      this.getDetail(id)
    },
    methods: {
      getDetail(id) {
        this.$message({message: 'Loading now, please wait.', type: 'warning'});
        getArticleDetail({id: id}).then(response => {
          if (response.code === 0) {
            this.data = response.data || {}
          }
        }).catch(error => {
          this.$message({message: 'Can not show data', type: 'warning'});
        })
      },
    },

  }
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}

.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
