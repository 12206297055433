<template>
    <div style="margin-top: 10px;">
        <el-col :span="checkIsMobile() ? 12 : 6">
                <el-card :body-style="{ padding: '0px' }" style="height: 400px;">

                    <a :href="'/product/detail?search_text=' + product.product_link" target="_blank">
                        <img :src="product.product_image_url" class="image" style="height: 270px; width: auto;margin: auto;">
                    </a>
                    <div style="padding: 14px;">
                        <a :href="'/product/detail?search_text=' + product.product_link" target="_blank" style="color: black;"><strong>{{product.product_name}}</strong></a>
                        <div class="bottom clearfix">
                            <strong>￥ {{product.product_price}}</strong>
                        </div>
                    </div>
                </el-card>
        </el-col>
    </div>
</template>
<script>

    import { checkIsMobile } from '@/utils/screen'

    export default {
        name: 'ProductCard',
        components: {},
        props: {
            product: Object,
        },
        data() {
            return {

            };
        },
        methods: {
            checkIsMobile() {
                return checkIsMobile()
            }
        }
    };


</script>

<style type="text/css">
    .time {
        font-size: 13px;
        color: #999;
    }
    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }
    .button {
        padding: 0;
        float: right;
    }
    .image {
        width: 100%;
        display: block;
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
</style>
