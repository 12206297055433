import request_api from '@/utils/request_api'

export function getArticleList(data) {
  return request_api({
    url: '/api/articles/get_article_list',
    method: 'post',
    data
  })
}

export function getArticleDetail(data) {
  return request_api({
    url: '/api/articles/get_article_detail',
    method: 'post',
    data
  })
}
