<template>

    <div class="section section-about-us" style="margin-top: 100px;min-height: 800px;">
        <div class="container">

            <h2 style="text-align: center;">Recharge Payment</h2>

            <el-alert
                    style="margin-top: 30px;"
                    title="Notice: The recharged amount can only be used for orders on this platform."
                    type="success">
            </el-alert>

            <div style="display:flex;justify-content: space-between;margin-top: 30px;">
                <el-col :span="11">
                    <el-card class="box-card">
                        <div class="total_fee">
                            <h4>Total: $ {{total_amount}}</h4>
                        </div>
                    </el-card>

                    <el-card class="box-card" style="margin-top: 30px;">
                        <div class="total_fee" >
                            Order No: {{order_id}}
                        </div>
                    </el-card>


                </el-col>
                <el-col :span="11">
                    <el-card class="box-card">
                        <el-radio v-model="pay_way" label="paypal" border>Paypal</el-radio>
                        <!--                        <el-radio v-model="pay_way" label="payoneer" border>Payoneer</el-radio>-->
                        <el-radio v-model="pay_way" label="creditcard" border disabled>Credit Card</el-radio>

                        <el-divider></el-divider>


                        <el-button type="success" @click="payOrder()">Pay Now</el-button>


                    </el-card>
                </el-col>
            </div>

        </div>

    </div>
</template>
<script>

    import {Button, FormGroupInput, Card} from '@/components';
    import { getBalancePayment, payBalanceOrder } from '@/api/user'


    export default {

        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                pay_way: 'paypal',
                order_id: '',
                total_amount: 0.00,
                activeNames: ['1']
            };
        },
        created() {
            let order_id = this.$route.query.order_id || ''
            if (order_id == null || order_id == '') {
                this.$message({message: 'Can not run', type: 'error'});
                this.$router.push('/')
            }
            this.order_id = order_id
            this.getPayments(this.order_id)
        },
        methods: {
            getPayments(order_id) {
                //获取支付总金额
                this.$message({message: 'Loading now', type: 'warning'});
                getBalancePayment({order_id: order_id}).then(response => {
                    if (response.code === 0 ) {
                        this.total_amount = response.data.total_amount || 0.00
                    } else {
                        //无法支付
                        this.$message({message: 'Can not pay', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Can not pay', type: 'error'});
                })
            },
            payOrder() {
                //判断支付类型
                if (this.pay_way == 'paypal') {
                    //获取支付链接
                    payBalanceOrder({order_id: this.order_id}).then(response => {
                        if (response.code === 0 && response.data != '') {
                            window.location = response.data || ''
                        } else {
                            this.$message({message: response.msg || 'Can not pay', type: 'error'});
                        }
                    }).catch(error => {
                        this.$message({message: 'Can not pay', type: 'error'});
                    })
                } else if(this.pay_way == 'payoneer') {
                    this.$message({message: 'Please transfer to our Payoneer account.', type: 'error'});
                }
                else {
                    this.$message({message: 'Please select pay way', type: 'error'});
                }
            },
        }


    };
</script>
<style></style>
