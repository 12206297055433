<template>
  <div>
    <vue-markdown :source="content" class="guides-page"></vue-markdown>
  </div>
</template>

<script>
import {getMarkdowns} from "../../api/document";
import VueMarkdown from "vue-markdown";

export default {
  name: "TermsOfService",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      content: '',
    }
  },
  created() {
    this.getContent('1823150307')
    // 测试数据
    // this.getContent('4165075125')
  },
  methods: {
    getContent(path) {
      getMarkdowns({file_name: path}).then(response => {
        if (response.code === 0) {
          this.content = response.data || ''
        }
      })
    }
  }
}
</script>

<style scoped>
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>