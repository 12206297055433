<template>
    <div style="margin-top: 75px;">
<!--        <el-alert-->
<!--                title="You need to pay for international shipping cost in this page. Then we will ship your parcel."-->
<!--                type="warning">-->
<!--        </el-alert>-->

        <el-row style="display:flex;justify-content: space-between;margin-top: 10px;">
            <el-col :span="24" style="display: flex;align-items: center;">
                <el-form :inline="true" :model="search_form" ref="search_form" class="demo-form-inline" label-position="top" >
                    <el-form-item required prop="order_status">
                        <el-select placeholder="Please select" v-model="search_form.order_status" filterable>
                            <el-option
                                    v-for="item in order_status_list"
                                    :key="item.status"
                                    :value="item.status"
                                    :label="item.status_text">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="search_text">
                        <el-input :placeholder="$t('page_my_parcel.delivery_number')" v-model="search_form.search_text" class="input-with-select"></el-input>
                    </el-form-item>
                    <el-button @click="searchOrder()">{{$t('page_my_order.search')}}</el-button>
                </el-form>
            </el-col>
        </el-row>


        <br>
        <el-table
                empty-text="You have no parcel need to ship"
                :data="order_list"
                tooltip-effect="dark"
                style="width: 100%;margin-top: 30px;"
                v-loading="loading"
                >
            <el-table-column
                    width="180px;"
                    prop="delivery_number"
                    :label="$t('page_my_parcel.delivery_number')">
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.item')"
                    width="275px;">
                <template slot-scope="scope">
                    <div v-for="order in scope.row.details" :key="order.order_id">
                        <div style="display: flex;justify-content: space-between;">
                            <el-image
                                    style="width: 70px; height: 70px"
                                    :src="order.product_image_url"
                                    :preview-src-list="[order.product_image_url]">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                            <div>
                                {{$t('page_shopping_payment.order_no')}}: <br>{{order.order_id}}<br>
                            </div>
                        </div>

                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.info')"
                    width="200">
                <template slot-scope="scope">
                    <p>{{$t('my_warehouse.actual_weight')}}: {{scope.row.total_weight}}KG</p>
                    <p>{{$t('page_wallet.status')}}: <span style="color: #f96332;">{{scope.row.order_status_text}}</span></p>
                    <p>{{$t('page_my_order.remark')}}: {{scope.row.shipping_remark}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_shopping_payment.total')"
                    width="100">
                <template slot-scope="scope">
                    USD {{scope.row.total_amount}}<br>
                </template>
            </el-table-column>

            <el-table-column
                    :label="$t('page_cart.operate')"
                    style="padding-right: 2px;"
                    width="150"
            >
                <template slot-scope="scope">
                    <el-button v-if="scope.row.order_status == 1 && scope.row.total_amount > 0" size="small" plain type="success" @click="turnToPay(scope.row.delivery_number)">{{$t('page_my_parcel.pay_shipping')}}</el-button><br>
                    <el-button size="small" style="margin-left: 0px;margin-top: 3px;" plain @click="viewDetail(scope.row.delivery_number)">{{$t('page_my_order.details')}}</el-button><br>

                    <el-popover
                            placement="right"
                            width="400"
                            trigger="click">
                        <div>
                            <p>Consignee Name: {{scope.row.consignee_name}}</p>
                            <p>Country: {{scope.row.country_code}}</p>
                            <p>State/Province: {{scope.row.consignee_province}}</p>
                            <p>Consignee City: {{scope.row.consignee_city}}</p>
                            <p>Address Detail: {{scope.row.consignee_address_detail}}</p>
                            <p>Consignee Postcode: {{scope.row.consignee_postcode}}</p>
                            <p>Consignee Phone: {{scope.row.consignee_phone}}</p>
                        </div>
                        <el-button size="small" style="margin-left: 0px;margin-top: 3px;"  slot="reference" plain>{{$t('page_my_parcel.address')}}</el-button><br>
                    </el-popover>

                    <br>
                    <el-button v-if="scope.row.order_status == 3" size="small" style="margin-left: 0px;margin-top: 3px;" plain @click="viewDetail(scope.row.delivery_number)">{{$t('page_my_parcel.tracking')}}</el-button><br>

                </template>
            </el-table-column>
        </el-table>


        <el-pagination
                style="margin-top: 30px;"
                layout="prev, pager, next"
                :total="total_count"
                :current-page="current_page"
                @current-change="changePage"
        >
        </el-pagination>

    </div>
</template>
<script>

    import { getDeliveryOrders, payShippingOrder } from '@/api/shopping'

    export default {
        name: 'MyOrders',
        components: {

        },
        props: {

        },
        data() {
            return {
                order_list: [],
                search_text: '',
                multipleSelection: [],
                total_count: 0,
                current_page: 1,
                loading: true,
                search_form: {
                    search_text: '',
                    order_status: '',
                },
                order_status_list: [
                    {
                        status: '',
                        status_text: 'All',
                    },
                    {
                        status: 0,
                        status_text: 'Waiting to add fee',
                    },
                    {
                        status: 1,
                        status_text: 'Unpaid Shipping Fee',
                    },
                    {
                        status: 2,
                        status_text: 'Ready Shipped',
                    },
                    {
                        status: 3,
                        status_text: 'Shipped',
                    },
                ],
            };
        },
        created() {
            this.getOrderList()
        },
        methods: {
            getOrderList() {
                let params = this.search_form

                params.page = this.current_page
                params.page_size = 10

                getDeliveryOrders(params).then(response => {
                    if (response.code === 0) {
                        this.order_list = response.data.list || []
                        this.total_count = response.data.total_count || 0
                    } else {

                    }

                    this.loading = false
                }).catch(error => {
                    this.loading = false
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            searchOrder() {
                this.current_page = 1
                this.getOrderList()
            },
            turnToPay(delivery_number) {
                this.$router.push('/shopping/shipping-payment?delivery_id=' + delivery_number)

            },
            changePage(page) {
                this.current_page = page
                this.getOrderList();
            },
            viewDetail(delivery_number) {
                // this.$router.push('/shipping/delivery-detail-new?delivery_number=' + delivery_number)
                window.open('/shipping/delivery-detail-new?delivery_number=' + delivery_number)
            },
        }
    };
</script>
