<template>
    <div>
        <div class="section section-about-us" style="margin-top: 30px; padding: 0px;">
            <div class="container">
                <div v-if="product_details.product_name" class="spu-details-container">
                    <div style="margin-top: 100px;" class="main_product">

<!--                        <el-col :span="8">-->

                            <div>
                                <el-image
                                        class="carousel-img"
                                        :src="product_details.product_image_url"
                                        :preview-src-list="[product_details.product_image_url]">
                                </el-image>
                                <div style="display: flex;justify-content: space-between;">
                                    <el-col v-for="item in product_details.product_image_list" :key="item.url" :span="4">
                                        <el-image class="product_pic_list" :src="item.url" @click="product_details.product_image_url = item.url"></el-image>
                                    </el-col>
                                </div>
                            </div>

<!--                        </el-col>-->

<!--                        <el-col :span="16">-->
                            <div style="padding-left: 10px;">
                                <h1 class="name">{{product_details.product_name}}</h1>
                                <div style="display: flex;flex-direction: column;">
                                    <div class="item-row">
                                        <el-col :span="5" class="item-title">{{$t('page_expert.product_show_price')}}</el-col>
                                        <el-col :span="19" style="font-weight: bold;color: #f96332;"> <h3>￥ {{cart_form.product_sale_price_cny}}</h3></el-col>
                                    </div>
                                    <div class="item-row" v-for="(item, key) in product_details.prop_list" :key="key">
                                        <el-col :span="5" class="item-title">{{item.prop_name}} <span style="color: red"> *</span></el-col>
                                        <el-col :span="19">

                                            <el-radio-group v-model="sku_prop_list[item.prop_name]" @change="changeSkuItem">
                                                <el-radio-button v-for="(item_child, index) in item.prop_list" :key="index" :label="item_child.p_value">
                                                    {{item_child.p_name}}
                                                </el-radio-button>
                                            </el-radio-group>

                                        </el-col>
                                    </div>
                                    <div class="item-row">
                                        <el-col :span="5" class="item-title">{{$t('page_expert.product_show_quantity')}}</el-col>
                                        <el-col :span="19">
                                            <el-input-number v-model="cart_form.quantity" :min="min_num" :max="1000"></el-input-number>
                                        </el-col>
                                    </div>
                                    <div class="item-row">
                                        <el-col :span="5" class="item-title">{{$t('page_expert.product_show_shipping')}}</el-col>
                                        <el-col :span="19">￥ {{product_details.product_freight_amount_cny}}</el-col>
                                    </div>
                                </div>

                                <el-alert
                                        :title="$t('page_expert.product_shipping_note')"
                                        type="warning">
                                </el-alert>

                                <el-input
                                        style="margin-top: 30px;resize:none;"
                                        type="textarea"
                                        :rows="3"
                                        :placeholder="$t('page_expert.product_detail_remark')"
                                        v-model="cart_form.product_remark">
                                </el-input>

                                <!-- 内容区底部按钮 -->
                                <div class="footer-buttons">
                                    <el-button round size="small" @click="turnToCustomShopping()">{{$t('expert_buy')}}</el-button>
                                    <el-button type="danger" v-if="!has_add_to_cart" round size="small" @click="addShoppingCart" >{{$t('page_expert.add_to_cart')}}</el-button>
                                    <el-alert
                                            style="margin-top: 30px;"
                                            :title="$t('page_expert.expert_btn')"
                                            type="success">
                                    </el-alert>
                                </div>
                            </div>
<!--                        </el-col>-->

                    </div>


                    <div class="details_detail">
                        <el-tabs v-model="menuActiveName">
                            <el-tab-pane label="Item Detail" name="itemDetail">
                                <div v-html="product_details.product_details"/>
                            </el-tab-pane>
<!--                            <el-tab-pane label="Shipping Notice" name="shipping">-->

<!--                            </el-tab-pane>-->
                        </el-tabs>
                    </div>


                </div>


                <div v-else style="min-height: 1000px;padding-top: 100px;">
                    <el-empty description="Loading now, please wait...........">
                        <el-button type="success" v-if="product_details.product_name == '' && request_num == 1" @click="getProductDetail(search_text); request_num += 1 ">Can not find, search again</el-button>
                    </el-empty>
                </div>

            </div>
        </div>

    </div>
</template>


<script>

    import { Button, FormGroupInput, Card } from '@/components';
    import { getProductDetail, addToCart } from "@/api/shopping";
    import { getToken } from '@/utils/auth'
    import { getMyCartNums } from '@/api/shopping'


    export default {
        metaInfo: {
            title: 'Product Detail',
            meta: [
                { hid: 'description', name: 'description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Product Detail' },
                { hid: 'og:description', name: 'og:description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                product_details: {
                    product_image_url: '',
                    product_image_list: [],
                    product_name: '',
                    product_link: '',
                    product_details: '',
                    current_price_usd: '',
                    product_freight_amount_cny: '',
                    product_freight_amount_usd: '',
                    props_list_origin: {},
                    sku_list: [],
                    product_price: 0,
                },
                search_text: '',
                cart_form: {
                    quantity: 1,
                    sku_code_note: '',
                    product_remark: '',//商品备注
                    product_sale_price_cny: 0,
                    sku_id: '',
                },
                sku_prop_list: {},//这是sku属性的分类 提交购物车时优先校验是否都选了 并且是否有货
                sku_prop_list_sort: [],
                has_add_to_cart: false,
                menuActiveName: 'itemDetail',
                request_num: 0,
                min_num: 1,
                sku_code: '',
            };
        },
        created() {
            let search_text = this.$route.fullPath || ''

            let params = search_text.split('search_text=')

            this.search_text = params[1]

            if (search_text == '') {
                //不能为空

                this.$router.push('/')
            }

            this.getProductDetail(this.search_text)
        },
        methods: {
            addShoppingCart() {
                this.cart_form.sku_code_note = ''
                this.cart_form.sku_id = ''
                for (let sku_type in this.sku_prop_list_sort) {
                    if (this.sku_prop_list[this.sku_prop_list_sort[sku_type]] == '') {
                        this.$message({message: 'Please select ' + this.sku_prop_list_sort[sku_type], type: 'error'});
                        return
                    }
                    //拼接sku code 信息
                    this.cart_form.sku_code_note += this.product_details.props_list_origin[this.sku_prop_list[this.sku_prop_list_sort[sku_type]]] + ';'
                    this.cart_form.sku_id += this.sku_prop_list[this.sku_prop_list_sort[sku_type]] + ';'
                }
                this.cart_form.sku_id = this.cart_form.sku_id.substr(0, this.cart_form.sku_id.length - 1);

                //判断金额
                if (this.cart_form.product_sale_price_cny <= 0 || this.cart_form.product_sale_price_cny <= 0.00 || this.cart_form.product_sale_price_cny <= '0' || this.cart_form.product_sale_price_cny <= '0.00') {
                    this.$message({message: 'Cannot be added to cart. You need to submit product information manually.', type: 'error'});
                    return
                }

                if (parseInt(this.product_details.sku_list[this.sku_code].quantity) <= 0) {
                    this.$message({message: 'Stock is empty', type: 'error'});
                    return;
                }


                //可以添加到购物车
                let cart_params = {
                    quantity: this.cart_form.quantity,
                    product_name: this.product_details.product_name,
                    product_link: this.product_details.product_link,
                    product_sale_price_cny: this.cart_form.product_sale_price_cny,
                    product_freight_amount_cny: this.product_details.product_freight_amount_cny,
                    sku_code_note: this.cart_form.sku_code_note,
                    product_remark: this.cart_form.product_remark,
                    product_image_url: this.product_details.product_image_url,
                    product_sku_id: this.cart_form.sku_id,
                }

                this.$message({message: 'Add now! Please wait...', type: 'success'});
                addToCart(cart_params).then(response => {
                    if (response.code === 0) {
                        // this.has_add_to_cart = true
                        this.$message({message: 'Add success', type: 'success'});

                        this.getCartNums()
                    } else {
                        this.$message({message: response.msg || 'Add failed', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Add failed', type: 'error'});
                })


            },
            getProductDetail(search_text) {
                this.$message({message: 'Search now, please wait.', type: 'warning', duration: 3500});
                getProductDetail({search_text: search_text}).then(response => {
                    if (response.code === 0) {
                        this.product_details = response.data || {}
                        this.sku_prop_list = this.product_details.sku_prop_list
                        this.sku_prop_list_sort = this.product_details.sku_prop_list_sort
                        this.cart_form.product_sale_price_cny = this.product_details.product_price || 0
                        this.min_num = this.product_details.min_num || 1
                    } else {
                        //跳转到手动提交订单页
                        // if (this.request_num >= 1) {
                        //     this.$message({message: 'Can not find the product', type: 'error'});
                        //     this.$router.push('/service/source')
                        // }
                        //
                        // this.request_num = this.request_num + 1

                        this.$message({message: response.msg || 'Can not find this item', type: 'error'});
                        this.$router.push('/service/source')
                    }
                }).catch(error => {
                    //跳转到手动提交订单页
                    this.$message({message: 'Can not find this item', type: 'error'});
                    // if (this.request_num >= 1) {
                    //     this.$message({message: 'Can not find the product', type: 'error'});
                    //     this.$router.push('/service/source')
                    // }
                    // this.request_num = this.request_num + 1
                    this.$router.push('/service/source')
                })
            },
            turnToCustomShopping() {
                window.open('/service/source')
            },
            changeSkuItem(event) {
                //实时计算当前勾选的价格 如果没获取到价格那就 不让添加到cart 需要手动提交
                //当前属性sku被选中的value  用于判断是否存在其他对应的sku选项

                let sku_code = ''
                this.cart_form.sku_code_note = ''
                for (let sku_type in this.sku_prop_list_sort) {
                    if (this.sku_prop_list[this.sku_prop_list_sort[sku_type]] == '') {
                        //this.$message({message: 'Please select ' + this.sku_prop_list_sort[sku_type], type: 'error'});
                        return
                    }
                    //拼接sku code 信息
                    //this.cart_form.sku_code_note += this.product_details.props_list_origin[this.sku_prop_list[this.sku_prop_list_sort[sku_type]]] + ';'

                    sku_code += this.sku_prop_list[this.sku_prop_list_sort[sku_type]] + ';'
                }
                sku_code = sku_code.slice(0, sku_code.length - 1) //这是sku_list 里面的唯一id 从这里获取价格
                this.sku_code = sku_code
                if (this.product_details.sku_list[sku_code] != undefined && this.product_details.sku_list[sku_code] != '') {
                    this.cart_form.product_sale_price_cny = this.product_details.sku_list[sku_code]['price'] || 0
                } else {
                    this.cart_form.product_sale_price_cny = 0
                }
            },
            getCartNums() {
                const token = getToken() || ''
                if (token) {
                    getMyCartNums().then(response => {
                        if (response.code === 0) {
                            this.$root.cart_num = response.data || 0
                        }
                    })
                }
            },
        }
    };
</script>

<style>
    .main_product {
        max-width: 960px;
        margin: 2em auto;
    }
    @media screen and (min-width: 600px) {
        .main_product {
            display: grid;
            grid-template-columns: 1fr 2fr;
            /*column-gap: 5%;*/
        }
    }
    .spu-details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-inline: 1rem;
    }
    .name {
        font-size: 1.5rem;
        font-weight: bold;
        color: #000;
    }
    .footer-buttons {
        text-align: center;
        margin-top: 30px;
    }
    .details_detail {
        background: white;
        border-radius: 1rem;
        text-align: center;
        margin-top: 30px;
        max-width: 1225px;
        width:100%;
        margin-inline: auto;
        padding: 1rem;
    }
    img {
        max-width:100%;
    }
    .product_pic_list {
        height: 70px;
    }
    .item-row {
        min-height: 50px;
        align-items:center
    }
    .item-title {
        display: flex;
        font-weight: bold;
    }
</style>
