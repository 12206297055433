<template>
    <div style="margin-top: 75px;">
<!--        <el-alert-->
<!--                title="Pay for goods items in this page. Then we will buy for you."-->
<!--                type="warning">-->
<!--        </el-alert>-->

        <el-row style="display:flex;;margin-top: 10px;">

                <el-form :inline="true" :model="search_form" ref="search_form" class="demo-form-inline" label-position="top" >
                    <el-form-item required prop="order_status">
                        <el-select placeholder="Please select" v-model="search_form.order_status" filterable>
                            <el-option
                                    v-for="item in order_status_list"
                                    :key="item.status"
                                    :value="item.status"
                                    :label="item.status_text">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="search_text">
                        <el-input :placeholder="$t('page_shopping_payment.order_no')" v-model="search_form.search_text" class="input-with-select"></el-input>
                    </el-form-item>
                    <el-button @click="searchOrder()">{{$t('page_my_order.search')}}</el-button>

                    <el-popconfirm
                            :confirm-button-text="$t('page_cart.yes')"
                            :cancel-button-text="$t('page_cart.no')"
                            :title="$t('page_cart.confirm_buy')"
                            @confirm="goToPay()"
                            style="margin-left: 5px;"
                    >
                        <el-button type="success" slot="reference">{{$t('page_my_order.pay_order')}}</el-button>
                    </el-popconfirm>

                </el-form>


        </el-row>

        <br>
        <el-table
                empty-text="Empty Order"
                :data="order_list"
                tooltip-effect="dark"
                style="width: 100%;margin-top: 30px;"
                ref="multipleTable"
                v-loading="loading"
                @selection-change="handleSelectionChange">
            <el-table-column
                    type="selection">
            </el-table-column>
            <el-table-column
                    width="100px;"
                    prop="order_id"
                    :label="$t('page_shopping_payment.order_no')">
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.item')"
                    width="150px;">
                <template slot-scope="scope">
                    {{$t('page_my_order.product_pic')}}<br>
                    <el-image
                            style="width: 100px; height: 100px"
                            :src="scope.row.product_image_url"
                            :preview-src-list="[scope.row.product_image_url]">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <br>
                    {{$t('page_my_order.product_qc')}}<br>
                    <div v-for="item in scope.row.qc_image_url" :key="item.url" style="display:inline">
                        <el-image
                                style="width: 60px; height: 60px;margin-left: 3px;"
                                :src="item.url"
                                :preview-src-list="[item.url]"
                        >
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    width="170px;"
                    :label="$t('page_cart.product')">
                <template slot-scope="scope">
                    <p>{{scope.row.product_name}}</p>
                    <p v-if="scope.row.product_link"><a :href="scope.row.product_link" target="_blank">View Product</a></p>
                    <p v-if="scope.row.external_order_id">{{$t('page_my_order.store_order_no')}}: {{scope.row.external_order_id}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_wallet.info')"
                    width="200"
            >
                <template slot-scope="scope">
                    <p>{{$t('page_my_order.type')}}: {{scope.row.order_type_text}}</p>
                    <p>{{$t('page_wallet.status')}}: <span style="color: #f96332;">{{scope.row.order_status_text}}</span></p>
                    <p>{{$t('page_my_order.details')}}: {{scope.row.product_sku}}</p>
                    <p>{{$t('page_my_order.remark')}}: {{scope.row.product_remark}}</p>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_shopping_payment.total')"
                    width="130">
                <template slot-scope="scope">
                    USD {{scope.row.total_amount}}<br>
                </template>
            </el-table-column>
            <el-table-column
                    :label="$t('page_cart.operate')"
                    width="150">
                <template slot-scope="scope">

                    <el-button size="small" @click="viewOrderDetail(scope.row.order_id)">{{$t('page_my_order.details')}}</el-button><br>
                    <el-popover
                            placement="right"
                            width="400"
                            trigger="click">
                        <div class="block">
                            <el-timeline :reverse="false">
                                <el-timeline-item
                                        v-for="(item, key) in time_data"
                                        :key="key"
                                        :timestamp="item.time">
                                    <strong>{{item.title}}</strong> <br>
                                    {{item.description}}
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                        <el-button style="margin-left: 0px;margin-top: 3px;" slot="reference" @click="showTime(scope.row)">{{$t('page_my_order.process')}}</el-button><br>
                        <el-button v-if="scope.row.tracking_number_cn" style="margin-left: 0px;margin-top: 3px;" slot="reference" @click="chinaTracking(scope.row)">{{$t('page_my_order.cn_track')}}</el-button><br>
                    </el-popover>
                    <br>

                </template>
            </el-table-column>
        </el-table>


        <el-pagination
                style="margin-top: 30px;"
                layout="prev, pager, next"
                :total="total_count"
                :current-page="current_page"
                @current-change="changePage"
        >
        </el-pagination>

    </div>
</template>
<script>

    import { getShoppingOrders } from '@/api/shopping'

    export default {
        name: 'MyOrders',
        components: {

        },
        props: {

        },
        data() {
            return {
                order_list: [],
                multipleSelection: [],
                total_count: 0,
                current_page: 1,
                loading: true,
                search_form: {
                    search_text: '',
                    order_status: '',
                },
                order_status_list: [
                    {
                        status: '',
                        status_text: 'All',
                    },
                    {
                        status: 1,
                        status_text: 'Unpaid',
                    },
                    {
                        status: 2,
                        status_text: 'Pay Success',
                    },
                    {
                        status: 5,
                        status_text: 'Wait Purchase',
                    },
                    {
                        status: 6,
                        status_text: 'On the way',
                    },
                    {
                        status: 7,
                        status_text: 'In Warehouse',
                    },
                    {
                        status: 8,
                        status_text: 'Unpaid Shipping Fee',
                    },
                    {
                        status: 9,
                        status_text: 'Ready Shipped',
                    },
                    {
                        status: 10,
                        status_text: 'Shipped',
                    },
                ],
                time_data: [],
            };
        },
        created() {
            this.getOrderList()
        },
        methods: {
            getOrderList() {
                let params = this.search_form

                params.page = this.current_page
                params.page_size = 20

                getShoppingOrders(params).then(response => {
                    if (response.code === 0) {
                        this.order_list = response.data.list || []
                        this.total_count = response.data.total_count || 0
                    }

                    this.loading = false
                })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            goToPay() {
                //验证基础数据
                if (this.multipleSelection.length == 0) {
                    this.$message({message: 'Please select order', type: 'error'});
                    return
                }

                let order_ids = []
                for (let id in this.multipleSelection) {
                    //校验数据
                    if (this.multipleSelection[id].order_type == '2') {
                        this.$message({message: this.multipleSelection[id].order_id + ' Forwarding Order can not be selected', type: 'error'});
                        return;
                    }
                    if (this.multipleSelection[id].order_status != '1') {
                        this.$message({message: this.multipleSelection[id].order_id + ' Can not be selected', type: 'error'});
                        return;
                    }

                    order_ids.push(this.multipleSelection[id].order_id)
                }

                this.$router.push('/shopping/payment?order_ids=' + order_ids)

            },
            cancelOrder(order_id) {
                this.$message({message: 'You can not cancel this order!', type: 'error'});
            },
            searchOrder() {
                this.current_page = 1
                this.getOrderList()
            },
            changePage(page) {
                this.current_page = page
                this.getOrderList();
            },
            viewOrderDetail(order_id) {
                // this.$router.push('/shipping/order-detail-new?order_id=' + order_id)
                window.open('/shipping/order-detail-new?order_id=' + order_id)
            },
            showTime(time_data) {
                let times = []
                if (time_data.created_at != '0000-00-00 00:00:00') {
                    times[time_data.created_at] = {
                        time: time_data.created_at.split(' ')[0],
                        title: 'Create Order',
                        description: 'You create the order',
                        sort: new Date(time_data.created_at).getTime(),
                    }
                }
                if (time_data.cancel_time != '0000-00-00 00:00:00') {
                    times[time_data.cancel_time] = {
                        time: time_data.cancel_time.split(' ')[0],
                        title: 'Cancel Order',
                        description: 'You cancel the order',
                        sort: new Date(time_data.cancel_time).getTime(),
                    }
                }
                if (time_data.source_time != '0000-00-00 00:00:00') {
                    times[time_data.source_time] = {
                        time: time_data.source_time.split(' ')[0],
                        title: 'Purchase Product',
                        description: 'TokCommerce team has purchased product for you',
                        sort: new Date(time_data.source_time).getTime(),
                    }
                }
                if (time_data.package_in_time != '0000-00-00 00:00:00') {
                    times[time_data.package_in_time] = {
                        time: time_data.package_in_time.split(' ')[0],
                        title: 'Arrived at warehouse',
                        description: 'Your parcel has arrived at TokCommerce warehouse',
                        sort: new Date(time_data.package_in_time).getTime(),
                    }
                }
                if (time_data.pay_time != '0000-00-00 00:00:00') {
                    times[time_data.pay_time] = {
                        time: time_data.pay_time.split(' ')[0],
                        title: 'Pay for product',
                        description: 'You have paid for your product item',
                        sort: new Date(time_data.pay_time).getTime(),
                    }
                }
                if (time_data.package_out_time != '0000-00-00 00:00:00') {
                    times[time_data.package_out_time] = {
                        time: time_data.package_out_time.split(' ')[0],
                        title: 'Shipped',
                        description: 'TokCommerce team has shipped your parcel',
                        sort: new Date(time_data.package_out_time).getTime(),
                    }
                }


                let new_time = []
                for (let i in times) {
                    new_time.push(times[i])
                }

                function compare(property){
                    return function(a,b){
                        var value1 = a[property];
                        var value2 = b[property];
                        return value1 - value2;
                    }
                }


                this.time_data = new_time.sort(compare('sort'))
            },
            chinaTracking(order) {
                let tracking_number_cn = order.tracking_number_cn
                window.open("https://www.baidu.com/s?wd=" + tracking_number_cn)
            }

        }
    };
</script>
