<template>
  <div class="guides-page">
    <div>
      <h1>
        Best 1688 Shopping Agent to help you source products easier - TokCommerce
      </h1>
      <p>
        Posted on 13 May 2022
      </p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#whatis">What is 1688?</a></li>
        <li><a href="#whyis">Why do you need a 1688 agent?</a></li>
        <li><a href="#suitable">Who is suitable for sourcing from 1688?</a></li>
        <li><a href="#howcan">How can TokCommerce help you?</a></li>
        <li><a href="#orderfrom">How to order from 1688 with TokCommerce?</a></li>
      </ol>


    </div>
    <div class="items-class">
      <b id="whatis" class="subtitle">
        What is 1688?
      </b><br>

      <p>1688.com  is China's biggest wholesale platform where many Chinese e-Commerce seller and trading company source their products. Those sellers then list the products on Amazon, Shopify, Esty,eBay, AliExpress, or just resell to importers from other countries.</p>
      <p>The key features of 1688</p>
      <ul>
        <li>1. Low price compared to retail sites like Amazon, Aliexpress,ebay,etc.</li>
        <li>2. Direct business with Chinese wholesalers or factories.</li>
        <li>3. No MOQ(minium order quantity ) or low MOQ</li>
      </ul>

      <p>
        So if you want to source product from China directly, 1688 is the first place you can access.
      </p>


    </div>
    <div class="items-class">
      <b id="whyis" class="subtitle">
        Why do you need a 1688 agent?
      </b><br>

      <p>Like Taobao, 1688 is also a company operated by Alibaba group, so you need Alipay to register . Unlike buying from Taobao which is more straightforward and transparent, using 1688 is more complicated and has some hidden factors. For example, some sellers list extreme prices to attract buyers as bait,or list prices which actually need pre-order .</p>
      <p>With a 1688 agent you will be gaining the following benefits.</p>

      <ul>
        <li>Efficient Communication-contact the seller/factory</li>
        <li>Free Quality Check</li>
        <li>Order management system</li>
        <li>Free shipment consolidation</li>
        <li>Much cheaper shipping cost</li>
        <li>Product return to factory/seller</li>
      </ul>


    </div>
    <div class="items-class">
      <b id="suitable" class="subtitle">
        Who is suitable for sourcing from 1688?
      </b><br>

      <p>1688 is not for everyone, even in China, only a small people who really want to save every penny will buy from 1688, because compared to Taobao or other e-Commerce platforms, buying 1688 does requires more time and energy to communication back and forth. So 1688 is only for those who are:</p>
      <strong>Business</strong>
      <p>Need to place bulky orders,and need a stable supply chain which can assure you can do business without uncertainty .</p>
      <strong>Online store</strong>
      <p>This is for those who are doing e-Commerce business like dropshipping. You can actually use the 0 MOQ or low MOQ feature of 1688 to get the product.</p>
      <p>The better agent for you - TokCommerce</p>

    </div>


    <div class="items-class">
      <b id="howcan" class="subtitle">
        How can TokCommerce help you?
      </b><br>

      <p>With TokCommerce 1688 agent service, you can have the following benefits.</p>

      <ul>
        <li>free communication</li>
        <li>free product availability</li>
        <li>order manage system</li>
        <li>free QC</li>
        <li>free warehouse service</li>
        <li>cheap shipping lines</li>
        <li>one-to-one service</li>
        <li>make you grow faster</li>
      </ul>

    </div>

    <div class="items-class">
      <b id="orderfrom" class="subtitle">
        How to order from 1688 with TokCommerce?
      </b><br>

      <ul>
        <li>Find product on 1688</li>
        <li>Copy the product/listing link of the product on 1688</li>
        <li>Paste the link into TokCommerce search box</li>
        <li>Select the SKU/Product information and add to the cart</li>
        <li>Pay the product cost</li>
        <li>Seller send to TokCommerce warehouse</li>
        <li>TokComerce warehouse get back to you in the system about product actual details with pictures or videos</li>
        <li>Confirm the international shipping method and shipping cost</li>
        <li>Pay the shipping cost online</li>
        <li>TokCommerce ship the products to you or your end users</li>
      </ul>

    </div>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideTaobaoTokCommerce",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'Best 1688 Shopping Agent to help you source products easier - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: 1688 Shopping Agent helps you source from the largest E-commerce platforms in China' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Best 1688 Shopping Agent to help you source products easier - TokCommerce' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: 1688 Shopping Agent helps you source from the largest E-commerce platforms in China' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
  padding-bottom: 30px;
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
.items-class {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
