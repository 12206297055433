<template>
  <div style="text-align: left">
    <b style="font-size: 20px">{{this.address.country}}</b>
    <p style="font-size: 15px">
      Name: {{this.address.user_name}}<br>
      State/Province: {{this.address.province}}<br>
      City: {{this.address.city}}<br>
      Address Line: {{this.address.address_detail}}<br>
      Postcode: {{this.address.postal_code}}<br>
      Phone Number: {{this.address.phone}}<br>
    </p>
  </div>
</template>

<script>
export default {
  name: "AddressCard",
  props: {
    address: {
      country: String,
      country_code: String,
      user_name: String,
      province: String,
      city: String,
      phone: String,
      email: String,
      address_detail: String,
      postal_code: String
    }
  }
}
</script>

<style scoped>
.class-size{
  margin-bottom: 20px;
}
@media screen and (min-width: 1200px){
  .class-size{
    width: 350px;
    height: 220px;
    margin-bottom: 0px;
  }
}
</style>
