import request_api from '@/utils/request_api'

export function getMyCartList(data) {
    return request_api({
        url: '/api/shopping/get_my_cart_list',
        method: 'post',
        data
    })
}

export function getMyCartNums(data) {
    return request_api({
        url: '/api/shopping/get_my_cart_nums',
        method: 'post',
        data
    })
}

export function addToCart(data) {
    return request_api({
        url: '/api/shopping/add_to_cart',
        method: 'post',
        data
    })
}

export function cartNumUpdate(data) {
    return request_api({
        url: '/api/shopping/cart_num_update',
        method: 'post',
        data
    })
}




//获取商品详细信息 包含sku
export function getProductDetail(data) {
    return request_api({
        url: '/api/shopping/get_product_detail',
        method: 'post',
        data
    })
}


export function getSettlementInfo(data) {
    return request_api({
        url: '/api/shopping/get_settlement_info',
        method: 'post',
        data
    })
}


export function submitShoppingOrders(data) {
    return request_api({
        url: '/api/shopping/submit_shopping_order',
        method: 'post',
        data
    })
}

export function submitShippingOrder(data) {
    return request_api({
        url: '/api/shopping/submit_shipping_order',
        method: 'post',
        data
    })
}

export function submitDeliveryOrder(data) {
    return request_api({
        url: '/api/shopping/submit_delivery_order',
        method: 'post',
        data
    })
}

export function getDeliveryOrders(data) {
    return request_api({
        url: '/api/shopping/get_delivery_orders',
        method: 'post',
        data
    })
}

export function getShoppingOrders(data) {
    return request_api({
        url: '/api/shopping/get_shopping_orders',
        method: 'post',
        data
    })
}

export function payOrder(data) {
    return request_api({
        url: '/api/shopping/pay_order',
        method: 'post',
        data
    })
}

export function getPayments(data) {
    return request_api({
        url: '/api/shopping/get_payments',
        method: 'post',
        data
    })
}


export function payShippingOrder(data) {
    return request_api({
        url: '/api/shipping/pay_order',
        method: 'post',
        data
    })
}

export function getShoppingOrderDetail(data) {
    return request_api({
        url: '/api/shopping/get_shopping_order_detail',
        method: 'post',
        data
    })
}

export function getDeliveryOrderDetail(data) {
    return request_api({
        url: '/api/shopping/get_delivery_order_detail',
        method: 'post',
        data
    })
}

export function submitStoreShoppingOrders(data) {
    return request_api({
        url: '/api/onlinestore/submit_store_shopping_orders',
        method: 'post',
        data
    })
}

export function searchProductByKeyword(data) {
    return request_api({
        url: '/api/shopping/search_product_by_keyword',
        method: 'post',
        data
    })
}


export function getShippingPayments(data) {
    return request_api({
        url: '/api/shopping/get_shipping_payment',
        method: 'post',
        data
    })
}

export function payOrderWithBalance(data) {
    return request_api({
        url: '/api/shopping/pay_order_with_balance',
        method: 'post',
        data
    })
}

export function payShippingWithBalance(data) {
    return request_api({
        url: '/api/shopping/pay_shipping_with_balance',
        method: 'post',
        data
    })
}


