<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
              class="page-header-image"
              style="background-image:url('/img/services/shopping_agent_banner.png')"
      >
      </parallax>
      <div class="content-center" style="margin-top: 10px;">
        <div class="container">
          <h1 class="title title-for-mobile">{{$t('shopping_agent')}}</h1>
          <h5 >{{$t('page_shopping.shopping_des')}}</h5>

          <el-input :placeholder="$t('page_home.search_product_text')" v-model="searchText" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="searchProduct"></el-button>
          </el-input>

          <div class="text-center" style="margin-top: 30px;">
            <a href="https://www.taobao.com/" class="shopping-icon" target="_blank">TaoBao</a>
            <a href="https://www.tmall.com/" class="shopping-icon" target="_blank">TMall</a>
            <a href="https://www.1688.com/" class="shopping-icon" target="_blank">1688</a>
            <a href="#" class="shopping-icon">Weidian</a>
            <a href="https://www.brandyupoo.com/" class="shopping-icon" target="_blank">Yupoo</a>
          </div>

          <div class="text-center" style="margin-top: 30px;">
            <strong>{{$t('page_shopping.shopping_step')}}</strong>
          </div>

          <p></p>
        </div>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">

<!--        <div style="min-height: 600px;width: 100%;">-->
<!--          <div style="width: 100%;min-height: 100px;">-->
<!--            <strong>{{$t('page_shopping.category')}}: {{showKeyword}} </strong> <br>-->
<!--            <div style="margin-top: 15px;"></div>-->

<!--            <div style="display: flex; " class="row">-->
<!--              <el-button v-for="item in (category_list)" :key="item.keyword" plain round @click="keyword = item.keyword; page = 1; showKeyword = item.showKeyword;searchProductList()">{{item.showKeyword}}</el-button>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div style="display: flex; flex-direction: column;">-->
<!--            <div style="width: 100%;padding-bottom: 100px;">-->
<!--              <product-card v-for="(item, key) in product_list" :key="key" :product="item"></product-card>-->

<!--              <div style="text-align: center;">-->
<!--                <el-empty v-if="product_list.length == 0" :description="$t('page_shopping.load_more_items')"></el-empty>-->

<!--                <el-button v-if="!login_token" type="success" round @click="$router.push('/login')">{{$t('sign_in')}}</el-button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <el-pagination-->

<!--                    style="margin-top: 30px;"-->
<!--                    layout="prev, pager, next"-->
<!--                    :page-count="total_page"-->
<!--                    :current-page="page"-->
<!--                    @current-change="changePage"-->
<!--            >-->
<!--            </el-pagination>-->
<!--            <el-alert-->
<!--                    style="margin-top: 30px;"-->
<!--                    :title="$t('page_shopping.note')"-->
<!--                    type="error">-->
<!--            </el-alert>-->
<!--          </div>-->

<!--        </div>-->


        <div style="min-height: 200px;max-width: 500px;padding-top: 50px; margin: auto;" >
          <p>
            {{$t('page_shopping.bottom_text')}}
          </p>
        </div>

<!--        <div class="section section-contact-us text-center">-->
<!--          <div class="container">-->
<!--            <h2 class="title">Expert Buy</h2>-->
<!--            <p>Can't find the item you like? You can submit your shopping order in this way..</p>-->
<!--            <div class="row">-->
<!--              <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">-->
<!--                <div class="send-button">-->
<!--                  <n-button type="primary" round block size="lg" @click="$router.push('/service/source')">Expert Buy</n-button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


        <div class="section section-contact-us text-center">
          <div class="container">
            <h2 class="title">{{$t('page_shopping.process_data')}}</h2>
            <p>{{$t('page_shopping.process_des')}}</p>
            <div class="row">
              <div class="col-lg-10 text-center ml-auto mr-auto col-md-10">

                <img v-lazy="'/img/services/process.png'" style="display: block;margin: 25px auto;width: 100%;"/>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
  import { Button, FormGroupInput, Pagination } from '@/components';
  import ProductCard from '@/components/Product/ProductCard'
  import { getToken } from '@/utils/auth';

  import { searchProductByKeyword } from "@/api/shopping"

  export default {
    name: 'landing',
    bodyClass: 'landing-page',
    components: {
      [Button.name]: Button,
      [FormGroupInput.name]: FormGroupInput,
      [Pagination.name]: Pagination,
       // ProductCard,
    },
    metaInfo () {
      return {
        title: this.$t('meta.shopping_agent_title'),
        meta: [
          { hid: 'description', name: 'description', content: this.$t('meta.shopping_agent_des') },
          { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
          { hid: 'og:type', name: 'og:type', content: 'website' },
          { hid: 'og:title', name: 'og:title', content: this.$t('meta.shopping_agent_title') },
          { hid: 'og:description', name: 'og:description', content: this.$t('meta.shopping_agent_des') },
          { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
          { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/search' },
        ],
      }
    },
    // metaInfo: {
    //   title: 'Shopping Agent - TokCommerce',
    //   meta: [
    //     { hid: 'description', name: 'description', content: 'TokCommerce: Shopping and Forwarding from China Right at your Fingertips.' },
    //     { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
    //     { hid: 'og:type', name: 'og:type', content: 'website' },
    //     { hid: 'og:title', name: 'og:title', content: 'Shopping Agent' },
    //     { hid: 'og:description', name: 'og:description', content: 'TokCommerce: Shopping and Forwarding from China Right at your Fingertips.' },
    //     { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
    //     { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/search' },
    //   ],
    // },
    data() {
      return {
        defaultPagination: 1,
        total_count: 10,
        product_list: [],
        searchText: '',
        page: 1,
        keyword: "女装",//搜索的词
        showKeyword: "Women's clothing",//英文展示的词
        category_list: [
          {
            keyword: "女装",
            showKeyword: "Women's clothing",
          },
          {
            keyword: "女鞋",
            showKeyword: "Women's shoes",
          },
          {
            keyword: "男装",
            showKeyword: "Men's clothing",
          },
          {
            keyword: "男鞋",
            showKeyword: "Men's shoes",
          },
          {
            keyword: "运动",
            showKeyword: "Sports",
          },
          {
            keyword: "汉服",
            showKeyword: "Hanfu",
          },
          {
            keyword: "Cosplay",
            showKeyword: "Cosplay",
          },
        ],
        total_page: 0,
        login_token: '',

      };
    },
    created() {
      this.searchProductList()
    },
    methods: {
      searchProduct() {
        //单个商品查询
        if (this.searchText == '') {
          this.$message({message: 'Please input Product URL', type: 'error'});
        }
        if (this.searchText == '') {
          this.$message({message: 'Please input Product URL', type: 'error'});
          return
        }

        window.open('/product/detail?search_text=' + this.searchText)

      },
      searchProductList() {
        //关键字搜索
        if (this.keyword == "") {
          this.$message({message: 'Please check your selection', type: 'error'});
          return;
        }

        const token = getToken() || ''
        this.login_token = token
        if (token == undefined || token == "") {
          this.$message({message: 'Sign in to load products', type: 'error'});
          return;
        }

        let params = {
          page: this.page,
          keyword: this.keyword,
        }

        this.$message({message: 'Loading now....', type: 'warning'});
        this.product_list = []
        this.total_page = 0
        searchProductByKeyword(params).then(response => {
          if (response.code === 0 && response.data.length != 0) {
            this.product_list = response.data.list || []
            this.total_page = response.data.total_page || 0
          } else {
            this.$message({message: 'Please try again', type: 'error'});
          }
        }).catch(err => {
          this.$message({message: 'Please try again', type: 'error'});
        })

      },
      changePage(page) {
        this.page = page
        this.searchProductList();
      },

    },
  };
</script>
<style type="text/css">
  input {
    background-color: #ffffff;
  }
  .shopping-icon {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    .title-for-mobile {
      font-size: 25px;
    }
    .description-for-mobile {
      font-size: 20px;
    }
    .p-for-mobile{
      font-size: 15px;
    }
  }
</style>
