<template>
    <div>
        <!--filter-color="orange"-->
        <div class="page-header clear-filter" style="max-height: 700px;">
            <parallax
                    class="page-header-image"
                    style="background-image:url('/img/services/home_banner.png');height: 700px;"
            >
            </parallax>
            <div class="container" style="height: 700px;">
                <div class="content-center brand" style="padding-top: 80px;">
                    <!--          <img class="n-logo" src="img/now-logo.png" alt="" />-->
                    <h1 class="title-for-mobile" style="color: #ffffff;">{{$t('page_home.title')}}</h1>
                    <h3 class="description-for-mobile" style="color: #ffffff;">{{$t('page_home.description')}}</h3>

                    <div>
                        <el-input :placeholder="$t('page_home.search_product_text')" v-model="searchText" class="input-with-select">
                            <el-button slot="append" icon="el-icon-search" @click="searchProduct"></el-button>
                        </el-input>
                    </div>

                    <div class="text-center" style="margin-top: 30px;">
                        <a href="https://www.taobao.com/" class="shopping-icon" target="_blank">TaoBao</a>
                        <a href="https://www.tmall.com/" class="shopping-icon" target="_blank">TMall</a>
                        <a href="https://www.1688.com/" class="shopping-icon" target="_blank">1688</a>
                        <a href="#" class="shopping-icon">Weidian</a>
                        <a href="https://www.brandyupoo.com/" class="shopping-icon" target="_blank">Yupoo</a>
                    </div>

                    <n-button class="btn btn-primary btn-round btn-lg" round size="lg" @click="$router.push('/register')">{{$t('sign_up')}}</n-button>
                    <n-button type="neutral" round size="lg" @click="$router.push('/guides/guide-main')">{{$t('user_guides')}}</n-button>
                </div>
            </div>
        </div>

        <index-about-us></index-about-us>

        <div class="section">
              <h2 class="title" style="text-align: center">{{$t('page_home.our_services')}}</h2>
              <div class="service-card-row">
                <el-card class="service-card">
                  <b class="service-card-title">{{$t('shopping_agent')}}</b><br><br>
                  <p>{{$t('page_home.service_shopping_agent')}}</p>
                  <a href="/search">{{$t('page_home.shop_now')}}</a>
                </el-card>

                <el-card class="service-card">
                  <b class="service-card-title">{{$t('forwarding')}}</b><br><br>
                  <p>{{$t('page_home.service_forwarding_agent')}}</p>
                  <a href="/service/forwarding">{{$t('page_home.ship_now')}}</a>
                </el-card>

                <el-card class="service-card">
                  <b class="service-card-title">{{$t('expert_buy')}}</b><br><br>
                  <p>{{$t('page_home.service_expert_buy')}}</p>
                  <a href="/service/source">{{$t('page_home.shop_now')}}</a>
                </el-card>

                <el-card class="service-card">
                  <b class="service-card-title">{{$t('dropshipping')}}</b><br><br>
                  <p>{{$t('page_home.service_dropshipping')}}</p>
                  <a href="/service/dropshipping">{{$t('page_home.shop_now')}}</a>
                </el-card>
              </div>
        </div>

        <shipping-line></shipping-line>

        <div class="section" style="margin-top: 100px;">

            <div class="container">
                <div class="logistics_menu">
                    <div>
                        <h2>{{$t('page_home.logistics_service_title')}}</h2>
                        <p>{{$t('page_home.calculate_now')}}</p>
                        <a
                                href="/service/shipping-calculate"
                                class="btn btn-primary btn-simple btn-round btn-lg"
                        >{{$t('page_home.calculate')}}</a>
                    </div>
                    <div>
                        <el-table
                                :data="shipping_data"
                                style="width: 100%">
                            <el-table-column
                                    prop="destination_country"
                                    :label="$t('page_home.destination')"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="weight"
                                    :label="$t('page_calculator.product_weight')"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="price"
                                    :label="$t('page_home.shipping_price')">
                            </el-table-column>
                            <el-table-column
                                    prop="delivery_time"
                                    :label="$t('page_calculator.delivery_time')">
                            </el-table-column>
                            <el-table-column
                                    prop="method"
                                    :label="$t('page_home.method')">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

              <div class="guides-card-row" style="margin-top: 100px;">
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('page_home.products')}}</b><br><br>
                  <p>{{$t('page_home.products_des')}}</p>
                </el-card>
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('page_home.warehousing')}}</b><br><br>
                  <p>{{$t('page_home.warehousing_des')}}</p>
                </el-card>
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('page_home.packing')}}</b><br><br>
                  <p>{{$t('page_home.packing_des')}}</p>
                </el-card>
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('forwarding')}}</b><br><br>
                  <p>{{$t('page_home.forwarding_des')}}</p>
                </el-card>
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('page_home.quality_check')}}</b><br><br>
                  <p>{{$t('page_home.quality_check_des')}}</p>
                </el-card>
                <el-card shadow="always" class="service-card">
                  <b style="font-size: 25px">{{$t('dropshipping')}}</b><br><br>
                  <p>{{$t('page_home.dropshipping_des')}}</p>
                </el-card>
              </div>
            </div>


            <div>
                <h2 class="title" style="margin-top: 100px;text-align: center;">{{$t('page_home.latest_shopping')}}</h2>

                <el-carousel :interval="4000" type="card" style="height: 400px;max-height: 500px;">
                    <el-carousel-item v-for="item in shopping_news" :key="item.id" style="height: 400px;max-height: 500px;" class="shopping_news">
                        <div style="display: block;margin: auto;text-align: center;padding: 5px;">
                            <el-image
                                :src="item.product_image_url"
                                :preview-src-list="[item.product_image_url]"
                                alt="Image"
                                class="rounded"
                                style="width: 70%;max-height: 200px;">
                                <div slot="error" class="image-slot">
                                    Picture can not show
                                </div>
                            </el-image>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-content: center;padding: 5px;">
                            <p>To: {{item.country_code}} </p>
                            <p>Date: {{item.created_at}} </p>
                            <p>Weight: {{item.product_weight}} KG</p>
                            <p>Delivery Time: {{item.delivery_time}} Days</p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <why-tok-commerce></why-tok-commerce>
        </div>

        <customer-service-section></customer-service-section>
        <!--        <signup-form></signup-form>-->
        <examples-section style="margin-top: 100px;"></examples-section>

        <download-section></download-section>




        <el-dialog title="Dear Customers" :visible.sync="show_home_community" :width="dialog_width"
                   style="word-break: normal;">
            <p>Hey guys!</p>

<!--            Note: The system is being upgraded. The upgrade is expected to be completed within 2-3 days.-->
<!--            At present, we cannot accept the order payment. If you have any questions, please consult our customer service.-->

            <p>Note: We have moved to new warehouse. Please don`t send parcels to old warehouse address. Check new address in the Forwarding Page.</p>

            {{$t('community_des')}}<br>

            <n-button class="btn btn-primary btn-round btn-lg" round size="lg" @click="openCommunity()">{{$t('get_community_help')}}</n-button>


        </el-dialog>

    </div>
</template>

<script>
    import {Parallax, Button} from '@/components';
    import ExamplesSection from './components/ExamplesSection';
    import DownloadSection from './components/DownloadSection';
    import IndexAboutUs from './components/IndexAboutUs';
    import WhyTokCommerce from './components/WhyTokCommerce'
    import Card from '../components/Cards/Card'
    import { setInvite, setHomeCommunity, getHomeCommunity } from '@/utils/auth';
    import { getShoppingNews } from '@/api/index'
    import { checkIsMobile } from '@/utils/screen'
    import CustomerServiceSection from "../components/CustomerServiceSection";
    import ShippingLine from '@/components/ShippingLine'


    export default {
        name: 'index',
        bodyClass: 'index-page',
        metaInfo () {
            return {
                title: this.$t('meta.index_title'),
                meta: [
                    { hid: 'description', name: 'description', content: this.$t('meta.index_des') },
                    { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                    { hid: 'og:type', name: 'og:type', content: 'website' },
                    { hid: 'og:title', name: 'og:title', content: this.$t('meta.index_title') },
                    { hid: 'og:description', name: 'og:description', content: this.$t('meta.index_des') },
                    { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                    { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
                ],
            }
        },
        // metaInfo: {
        //     title: this.$t('meta.index_title'),
        //     meta: [
        //         { hid: 'description', name: 'description', content: this.$t('meta.index_des') },
        //         { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
        //         { hid: 'og:type', name: 'og:type', content: 'website' },
        //         { hid: 'og:title', name: 'og:title', content: this.$t('meta.index_title') },
        //         { hid: 'og:description', name: 'og:description', content: this.$t('meta.index_des') },
        //         { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
        //         { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
        //     ],
        // },CN￥560 Shipping Coupon
        components: {
          CustomerServiceSection,
            Parallax,
            // SignupForm,
            ExamplesSection,
            DownloadSection,
            // FgInput,
            IndexAboutUs,
            // Steps,
            [Button.name]: Button,
            WhyTokCommerce,
            ShippingLine,
        },
        data() {
          return {
              searchText: '',
              shopping_news: [],
              shipping_data: [
                  {
                      destination_country: 'USA',
                      weight: '1KG',
                      price: '$22',
                      delivery_time: '13',
                      method: 'Air',
                  },
                  {
                      destination_country: 'USA',
                      weight: '3KG',
                      price: '$56',
                      delivery_time: '7',
                      method: 'Air',
                  },
                  {
                      destination_country: 'Canada',
                      weight: '2KG',
                      price: '$45',
                      delivery_time: '12',
                      method: 'Air',
                  },
                  {
                      destination_country: 'UK',
                      weight: '1KG',
                      price: '$18',
                      delivery_time: '15',
                      method: 'Air',
                  },
              ],
              show_home_community: false,
              dialog_width: '',
          }
        },
        created() {
            const invite_code = this.$route.query.invite_code || ''
            if (invite_code) {
                setInvite(invite_code);
            }
            // this.showCommunity()
            this.getShoppingNews()
        },
        methods: {
            searchProduct() {
                if (this.searchText == '') {
                    this.$message({message: 'Please input Product URL', type: 'error'});
                    return
                }

                window.open('/product/detail?search_text=' + this.searchText)
            },
            getShoppingNews() {
                getShoppingNews().then(response => {
                    this.shopping_news = response.data || []
                })
            },
            showCommunity() {
                // if (checkIsMobile()) {
                //     return;//如果是手机端 那么不用显示
                // }
                //return;


                //先判断是否在单位时间内 已弹过
                if (getHomeCommunity()) {
                    return
                }

                //set 同时弹出对话框
                setHomeCommunity()

                this.dialog_width = checkIsMobile() ? '90%' : '40%'
                this.show_home_community = true
            },
            openCommunity() {
                window.open('https://forum.tokcommerce.com/')
            },
        },
    };
</script>

<style>
    .logistics_menu {
        max-width: 100%;
    }
    @media screen and (min-width: 600px) {
        .logistics_menu {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1px;
        }
    }
    .shopping_news {
        max-width: 100%;
    }
    @media screen and (min-width: 600px) {
        .shopping_news {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 1px;
        }
    }


    .el-table .warning-row {
        background: oldlace;
    }

    .el-table .success-row {
        background: #f0f9eb;
    }

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }
    .el-carousel__item:nth-child(2n) {
        background-color: #F5F5F5;
    }
    .el-carousel__item:nth-child(2n+1) {
        background-color: #F5F5F5;
    }
    .guides-card-row{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 25px auto;
    }
    .tag-card{
      width: auto;
      margin: 10px 0;
    }
    .service-card-row{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .service-card{
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
      text-align: center;
      border-radius: 10px;
    }

    @media screen and (min-width: 1200px) {
      .guides-card-row{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        margin-left: 30%;
        margin-right: 30%;
      }
      .tag-card{
        width: 300px;
        margin: 0 10px;
      }
      .service-card-row{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        margin-left: 30%;
        margin-right: 30%;
      }
      .service-card {
        width: 450px;
        height: 180px;
        margin: 10px;
      }
    }

    .service-card-title {
      font-size: 20px;
    }
</style>
