<template>
    <div>
        <div class="section section-about-us" style="margin-top: 80px;min-height: 800px;">

            <h2 style="text-align: center;">{{$t('page_settlement.title')}}</h2>

            <div class="container">
                <el-table
                        empty-text="Your settlement is empty"
                        :data="settlement_list"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="loading">
                    <el-table-column
                            :label="$t('page_cart.item')"
                            width="100px;">
                        <template slot-scope="scope">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="scope.row.product_image_url">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            width="200px;"
                            prop="product_name"
                            :label="$t('page_cart.product')">
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.price')">
                        <template slot-scope="scope">
                            CNY {{scope.row.product_sale_price_cny}}<br>
                            USD {{scope.row.product_sale_price_usd}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.quantity')"
                            prop="quantity">
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.freight')">
                        <template slot-scope="scope">
                            CNY {{scope.row.product_freight_amount_cny}}<br>
                            USD {{scope.row.product_freight_amount_usd}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.total')">
                        <template slot-scope="scope">
                            CNY {{scope.row.total_amount_cny}}<br>
                            USD {{scope.row.total_amount_usd}}
                        </template>
                    </el-table-column>

                </el-table>


                <div style="margin-top: 30px">
                    <el-col :span="11" style="float: left;">
                        <div>
                            <h3>{{$t('page_settlement.information')}}</h3>
                            {{$t('page_settlement.note_line1')}}<br>
                            {{$t('page_settlement.note_line2')}}<br>
                            {{$t('page_settlement.note_line3')}}<br>
                            {{$t('page_settlement.note_line4')}}<br>
                            {{$t('page_settlement.note_line5')}}<br>
                            6. <a href="/service/compensation-taxation" target="_blank">{{$t('page_taxation.title')}}</a><br>
                        </div>
                    </el-col>
                    <el-col :span="11" style="float: right;">
                        <el-form :inline="false" :model="submit_form" ref="submit_form" class="demo-form-inline"
                                 label-position="top">
                            <el-form-item :label="$t('page_forwarding.country_name')" required prop="country_code">
                                <el-select v-model="submit_form.country_code" filterable
                                           :placeholder="$t('page_forwarding.country_name')" style="width: 100%;">
                                    <el-option
                                            v-for="country in country_list"
                                            :key="country.country_code"
                                            :value="country.country_code"
                                            :label="country.en_name + ' - ' + country.cn_name">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-alert
                                    style="margin-top: 10px;"
                                    :title="$t('page_settlement.address_note')"
                                    type="success">
                            </el-alert>
                        </el-form>
                    </el-col>
                </div>

<!--                <el-alert-->
<!--                        style="margin-top: 40px"-->
<!--                        title="Handle Fee: Third-party payment processing fees and purchase service fees | Total 5%"-->
<!--                        type="warning">-->
<!--                </el-alert>-->

                <card class="text-right" style="margin-top: 50px;">
                    <h4 class="card-title">Handle Fee: $ {{handling_fee}}</h4>
                    <h4 class="card-title">Total: ${{total_amount_usd}}</h4>

                    <el-popconfirm
                            :confirm-button-text="$t('page_cart.yes')"
                            :cancel-button-text="$t('page_cart.no')"
                            :title="$t('page_settlement.confirm_submit')"
                            @confirm="submitOrders('submit_form')"
                            v-if="show_submit_btn"
                    >
                        <el-button type="success" slot="reference">{{$t('page_settlement.submit_order')}}</el-button>
                    </el-popconfirm>
                </card>

            </div>
        </div>

    </div>
</template>
<script>

    import { Button, FormGroupInput, Card } from '@/components';
    import { getSettlementInfo, submitShoppingOrders } from '@/api/shopping'
    import { getCountryList } from "@/api/user";


    export default {

        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                settlement_list: [],
                total_amount_cny: 0.00,
                total_amount_usd: 0.00,
                handling_fee: 0.00,
                loading: true,
                country_list: [],
                submit_form: {
                    country_code: '',
                },
                cart_ids: '',
                show_submit_btn: true,
            };
        },
        created() {
            let cart_ids = this.$route.query.cart_ids || ''
            if (cart_ids == null || cart_ids == '') {
                this.$message({message: 'Can not checkout', type: 'error'});
                this.$router.push('/')
            }
            this.cart_ids = cart_ids

            //获取结算信息
            this.getSettlement(cart_ids)
            this.getCountrys()
        },
        methods: {
            submitOrders(form_name) {
                this.$refs[form_name].validate((valid) => {
                    if (!valid) {
                        this.$message({message: 'Please select country', type: 'error'});
                        return false
                    }

                    let order_params = {
                        cart_ids: this.cart_ids,
                        country_code: this.submit_form.country_code
                    }
                    this.show_submit_btn = false
                    this.$message({message: 'Submitting now... please wait', type: 'warning'});
                    submitShoppingOrders(order_params).then(response => {
                        if (response.code === 0) {
                            this.$message({message: 'Submit success', type: 'success'});
                            this.$router.push('/shopping/payment?order_ids=' + response.data)
                        } else {
                            this.$message({message: 'Submit failed.Please retry.', type: 'error'});
                        }
                        this.show_submit_btn = true
                    }).catch(error => {
                        this.show_submit_btn = true
                        this.$message({message: 'Submit failed.Please retry.', type: 'error'});
                    })
                })
            },
            getCountrys() {
                getCountryList().then(res => {
                    this.country_list = res.data || []
                })
            },
            getSettlement(cart_ids) {
                let params = {
                    cart_ids: cart_ids
                }
                getSettlementInfo(params).then(response => {
                    if (response.code === 0) {
                        this.settlement_list = response.data.list || []
                        this.total_amount_usd = response.data.total_amount_usd || 0.00
                        this.total_amount_cny = response.data.total_amount_cny || 0.00
                        this.handling_fee = response.data.handling_fee || 0.00
                    } else {
                        this.$message({message: 'Get settlement failed', type: 'error'});
                    }
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    this.$message({message: 'Get settlement failed', type: 'error'});
                })
            }
        }




















    };
</script>
<style></style>
