<template>
    <div style="padding: 10px; margin-top: 30px;" class="container">
        <h2 style="text-align: center;">Shipping Lines</h2>

        <div style="margin-top: 10px; display: flex; justify-content: space-between; flex-wrap:wrap;">
            <img class="d-block" src="/img/lines/ups.png" alt="UPS" />
            <img class="d-block" src="/img/lines/dhl.png" alt="DHL" />
            <img class="d-block" src="/img/lines/yunexpress.png" alt="YunExpress" />
            <img class="d-block" src="/img/lines/fedex.png" alt="FedEx" />
        </div>
    </div>
</template>
<script>


    export default {
        name: 'ShippingLine',
        components: {

        },
        props: {

        },
        data() {
            return {

            };
        },
        methods: {

        }
    };
</script>

<style>
    .d-block{
        margin: 2px auto;
        height: 120px;
    }
</style>
