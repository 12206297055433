<template>
    <div style="margin-top: 75px;">
        <card class="text-center">

            <img src="/img/user-icon.png" style="border-radius: 60px; width: 150px;">

            <h4 class="card-title">{{$t('page_profile.account')}}: {{account_info.account_name}}</h4>
            <p class="card-text">{{$t('page_profile.email')}}: {{account_info.account_no}}</p>

            <br>
            <el-button type="danger" round size="small" @click="logout()">{{$t('log_out')}}</el-button>
        </card>

        <div style="display:flex;justify-content: space-between;">
            <el-col :span="11">
                <card class="text-center">

                        <div class="account-data" style="color: black;">
                            <strong class="card-title">{{$t('page_profile.balance')}}</strong><br>
                            <strong class="card-text">0 USD</strong>
                        </div>
                </card>
            </el-col>
            <el-col :span="11">
                <card class="text-center">

                        <div class="account-data" style="color: black;">
                            <strong class="card-title">{{$t('page_person.coupons')}}</strong><br>
                            <strong class="card-text">{{coupon_num}}</strong>
                        </div>
                </card>
            </el-col>
        </div>
    </div>
</template>
<script>

    import { Button, Card } from '@/components';
    import { getAccountInfo, getCouponInfo } from '@/api/user'
    import {removeAccountName, removeToken, removeTEMPACCOUNTNO, getToken} from '@/utils/auth'

    export default {
        name: 'MyProfile',
        components: {
            [Button.name]: Button,
            [Card.name]: Card,
        },
        props: {

        },
        data() {
            return {
                account_info: {},
                coupon_num: 0,
            };
        },
        created() {
            this.getAccountInfo()
            this.getCouponNum()
        },
        methods: {
            getAccountInfo() {
                getAccountInfo().then(response => {
                    if (response.code === 0) {
                        this.account_info = response.data || {}
                    }
                })
            },
            getCouponNum() {
                getCouponInfo().then(response => {
                    if (response.code === 0) {
                        this.coupon_num = response.data || 0
                    }
                })
            },
            logout() {
                removeToken()
                removeAccountName()
                removeTEMPACCOUNTNO()

                this.$message({message: 'Logout Success', type: 'success'});
                this.$root.cart_num = 0
                this.$root.notice_num = 0
                this.$root.isLogin = false

                if (this.$route.path != '/') {
                    this.$router.push('/')
                }
            },
        }
    };
</script>

<style>
    .account-data {
        border: 1px solid #f96332;
    }
</style>
