<template>
    <div style="margin-top: 75px;">
      <h3>Manage Store Order - {{store_name}}</h3>
      <el-alert
          title="Manage your store orders"
          type="warning">
      </el-alert>

      <el-button type="success" @click="add_store_dialog_visible=true" style="margin-top: 30px">Import Store Orders - {{store_type}}</el-button>
      <el-tooltip class="item" effect="light" placement="top-start">
        <div slot="content">
          1.Export orders from your store<br>
          2.Import orders<br>
          3.Check order information<br>
          4.Confirm and Pay<br>
          5.TokCommerce team buy and ship for you<br>
        </div>
        <i class="el-icon-question"></i>
      </el-tooltip>

      <el-row style="justify-content: space-between;margin-top: 10px;">
        <el-form
            :inline="true"
            :model="store_list_search_params"
            ref="store_list_search_params"
            class="demo-form-inline"
            label-position="top" >

          <el-form-item prop="external_order_id">
            <el-input placeholder="Store Order No" v-model="store_list_search_params.external_order_id" class="input-with-select"></el-input>
          </el-form-item>

          <el-button @click="search()">Search</el-button>
          <el-button type="success" @click="goToPay()">Go to pay</el-button>
        </el-form>
      </el-row>



      <el-table
        empty-text="No Product Added Yet"
        :data="product_list"
        tooltip-effect="dark"
        style="width: 100%;margin-top: 30px;"
        v-loading="loading"
        >
        <el-table-column
          width="200"
          prop="external_order_id"
          label="Store Order No"
          ></el-table-column>
        <el-table-column
          width="200"
          prop="order_id"
          label="TokCommerce Order No"
          >
        </el-table-column>
        <el-table-column
          width="150"
          prop="order_status_text"
          label="Order Status"
        >
        </el-table-column>
        <el-table-column
          width="200"
          prop="total_amount"
          label="Total"
          >
        </el-table-column>

        <el-table-column
                label="Operate"
                width="150">
          <template slot-scope="scope">
            <el-button size="small" round @click="viewOrderDetails(scope.row.order_id)">View Details</el-button><br>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination
          style="margin-top: 30px;"
          layout="prev, pager, next"
          :total="total_count"
          :current-page="current_page"
          @current-change="changePage"
      >
      </el-pagination>


      <el-dialog
              :title="'Import Store Orders - ' + store_type"
              :visible.sync="add_store_dialog_visible"
              width="80%"
      >
        <el-alert
                title="Import your store orders with one click, and the TokCommerce team buys and ships them for you."
                type="warning">
        </el-alert>
        <div style="margin-top: 10px;"></div>

        <el-row>
          <el-col :span="10">
            <el-upload
                    drag
                    name="order_file"
                    :action="batch_import_url"
                    accept=".csv"
                    :http-request="logistic_price_upload"
                    :before-upload="startUpload"
                    :show-file-list="true"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text"><em>Click to import your store orders</em></div>
              <div class="el-upload__tip" slot="tip">Only support "CSV" file.</div>
            </el-upload>
          </el-col>

          <el-col :span="10">
            <el-button size="small" plain @click="turnToManageProduct()">Add New product</el-button>
          </el-col>
        </el-row>


        <br>
        <h3 style="margin-top: 30px;">Error Orders</h3>
        <el-alert
                title="You should deal error orders first. Otherwise, this part of orders cannot be submitted, and we cannot buy these order items for you."
                type="error">
        </el-alert>
        <el-table
                :data="error_order_list"
                style="width: 100%"
                empty-text="Empty error order"
                height="300">
          <el-table-column
                  type="index"
                  label="No"
                  fixed
                  width="50">
          </el-table-column>
          <el-table-column
                  prop="store_product_sku"
                  label="Store Product Sku"
                  width="170">
          </el-table-column>
          <el-table-column
                  prop="store_product_name"
                  label="Store Product Name"
                  width="200">
          </el-table-column>
          <el-table-column
                  prop="external_order_id"
                  label="Store Order No"
                  width="150">
          </el-table-column>
          <el-table-column
                  prop="product_sku"
                  label="Details"
                  width="200">
          </el-table-column>
          <el-table-column
                  prop="product_quantity"
                  label="Quantity"
                  width="100">
          </el-table-column>
          <el-table-column
                  prop="error_msg"
                  label="Error Msg"
                  width="200">
          </el-table-column>
        </el-table>



        <h3 style="margin-top: 30px;">Success Orders</h3>
        <el-table
                :data="success_order_list"
                empty-text="Empty success order"
                style="width: 100%"
                height="500">
          <el-table-column
                  type="index"
                  label="No"
                  fixed
                  width="50">
          </el-table-column>
          <el-table-column
                  prop="store_product_sku"
                  label="Store Product Sku"
                  width="170">
          </el-table-column>
          <el-table-column
                  prop="store_product_name"
                  label="Store Product Name"
                  width="200">
          </el-table-column>
          <el-table-column
                  prop="external_order_id"
                  label="Store Order No"
                  width="150">
          </el-table-column>
          <el-table-column
                  prop="product_sku"
                  label="Details"
                  width="200">
          </el-table-column>
          <el-table-column
                  prop="product_quantity"
                  label="Quantity"
                  width="100">
          </el-table-column>
          <el-table-column
                  prop="product_sale_price_cny"
                  label="Unit Price(CNY)"
                  width="150">
          </el-table-column>
          <el-table-column
                  label="Taobao/1688 Product Link"
                  width="200">
            <template slot-scope="scope">
              <a :href="scope.row.product_link" target="_blank">View Product</a>
            </template>
          </el-table-column>
          <el-table-column
                  prop="product_remark"
                  label="Product Remark"
                  width="150">
          </el-table-column>
        </el-table>

        <el-button type="success" style="margin-top: 30px;" @click="submitOrders()">Submit And Pay</el-button>
        <el-alert
                title="Once you submit these success orders and pay, we will buy for you in one day."
                type="success"
                style="margin-top: 10px;">
        </el-alert>

      </el-dialog>


    </div>
</template>
<script>


  import {checkOnlineOrders, getStoreOrderList} from "@/api/onlinestore";
  import { submitStoreShoppingOrders } from "@/api/shopping"
  import XLSX from 'xlsx'

    export default {
        name: 'MyStoreOrder',
        components: {

        },
        props: {

        },
        data() {
            return {
              add_store_dialog_visible: false,
              add_store_form: {
                store_product_sku: '',
                product_link: '',
                product_sale_price_cny: '',
                product_name: '',
                product_remark: '',
                store_id: '',
              },
              total_count: 0,
              current_page: 1,
              product_list: [],
              store_list_params: {},
              store_list_search_params: {
                store_id: '',
                external_order_id: '',
              },
              loading: true,
              store_name: '',
              store_type: '',
              batch_import_url: '',
              store_id: '',
              error_order_list: [],
              success_order_list: [],
            };
        },
        created() {
          let store_id = this.$route.query.store_id || ''
          let store_name = this.$route.query.store_name || ''
          let store_type = this.$route.query.store_type || ''

          this.add_store_form.store_id = store_id
          this.store_list_search_params.store_id = store_id
          this.store_id = store_id
          this.store_name = store_name
          this.store_type = store_type

          this.getStoreOrderList()
        },
        methods: {
          getStoreOrderList(){
            let params = this.store_list_search_params
            params.page = this.current_page
            params.page_size = 10

            this.$message({message: 'Loading now...', type: 'warning'});
            getStoreOrderList(params).then(res => {
              if (res.code === 0){
                this.product_list = res.data.list || []
                this.total_count = res.data.total || 0
              } else {
                this.$message({message: 'Loading failed....', type: 'error'});
              }

              this.loading = false
            }).catch(error => {
              this.loading = false
            })
          },
          changePage(page) {
            this.current_page = page
            this.getProductList();
          },
          search(){
            this.current_page = 1
            this.getStoreOrderList()
          },
          logistic_price_upload(file_data) {
            const that = this
            var file = file_data.file
            var reader = new FileReader()
            FileReader.prototype.readAsBinaryString = function (file) {
              var binary = ''
              var wb // 读取完成的数据
              var reader = new FileReader()
              reader.onload = function (e) {
                // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
                var bytes = new Uint8Array(reader.result)
                var length = bytes.byteLength
                for (var i = 0; i < length; i++) {
                  binary += String.fromCharCode(bytes[i])
                }
                wb = XLSX.read(binary, {
                  type: 'binary'
                })

                // todo  order_id 格式变长了



                let imports = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
                let data_list = []

                //根据店铺不同 识别的字段不同
                if (that.store_type == 'Wix') {
                  for (let i in imports) {

                    let Variant = imports[i]['Variant'] || ''
                    if (!Variant) {
                      Variant = ''
                    }

                    let order = {
                      external_order_id: imports[i]['Order no.'] || '',
                      store_product_name: imports[i]['Item'] || '',
                      product_sku: Variant,
                      store_product_sku: imports[i]['SKU'] || '',
                      product_quantity: imports[i]['Qty'] || '',
                      store_id: that.store_id || '',
                    }
                    data_list.push(order)
                  }

                } else if (that.store_type == 'Shopify') {
                  for (let i in imports) {
                    let order = {
                      external_order_id: imports[i]['Name'] || '',
                      store_product_name: imports[i]['Lineitem name'] || '',
                      product_sku: imports[i]['Lineitem sku'] || '',
                      store_product_sku: imports[i]['Lineitem sku'] || '',
                      product_quantity: imports[i]['Lineitem quantity'] || '',
                      store_id: that.store_id || '',
                    }
                    data_list.push(order)
                  }
                } else {
                  that.$message({'message': 'Can not support this store type', 'type': 'warning'})
                }


                if (data_list.length <= 0) {
                  that.$message({'message': 'Empty order', 'type': 'warning'})
                  return
                }
                //校验订单的状态
                checkOnlineOrders({order_list: data_list, store_id: that.store_id}).then(response => {
                  if (response.code === 0) {
                    that.error_order_list = response.data.error || []
                    that.success_order_list = response.data.success || []
                  } else {
                    that.$message({'message': response.msg || 'Please check your order file', 'type': 'warning'})
                  }

                  that.$message({'message': 'Import successfully.', 'type': 'success'})
                }).catch(error => {
                  that.$message({'message': 'Please check your order file', 'type': 'warning'})
                })


              }
              reader.readAsArrayBuffer(file)
            }
            reader.readAsBinaryString(file)
          },
          startUpload() {
            this.$message({'message': 'Start importing, please wait.', 'type': 'warning', duration: 4000})
          },
          turnToManageProduct(data) {
            window.open('/manage/main?path=OnlineStore-MyStoreProduct&store_id=' + this.store_id + '&store_name=' + this.store_name)
          },
          submitOrders() {
            if (this.success_order_list.length <= 0) {
              this.$message({'message': 'Empty success order', 'type': 'warning'})
              return
            }
            let params = {
              list: this.success_order_list,

            }

            this.$message({message: 'Submitting now... please wait', type: 'warning'});
            submitStoreShoppingOrders(params).then(response => {
              if (response.code === 0) {
                this.$message({message: 'Submit success', type: 'success'});
                this.$router.push('/shopping/payment?order_ids=' + response.data)
              } else {
                this.$message({'message': response.msg || 'Submit failed, please contact us.', 'type': 'error'})
              }
            }).catch(error => {
              this.$message({'message': 'Submit failed, please contact us.', 'type': 'error'})
            })
          },
          viewOrderDetails(order_id) {
            window.open("/shipping/order-detail-new?order_id=" + order_id)
          },
          goToPay() {
            window.open("/manage/main?path=orderList-MyOrders")
          }

        }
    };
</script>
