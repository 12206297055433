<template>
    <div style="min-height: 800px;">
        <div class="section-about-us" style="margin-top: 100px;">
            <div class="container main_menu">
                    <el-menu
                            :default-active="currentPage"

                            :default-openeds='defaultOpeneds'
                            @select="handleSelect"
                    >
                        <el-submenu index="Account">
                            <template slot="title">
                                <i class="el-icon-user"></i>
                                <span>{{$t('page_person.account')}}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="Account-MyProfile">{{$t('page_person.user_center')}}</el-menu-item>
<!--                                <el-menu-item index="Account-Wallet">{{$t('page_person.wallet')}}</el-menu-item>-->
                            </el-menu-item-group>
                        </el-submenu>


                        <el-submenu index="orderList">
                            <template slot="title">
                                <i class="el-icon-shopping-cart-full"></i>
                                <span>{{$t('page_person.orders')}}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="orderList-MyOrders">{{$t('page_person.my_order')}}</el-menu-item>
                                <el-menu-item index="orderList-MyWarehouse">{{$t('page_person.my_warehouse')}}</el-menu-item>
                                <el-menu-item index="orderList-ShippedParcels">{{$t('page_person.my_parcel')}}</el-menu-item>
                                <el-menu-item index="orderList-AddressBook">{{$t('page_person.address_book')}}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>

<!--                        <el-submenu index="OnlineStore">-->
<!--                            <template slot="title">-->
<!--                                <i class="el-icon-shopping-bag-1"></i>-->
<!--                                <span>Online Store</span>-->
<!--                            </template>-->
<!--                            <el-menu-item-group>-->
<!--                                <el-menu-item index="OnlineStore-MyStore">My Stores</el-menu-item>-->
<!--                            </el-menu-item-group>-->
<!--                        </el-submenu>-->

                        <el-submenu index="Activity">
                            <template slot="title">
                                <i class="el-icon-data-line"></i>
                                <span>{{$t('page_person.activity')}}</span>
                            </template>
                            <el-menu-item-group>
                                <el-menu-item index="Activity-InviteFriend">{{$t('page_person.invite')}}</el-menu-item>
                                <el-menu-item index="Account-Coupons">{{$t('page_person.coupons')}}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>


                    </el-menu>

            </div>
        </div>

    </div>
</template>
<script>

    import { Button, FormGroupInput, Card } from '@/components';
    import MyOrders from '@/components/Orders/MyOrders'
    import MyWarehouse from '@/components/Orders/MyWarehouse'
    import ShippedParcels from '@/components/Orders/ShippedParcels'
    import MyProfile from '@/components/Account/MyProfile'
    import CouponList from '@/components/Account/CouponList'
    import InviteFriend from '@/components/Activity/InviteFriend'
    import AddressBook from '@/components/Orders/AddressBook'
    import MyStore from '@/components/OnlineStore/MyStore'
    import MyStoreProduct from '@/components/OnlineStore/MyStoreProduct'
    import MyStoreOrder from '@/components/OnlineStore/MyStoreOrder'
    import OrderHistory from '@/components/Orders/OrderHistory'

    export default {
        metaInfo: {
            title: 'Person',
            meta: [
                { hid: 'description', name: 'description', content: 'Person' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Person' },
                { hid: 'og:description', name: 'og:description', content: 'Person' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                defaultOpeneds: ['Account', 'orderList', 'Activity'],
                currentPage: 'Account-MyProfile',
            };
        },
        created() {
            this.currentPath()
        },
        methods: {
            currentPath() {
                const path = this.$route.query.path || 'Account-MyProfile'
                this.currentPage = path
            },
            handleSelect(key, menuPath) {
                this.currentPage = key

                //识别要不要跳转
                const path = this.$route.query.path || ''
                if ((path != null || path != '') && path != key) {
                    this.$router.push('/manage/main-mobile/' + key)
                }
            }
        }
    };
</script>
<style>
    .main_menu {
        max-width: 100%;
        margin: 2em auto;
    }
    @media screen and (min-width: 600px) {
        .main_menu {
            display: grid;
            grid-template-columns: 1fr 6fr;
            /*column-gap: 5%;*/
        }
    }

</style>
