import request_api from '@/utils/request_api'


export function orderStatusCount(data) {
  return request_api({
    url: '/api/order/order_status_count',
    method: 'post',
    data
  })
}
export function showUserRecharge(data) {
  return request_api({
    url: '/api/finance/show_user_total_recharge',
    method: 'post',
    data
  })
}
export function showUserExpenditure(data) {
  return request_api({
    url: '/api/finance/show_user_total_expenditure',
    method: 'post',
    data
  })
}
export function showUserBalance(data) {
  return request_api({
    url: '/api/finance/show_user_balance',
    method: 'post',
    data
  })
}

export function getAllCountryList(data) {
  return request_api({
    url: '/api/index/get_all_country_list',
    method: 'post',
    data
  })
}

export function getAllAreaList(data) {
  return request_api({
    url: '/api/index/get_all_area_list',
    method: 'post',
    data
  })
}

export function calculateFreight(data) {
  return request_api({
    url: '/api/index/calculate_freight',
    method: 'post',
    data
  })
}

// export function getIndexPic(data) {
//   return request_api({
//     url: '/api/index/get_index_pic',
//     method: 'post',
//     data
//   })
// }

export const getIndexPic = data => {
  return request_api({
    url: '/api/index/get_index_pic',
    method: 'POST',
    data
  })
}

export function sendFeedback(data) {
  return request_api({
    url: '/api/index/send_feedback',
    method: 'post',
    data
  })
}

export function getShoppingNews(data) {
  return request_api({
    url: '/api/index/get_shopping_news',
    method: 'post',
    data
  })
}

