<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/banner_logo_3.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">About TokCommerce</h1>
          <div class="text-center">

          </div>
        </div>
      </div>
    </div>
    <div class="guides-page">
      <div>
        <p>
          Founded in 2020, TokCommerce is a logistics start-up company that is based in ShenZhen, China.
        </p>
        <p>
          Our mission is to provide our customers with efficient and high-quality logistical solutions at an affordable price.
        </p>
        <p>
          Here on the TokCommerce website, we provide 2 main services, namely as a shopping agent for Chinese E-Commerce websites, as well as a freight forwarder from China to the World.
        </p>
      </div>
      <el-divider></el-divider>
      <div>
        <p>
          If you are looking to purchase items from the Chinese E-Commerce market from outside of China, then you have come to the right place!
        </p>
        <p>
          TokCommerce is a shopping agent for many of the major Chinese E-Commerce websites, such as Taobao, 1688, TMall and many others.
        </p>
        <p>
          As a shopping agent, we aim to provide a seamless and intuitive experience for our customers when purchasing from Chinese E-Commerce websites through our platform.
        </p>
        <p>
          We mainly help you to shop from Chinese E-Commerce websites in 3 ways.
        </p>
        <ol>
          <li>
            If you are unable to speak Chinese, we will help you to break down the language barrier so that our customers
            can enjoy shopping from Chinese E-Commerce websites. We will help you to understand the product descriptions
            and communicate with the sellers.
          </li>
          <li>
            We can provide advice on products that you wish to buy, such as the reliability of the sellers, the quality of the products, etc.
          </li>
          <li>
            If you are unable to pay in Chinese RMB, we can purchase the products for you from the Chinese E-Commerce
            website and you can pay us in your preferred currency on our platform.
          </li>
          <li>
            While most of the Chinese E-Commerce websites only ship to locations within China, we can ship straight
            to your doorstep wherever you may be in the World.
          </li>
        </ol>
        <p>
          We also provide a variety of complementary services for our customers.
        </p>
        <ul>
          <li>
            Quality checks on and pictures of your products upon their arrival at our warehouse
          </li>
          <li>
            Warehousing services for up to 30 days within our warehouse
          </li>
          <li>
            Repackaging of your products to optimize them for shipping
          </li>
          <li>
            1-to-1 customer support to tend to your needs and special requests from the moment you place your order to
            when it arrives at your doorstep
          </li>
        </ul>
        <p>
          If you would like to read more about what sets us apart from other shopping agents, be sure to check out our
          shopping agent service article <a href="/guides/guide-tokcommerce-shopping-forwarding">here.</a>
        </p>
        <p>
          You can also read more about how to shop with TokCommerce using our shopping agent service guide
          <a href="/guides/guide-taobao-tokcommerce">here.</a>
        </p>
      </div>
      <el-divider></el-divider>
      <div>
        <p>
          Since most of the Chinese E-Commerce websites do not ship to locations outside China, you can also use
          TokCommerce as a freight forwarder to ship to wherever you may be in the World.
        </p>
        <p>
          All you have to do is to send the products you wish to ship to our TokCommerce warehouse based in ShenZhen,
          China, submit a freight forwarding order, and we will take care of the rest for you.
        </p>
        <p>
          As a freight forwarder, we aim to provide quick and efficient shipping by working with trusted and reliable
          shipping partners such as FedEx, UPS, DHL, etc., while keeping our shipping costs low with some of the lowest
          service fees in the industry.
        </p>
        <p>
          Similar to our shopping agent service, we also provide warehousing, repackaging and professional customer support
          services, all free-of-charge, if you are using our freight forwarding service
        </p>
        <p>
          You can find out more about how to ship with TokCommerce from our freight forwarding service guide
          <a href="/guides/guide-freight-forwarding">here.</a>
        </p>
      </div>
    </div>


    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Customer Reviews</h2>
        <div class="guides-card-row" style="margin-top: 100px;">
          <el-card shadow="always" class="tag-card">
            <b style="font-size: 25px">Nguyen</b><br><br>
            <p>
              I purchased a lot of things on Taobao with the help of TokCommerce at the end of March.
              And surprisingly the service they've provided has been spontaneous and beyond my expectations.
              Despite being a small company, they were very attentive and took good care of their consumers.
              Shipping was quite fast as well, only for it to arrive within a month. Overall,
              I am very satisfied with choosing TokCommerce and I hope to be able to rely on them again in the future.
              Thank you.
            </p>
          </el-card>
          <el-card shadow="always" class="tag-card">
            <b style="font-size: 25px">Kenneth</b><br><br>
            <p>
              I run two shopify stores, one for clothing and one for jewelry.
              I have been in business for 4 years. In the past, all my products were purchased from Chinese sellers,
              and I didn't have a good platform to manage my orders, which greatly increased the difficulty of my work.
              My business has become easier since using TokCommerce.
            </p>
          </el-card>
          <el-card shadow="always" class="tag-card">
            <b style="font-size: 25px">David</b><br><br>
            <p>
              I am an electronic product enthusiast, I like to buy electronic products from Taobao,
              and then assemble them into products I like. I have used other platforms in the past year,
              but the shipping price is very expensive and I can't get a good one-to-one service.
              TokCommerce has a very professional customer service that can solve almost all problems for me.
              I love custom items and TokCommerce does a lot of the work for me.
            </p>
          </el-card>
          <el-card shadow="always" class="tag-card">
            <b style="font-size: 25px">Angela - Hanfu</b><br><br>
            <p>
              I like to buy Hanfu from Taobao, it is very cheap.
              I have been wearing Hanfu for 6 years.
              I have a hard time getting a style I like in the US. But on Taobao,
              I can find a lot of styles that I like. TokCommerce helped me buy Hanfu from Taobao and shipped it from China to the US,
              which saved me a lot of time and money. I absolutely love TokCommerce's service.
            </p>
          </el-card>
        </div>
      </div>
    </div>

    <div style="text-align: center;">
      <img v-lazy="'/img/services/about-us.jpeg'" alt="Taobao agent" style="width: 80%;" />
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Contact Us</h2>
        <div style="text-align: left;">
          <h4 style="margin: auto;">Customer Service Email: service@tokcommerce.com</h4>
<!--          <h4 style="margin: auto;">Customer Service Hotline: +86 13543326160</h4>-->
          <h4 style="margin: auto;">Company Name: ShenZhen AnKeHao Technology</h4>
          <h4 style="margin: auto;">Company Address: 1406, Huichao Science and Technology Building, No. 12, Jinhai Road, Xixiang street, Bao'an District, Shenzhen City, Guangdong Province, China</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  metaInfo: {
    title: 'About us - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'About us' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'About us' },
      { hid: 'og:description', name: 'og:description', content: 'About us' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
    ],
  },
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style>
.guides-page{
  margin: 30px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 30px 25%;
  }
}
</style>
