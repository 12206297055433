<template>
    <div>
        <div class="page-header page-header-small" style="min-height: 330px;">
            <div class="content-center">
                <div class="container">
                    <h1 class="title">{{$t('page_message.title')}}</h1>
                    <div class="text-center">
                        <p>
                            {{$t('page_message.des')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section section-about-us" style="min-height: 800px;">
            <div class="container">
                <el-collapse v-if="list.length" accordion @change="readMessage">
                    <el-collapse-item v-for="item in list" :key="item.id" :name="item.id">
                        <template slot="title">
                            <i v-if="!item.is_read" class="header-icon el-icon-warning-outline"
                               style="margin-left: 15px;"></i>
                            <span v-if="item.is_read" style="margin-left: 28px;">{{item.title}} | {{item.date}}</span>
                            <span v-else>{{item.title}} | {{item.date}}</span>
                        </template>
                        <div style="padding: 20px;">
                            <vue-markdown :source="item.message" class="guides-page"></vue-markdown>
                        </div>
                    </el-collapse-item>
                </el-collapse>

                <el-empty v-else description="No message for you.">

                </el-empty>
            </div>
        </div>

    </div>
</template>

<script>
    import { getNotificationList, readMessage } from '@/api/user'
    import VueMarkdown from "vue-markdown";

    export default {
        metaInfo: {
            title: 'Message Center',
            meta: [
                { hid: 'description', name: 'description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Message Center' },
                { hid: 'og:description', name: 'og:description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {
            VueMarkdown,
        },
        data() {
            return {
                list: [],
            }
        },
        created() {
            this.getMessageList()
        },
        methods: {
            getMessageList() {
                this.$message({message: 'Loading now...', type: 'warning'});
                getNotificationList().then(response => {
                    if (response.code === 0) {
                        this.list = response.data.list || []
                    }
                })
            },
            readMessage(message_id) {
                if (message_id) {
                    readMessage({ids: [message_id]}).then(response => {
                        if (response.code === 0) {
                         for (let i in this.list) {
                             if (this.list[i].id == message_id) {
                                 this.list[i].is_read = 1
                                 return;
                             }
                         }
                        }
                    })
                }
            },
        },
    };
</script>


<style></style>
