<template>
  <div style="margin-top: 75px;">
<!--    <el-alert-->
<!--            title="Manage your addresses here, addresses can be reused"-->
<!--            type="warning">-->
<!--    </el-alert>-->
    <el-button type="success" @click="addNewAddressButtonClick" style="margin-top: 30px">Add new address</el-button>
    <div>
      <el-card
          v-for="address in address_list"
          :key="address.id"
          style="width: 270px;height: 250px;display: inline-grid;margin: 10px;">
        <AddressCard
            :address="address"
        ></AddressCard>
      </el-card>
    </div>
    <el-pagination
        style="margin-top: 30px;"
        layout="prev, pager, next"
        :total="total_count"
        :current-page="current_page"
        @current-change="changePage"
    >
    </el-pagination>
    <el-dialog
        title="Add New Address"
        :visible.sync="add_address_visible"
        width="80%"
    >
      <el-form
          :inline="false"
          :model="address_form"
          ref="address_form"
          class="demo-form-inline"
          label-position="top"
      >
        <el-row class="order-form-row">
          <el-col :span="11">
            <el-form-item label="Consignee Name" required prop="user_name" >
              <el-input v-model="address_form.user_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Country" required prop="country_code">
              <el-select v-model="address_form.country_code" filterable placeholder="Select Destination Country" style="width: 100%;">
                <el-option
                    v-for="country in country_list"
                    :key="country.country_code"
                    :value="country.country_code"
                    :label="country.en_name + ' - ' + country.cn_name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="order-form-row">
          <el-col :span="11">
            <el-form-item label="State/Province" required prop="province" >
              <el-input v-model="address_form.province"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Consignee City" required prop="city" >
              <el-input v-model="address_form.city"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="order-form-row">
          <el-col :span="11">
            <el-form-item label="Address Detail" required prop="address_detail" >
              <el-input v-model="address_form.address_detail"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Consignee Postcode" required prop="postal_code" >
              <el-input v-model="address_form.postal_code"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="order-form-row">
          <el-col :span="11">
            <el-form-item label="Consignee Phone" required prop="phone" >
              <el-input v-model="address_form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Consignee Email" prop="email" >
              <el-input v-model="address_form.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div style="display: flex;justify-content: center;margin-top: 30px;">
          <el-button size="small" plain type="success" @click="addNewAddress('address_form')" style="margin: auto;">Add Address</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>


import {addAddress, getAddressList, getCountryList} from "../../api/user";
import AddressCard from "../../pages/components/AddressCard";

    export default {
        name: 'AddressBook',
        components: {
          AddressCard
        },
        props: {

        },
        data() {
            return {
              add_address_visible: false,
              address_form: {
                country_code: '',
                user_name: '',
                province: '',
                city: '',
                address_detail: '',
                postal_code: '',
                phone: '',
                email: '',//邮件使用客户的邮箱即可
                default: 0
              },
              country_list: [],
              address_list: [],
              total_count: 0,
              current_page: 1,
              address_list_params: {}
            };
        },
        created() {
          this.getAddressList()
        },
        methods: {
          getCountrys() {
            getCountryList().then(res => {
              this.country_list = res.data || []
            })
          },
          addNewAddressButtonClick(){
            this.getCountrys()
            this.add_address_visible = true
          },
          addNewAddress(form_name){
            this.$message({message: 'Adding new address...', type: 'warning'})
            this.$refs[form_name].validate((valid) => {
              if (!valid) {
                this.$message({message: 'Please check your input', type: 'warning'});
                return
              }
              addAddress(this.address_form).then(res => {
                if (res.code === 0){
                  this.$message({message: 'New address added', type: 'success'});
                  this.add_address_visible = false
                  this.getAddressList()
                } else {
                  this.$message({message: 'Failed to add new address', type: 'error'});
                }
              })
            })
          },
          getAddressList(){
            let params = this.address_list_params
            params.page = this.current_page
            params.page_size = 9

            this.$message({message: 'Fetching your addresses', type: 'warning'});
            getAddressList(params).then(res => {
              if (res.code === 0){
                this.$message({message: 'Addresses fetched successfully', type: 'success'});
                this.address_list = res.data.list || []
                this.total_count = res.data.total || 0
              } else {
                this.$message({message: 'Failed to fetch addresses', type: 'error'});
              }
            })
          },
          changePage(page) {
            this.current_page = page
            this.getAddressList();
          },
        }
    };
</script>
