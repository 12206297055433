<template>
  <div>

    <div class="page-header page-header-small">
      <parallax
              class="page-header-image"
              style="background-image:url('/img/services/shipping_calculator.png')"
      >
      </parallax>
      <div class="content-center" style="margin-top: 0px;">
        <div class="container">
          <h1 class="title title-for-mobile">{{$t('shipping_calculator')}}</h1>
          <h4>{{$t('page_calculator.des')}}</h4>

          <p></p>
        </div>
      </div>
    </div>

    <div class="section-about-us" style="margin-top: 100px;">
      <div class="container">



        <div id="calculator" style="margin-top: 30px;">
          <div class="request-ship-order">

            <el-form :inline="false" :model="submit_form" ref="submit_form" class="demo-form-inline" label-position="top">
              <el-row class="order-form-row">
                <el-col :span="11">
                  <el-form-item :label="$t('page_forwarding.country_name')" required prop="country_code" >
                    <el-select v-model="submit_form.country_code" filterable :placeholder="$t('page_forwarding.country_name')" style="width: 100%;">
                      <el-option
                              v-for="country in country_list"
                              :key="country.en_name"
                              :value="country.area_id"
                              :label="country.en_name + ' - ' + country.cn_name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item :label="$t('page_calculator.item_category')" required prop="goods_type" >
                    <el-select v-model="submit_form.goods_type" :placeholder="$t('page_calculator.item_category')" style="width: 100%;">
                      <el-option
                              v-for="warehouse in goods_type_list"
                              :key="warehouse.value"
                              :value="warehouse.value"
                              :label="warehouse.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row class="order-form-row">
                <el-col :span="5">
                  <el-form-item :label="$t('page_calculator.product_weight') + '/KG'" required prop="package_weight">
                    <el-input v-model="submit_form.package_weight" placeholder="KG" :min="0" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item :label="$t('page_calculator.product_length') + '/CM'" prop="package_length">
                    <el-input v-model="submit_form.package_length" placeholder="CM" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item :label="$t('page_calculator.product_width') + '/CM'" prop="package_width">
                    <el-input v-model="submit_form.package_width" placeholder="CM" type="number"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item :label="$t('page_calculator.product_height') + '/CM'" prop="package_height" >
                    <el-input v-model="submit_form.package_height" placeholder="CM" type="number"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row style="text-align: center;margin-top: 30px;">
                <el-form-item>
                  <el-button type="success" plain @click="getShipChannels('submit_form')">{{$t('page_calculator.check')}}</el-button>
                </el-form-item>
              </el-row>

              <el-alert
                      style="max-width: 90%;margin: auto;"
                      :title="$t('page_calculator.cost_note')"
                      type="error">
              </el-alert>

            </el-form>
          </div>

          <div v-if="product_channels.length !== 0" class="freight-price-table" style="margin-top: 50px;">
            <el-table
                :data="product_channels"
                style="width: 100%">
              <el-table-column
                  :label="$t('page_calculator.shipping_method')"
                  :width="table_column_width">
                <template slot-scope="scope">
                  <div style="text-align: center;">
                    <img :src="scope.row.logo" width="50px;"> <br>
                    <strong>{{scope.row.channel_name}}</strong>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                      prop="channel_days"
                      :label="$t('page_calculator.delivery_time')"
                      :width="table_column_width"></el-table-column>
              <el-table-column
                      prop="warehouse"
                      :label="$t('page_calculator.warehouse')"
                      :width="table_column_width"></el-table-column>
              <el-table-column
                      :label="$t('page_calculator.product_weight')"
                      :width="table_column_width">
                <template>
                  <el-tooltip class="item" effect="light" placement="top-start">
                    <div slot="content">
                      How is the weight calculated? <br>
                      If the volume weight is larger than the actual weight, take the volume weight. <br>
                      If the actual weight is larger than the volume weight, take the actual weight.<br>
                      (volume weight = parcel length * parcel height * parcel width / 5000)
                    </div>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                      :label="$t('page_calculator.estimated_cost') + '(USD)'"
                      :width="table_column_width">
                <template slot-scope="scope">
                  <p>{{scope.row.freight_price}} USD</p>
                  <p>{{scope.row.freight_price_cny}} CNY</p>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <shipping-line></shipping-line>

          <el-row style="display:flex;justify-content: space-between;margin: 50px 10%;">
            <el-col style="text-align: center">
              <div class="source-info-container__info-title">
                <h3>{{$t('page_calculator.service_title')}}</h3>
              </div>
              <div class="source-info-container__info-description">
                <p>
                  {{$t('page_calculator.about_service')}}
                </p>
              </div>
            </el-col>
          </el-row>

        </div>

      </div>
    </div>
  </div>

</template>


<script>
  import {getCountryList} from '@/api/user'
  import {checkShipFreight} from '@/api/service'
  import ShippingLine from '@/components/ShippingLine'

  export default {
    name: 'ShipServiceCalculate',
    metaInfo() {
      return {
        title: this.$t('meta.calculator_title'),
        meta: [
          { hid: 'description', name: 'description', content: this.$t('meta.calculator_des') },
          { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
          { hid: 'og:type', name: 'og:type', content: 'website' },
          { hid: 'og:title', name: 'og:title', content: this.$t('meta.calculator_title') },
          { hid: 'og:description', name: 'og:description', content: this.$t('meta.calculator_des') },
          { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
          { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/service/shipping-calculate' },
        ],
      }
    },
    components: {
      ShippingLine,
    },
    data() {
      return {
        dialogCardVisible: false,
        activeName: 'Ordinary goods',
        country_list: [],
        goods_type_list: [
          {
            'name': this.$t('page_calculator.common_goods'),
            'value': '0',
          },
          {
            'name': this.$t('page_calculator.brand_goods'),
            'value': '4',
          },
          {
            'name': this.$t('page_calculator.liquid_goods'),
            'value': '3',
          },
          {
            'name': this.$t('page_calculator.battery_goods'),
            'value': '5',
          }
        ],
        submit_form: {
          country_code: '',
          package_weight: '',
          package_length: '0',
          package_width: '0',
          package_height: '0',
          goods_type: '',
        },
        total_cost: 0,
        product_channels: [],
        current_step: 0,
        table_column_width: '200px',
      }
    },
    created() {
      this.getCountrys()
    },
    methods: {
      getCountrys() {
        getCountryList().then(res => {
          this.country_list = res.data || []
        })
      },
      getShipChannels(form_name) {
        this.$refs[form_name].validate((valid) => {
          if (!valid) {
            this.$message({message: 'Please check that all required fields are filled up', type: 'error'});
            return false
          }
          //这里只是拿到运费试算而已
          this.$message({message: 'Checking now, please wait.', type: 'warning'});
          this.product_channels = []
          checkShipFreight(this.submit_form).then(response => {
            if (response.code === 0) {
              this.product_channels = response.data || []
              if (response.data.length == 0) {
                this.$message({message: 'Please try again...', type: 'error'});
              }
            } else {
              this.$message({message: 'Please try again...', type: 'error'});
              this.product_channels = []
            }
          })
        })
      },
      changeStep(event) {
        this.current_step = event
      },
    },
  };
</script>

<style>
.for-desktop{
  display: none;
}
.for-mobile{
  display: block;
}
@media (min-width: 480px) {
  .for-desktop{
    display: block;
  }
  .for-mobile{
    display: none;
  }
}
</style>
