<template>
    <div class="page-header clear-filter">
        <div
                class="page-header-image"
                style="background-image: url('/img/login/login-bg.png')"
        ></div>

        <div class="content">
            <div class="container">
                <div class="col-md-5 ml-auto mr-auto" style="margin-top: 100px;">
                    <div
                            class="section section-signup"
                            style="background: none;padding-top: 50px;"
                    >
                        <div class="container">
                            <div class="row">
                                <card class="card-signup" header-classes="text-center" color="orange">
                                    <template slot="header">
                                        <h3 class="card-title title-up">Sign in</h3>
                                    </template>
                                    <fg-input
                                            class="no-border input-lg"
                                            addon-left-icon="now-ui-icons users_circle-08"
                                            placeholder="Email..."
                                            v-model="login_form.email"
                                    >
                                    </fg-input>

                                    <fg-input
                                            class="no-border input-lg"
                                            addon-left-icon="now-ui-icons text_caps-small"
                                            placeholder="Password..."
                                            type="password"
                                            v-model="login_form.password"
                                    >
                                    </fg-input>

                                    <template slot="raw-content">


                                        <div style="color: #ffffff;">
                                            <GoogleLogin
                                                    :params="googleSignInParams"
                                                    :renderParams="googleRenderParams"
                                                    :onSuccess="googleOnSignInSuccess"
                                                    :onFailure="googleOnSignInError">
                                            </GoogleLogin><br>
                                            <!--                                <facebook-login-->
                                            <!--                                        style=""-->
                                            <!--                                        class="button"-->
                                            <!--                                        appId="1092107274927967"-->
                                            <!--                                        loginLabel="Signed in"-->
                                            <!--                                        :loginOptions="fbBtn"-->
                                            <!--                                        @login="facebookOnSignInSuccess">-->
                                            <!--                                </facebook-login>-->
                                        </div>

                                        <div class="">
                                            <n-button type="neutral" round size="lg" @click="Login">Sign In</n-button>
                                        </div>

                                        <div class="col text-center">
                                            <router-link
                                                    to="/register"
                                                    class="btn btn-simple btn-round btn-white btn-lg"
                                            >
                                                Sign up
                                            </router-link>
                                        </div>

                                    </template>

                                </card>

                                <img :src="'/img/login/singupbonus.png'" alt="TokCommerce: sign up bonus" style="margin-top: 10px;" class="rounded" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import {Card, Button, FormGroupInput} from '@/components';
    import { login, googleLoginCheck, facebookLoginCheck, resetLink } from '@/api/user';
    import { setToken, setAccountName, getToken, setTmpAccountNo, getTmpAccountNo, getInvite} from '@/utils/auth';
    import GoogleLogin from 'vue-google-login';
    import facebookLogin from 'facebook-login-vuejs';

    export default {
        metaInfo: {
            title: 'Login Page - TokCommerce',
            meta: [
                { hid: 'description', name: 'description', content: 'Login Page' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Login Page' },
                { hid: 'og:description', name: 'og:description', content: 'Login Page' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        name: 'login-page',
        bodyClass: 'login-page',
        components: {
            Card,
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            GoogleLogin,
            // facebookLogin,
        },
        data() {
            return {
                show_register_sb_btn: true,
                register_form: {
                    email: '',
                    username: '',
                    password: '',
                    confirmPass: '',
                },
                login_form: {
                    email: '',
                    password: '',
                },
                error: {
                    register: '',
                    login: '',
                },
                googleSignInParams: {
                    client_id: '656853918556-4j6g8n6d5r2bebh6e14asl6m8p12sebg.apps.googleusercontent.com',// do not change please
                },
                googleRenderParams: {
                    width: 130,
                    height: 37,
                    longtitle: false
                },
                fbSignInParams: {
                    scope: 'email,user_likes',
                    return_scopes: true
                },
                fbBtn: {scope: 'email'},
                timer: 40000,
                forget_password_form: {
                    email: ''
                },
                forget_password_loading: false,
                buy_notice_times: 0,
                buy_notice_list: [],
            }
        },
        methods: {
            Login() {
                let params = {
                    account_no: this.login_form.email,
                    pass: this.login_form.password
                }
                this.$message({message: 'Login Now,please wait', type: 'success'});
                login(params).then(res => {
                    if (res.code === 0) {
                        let account_name = res.data['account_name']

                        this.loginOrRegisterTmp(res.data['token'], account_name, res.data['account_no'])

                        this.$message({message: 'Login Success', type: 'success'});
                        //登录成功后要干嘛？
                        this.$root.isLogin = true

                        this.$router.push('/')
                    } else {

                        this.$message({message: res.msg || 'Login failed', type: 'error'});
                    }
                })
            },
            requestReset(){
                let params = {
                    email: this.login_form.email
                }
                resetLink(params).then(response => {
                    if (response.code === 0) {
                        this.$message({message: 'Please check your email for the procedures to reset your password', type: 'success'});
                    } else {
                        this.$message({message: response.msg || 'Login failed', type: 'error'});
                    }
                })
            },
            loginOrRegisterTmp(token, account_name, tmp_account) {
                setToken(token);
                setAccountName(account_name);
                setTmpAccountNo(tmp_account)
            },
            googleOnSignInSuccess(data) {
                googleLoginCheck({id_token: data, invite_code: getInvite()}).then(response => {
                    if (response.code === 0) {
                        let account_name = response.data['account_name']
                        // 弹出通知框提示登录成功信息
                        this.loginOrRegisterTmp(response.data['token'], account_name, account_name)
                        this.$message({message: 'Login Successfully', type: 'success'});
                        this.$root.isLogin = true

                        this.$router.push('/')
                    } else {
                        this.$message({message: 'Login failed', type: 'error'});
                    }
                })
            },
            googleOnSignInError(error) {
                this.$message({message: 'Login failed', type: 'error'});
            },
            facebookOnSignInSuccess (response) {
                FB.api('/me?fields=id,name,gender,first_name,last_name,email', dude => {
                    facebookLoginCheck({id_token: dude, invite_code: getInvite()}).then(response => {
                        if (response.code === 0) {
                            // 隐藏登录组件
                            this.$root.isLogin = true
                            let account_name = response.data['account_name']
                            this.loginOrRegisterTmp(response.data['token'], account_name, account_name)
                            this.$message({message: 'Login successfully', type: 'success'});

                            this.$router.push('/')
                        } else {
                            this.$message({message: 'Login failed', type: 'error'});
                        }
                    })
                })
            },
            facebookOnSignInError (error) {
                //登录失败回调
                this.$message({message: 'Login failed', type: 'error'});
            },
        },
    };
</script>
<style></style>
