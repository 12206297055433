<template>
  <div class="section section-examples" data-background-color="black">
    <div class="space-50"></div>
    <div class="container text-center">
      <h2>{{$t('page_home.video_title')}}</h2>
      <div class="for-desktop">
        <div class="row">
          <div class="col">
            <iframe
                title="How to buy from Taobao with TokCommerce"
                width='100%'
                height='270'
                :src="shopping_agent"
                :allowfullscreen="true"
            ></iframe>
          </div>
          <div class="col">
            <iframe
                title="Forwarding from China"
                width='100%'
                height='270'
                :src="forwarding"
                :allowfullscreen="true"
            ></iframe>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <iframe
                    title="How to manage your orders in TokCommerce Account"
                    width='100%'
                    height='270'
                    :src="order_manage"
                    :allowfullscreen="true"
            ></iframe>
          </div>
          <div class="col">
            <iframe
                    title="Why TokCommerce"
                    width='100%'
                    height='270'
                    :src="why_tokcommerce"
                    :allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="for-mobile">
        <div class="col">
          <div class="row">
            <iframe
                    title="How to buy from Taobao with TokCommerce"
                    width='100%'
                    height='270'
                    :src="shopping_agent"
                    :allowfullscreen="true"
            ></iframe>
          </div>
          <div class="row" style="margin-top: 20px">
            <iframe
                    title="Forwarding from China"
                    width='100%'
                    height='270'
                    :src="forwarding"
                    :allowfullscreen="true"
            ></iframe>
          </div>
          <div class="row" style="margin-top: 20px;">
            <iframe
                    title="How to manage your orders in TokCommerce Account"
                    width='100%'
                    height='270'
                    :src="order_manage"
                    :allowfullscreen="true"
            ></iframe>
          </div>
          <div class="row" style="margin-top: 20px">
            <iframe
                    title="Why TokCommerce"
                    width='100%'
                    height='270'
                    :src="why_tokcommerce"
                    :allowfullscreen="true"
            ></iframe>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      why_tokcommerce: '',
      shopping_agent: '',
      forwarding: '',
      order_manage: '',
    }
  },
  created() {
    this.why_tokcommerce = this.loadVideoURL('https://www.youtube.com/watch?v=ZK4m598K9oI')
    this.shopping_agent = this.loadVideoURL('https://www.youtube.com/watch?v=kF4xs6BK1kk')
    this.forwarding = this.loadVideoURL('https://www.youtube.com/watch?v=Yq-DvvBvA5I')
    this.order_manage = this.loadVideoURL('https://www.youtube.com/watch?v=tO9itJF4oqQ')
  },
  methods: {
    loadVideoURL (youtube_url) {
      const youtubeURL = youtube_url
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const url = youtubeURL.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      const YId = url[2] !== undefined ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
      const embededYouTubeVideo = youtubeEmbedTemplate.concat(YId)
      return  embededYouTubeVideo
    },
  },
};
</script>
<style>
.for-desktop{
  display: none;
}
.for-mobile{
  display: block;
}
@media (min-width: 480px){
  .for-desktop{
    display: block;
  }
  .for-mobile{
    display: none;
  }
}
</style>
