<template>
    <div>
        <div class="section section-about-us" style="margin-top: 80px;min-height: 1000px;">

            <h2 style="text-align: center;">{{$t('page_cart.title')}}</h2>

            <div class="container" style="margin-top: 30px;">

                <el-table
                        empty-text="Your cart is empty"
                        ref="multipleTable"
                        :data="cart_list"
                        tooltip-effect="dark"
                        style="width: 100%"
                        v-loading="loading"
                        @selection-change="handleSelectionChange">
                    <el-table-column
                            type="selection">
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.item')"
                            width="100px;">
                        <template slot-scope="scope">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="scope.row.product_image_url">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.product')"
                            width="180px">
                        <template slot-scope="scope">
                            <p>{{scope.row.product_name}}</p>
                            <p v-if="scope.row.product_link"><a :href="scope.row.product_link" target="_blank">View Product</a></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.price')">
                        <template slot-scope="scope">
                            CNY {{scope.row.product_sale_price_cny}}<br>
                            USD {{scope.row.product_sale_price_usd}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.quantity')"
                            width="150px;">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.quantity" disabled @change="updateCart(scope.row.id, scope.row.quantity)" size="mini" :min="1" :max="5000" label="Quantity" @keydown.native="channelInputLimit"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.freight')">
                        <template slot-scope="scope">
                            CNY {{scope.row.product_freight_amount_cny}}<br>
                            USD {{scope.row.product_freight_amount_usd}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.total')">
                        <template slot-scope="scope">
                            CNY {{scope.row.total_amount_cny}}<br>
                            USD {{scope.row.total_amount_usd}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('page_cart.info')"
                            width="150px"
                    >
                        <template slot-scope="scope">
                            <p>Details: {{scope.row.sku_code_note}}</p>
                            <p>Remark: {{scope.row.product_remark}}</p>
                        </template>
                    </el-table-column>

                    <el-table-column
                            :label="$t('page_cart.operate')"
                            width="100px;"

                    >
                        <template slot-scope="scope">
                            <el-popconfirm
                                    confirm-button-text='Yes'
                                    cancel-button-text='No'
                                    title="Confirm delete this item？"
                                    @confirm="updateCart(scope.row.id, -10000)"
                            >
                                <el-button type="danger" round size="small" slot="reference">Delete</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <div style="height: 50px;"></div>

                <card class="text-right" v-if="cart_list.length > 0">
                    <p class="card-text">{{$t('page_cart.quantity')}}: {{select_nums}}</p>
                    <el-popconfirm
                            :confirm-button-text="$t('page_cart.yes')"
                            :cancel-button-text="$t('page_cart.no')"
                            :title="$t('page_cart.confirm_buy')"
                            @confirm="submitOrders()"
                    >
                        <el-button type="success" size="small" slot="reference">{{$t('page_cart.pay_now')}}</el-button>
                    </el-popconfirm>
                </card>
            </div>
        </div>

    </div>
</template>
<script>
    import { Button, FormGroupInput, Card } from '@/components';

    import { getMyCartList, cartNumUpdate } from "@/api/shopping";

    export default {
        name: 'shopping-cart',
        bodyClass: 'landing-page',
        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput,
            [Card.name]: Card,
        },
        data() {
            return {
                cart_list: [],
                cart_nums: 0,
                multipleSelection: [],//被选中的全部数据
                select_nums: 0,
                checkout_total_cny: 0,
                checkout_total_usd: 0,
                loading: true,
            };
        },
        created() {
            this.getCartList()
        },
        methods: {
            handleSelectionChange(values) {
                this.multipleSelection = values;

                let select_nums = 0;
                let checkout_total_cny = 0
                let checkout_total_usd = 0
                for (let i in values) {
                    select_nums += values[i].quantity
                    checkout_total_cny += parseFloat(values[i].total_amount_cny)
                    checkout_total_usd += parseFloat(values[i].total_amount_usd)
                }
                this.select_nums = select_nums
                this.checkout_total_cny = parseFloat(checkout_total_cny).toFixed(2)
                this.checkout_total_usd = parseFloat(checkout_total_usd).toFixed(2)
            },
            getCartList() {
                getMyCartList().then(response => {
                    if (response.code === 0) {
                        this.cart_list = response.data.list || []
                        this.cart_nums = response.data.cart_nums || 0

                        this.$root.cart_num = this.cart_nums
                    }

                    this.loading = false
                })
            },
            submitOrders() {
                //做些基础校验
                if (this.multipleSelection.length <= 0) {
                    this.$message({message: 'Please select items', type: 'error'});
                    return
                }

                //取出cart_ids
                let cart_ids = []
                for (let i in this.multipleSelection) {
                    cart_ids.push(this.multipleSelection[i].id)
                }

                this.$router.push('/shopping/settlement?cart_ids=' + cart_ids)
            },
            channelInputLimit(e) {
                e.returnValue = ''
            },
            updateCart(id, num) {
                let params = {
                    cart_id: id,
                    quantity: num,
                }

                this.$message({message: 'Update now, please wait!', type: 'warning'});
                cartNumUpdate(params).then(response => {
                    if (response.code === 0) {
                        this.$message({message: 'Update success', type: 'success'});
                        this.getCartList()
                    } else {
                        this.$message({message: response.msg || 'Update failed', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Update failed', type: 'error'});
                })
            },
        }
    };
</script>
<style></style>
