<template>
    <navbar
            position="fixed"
            type="primary"
            :transparent="transparent"
            :color-on-scroll="colorOnScroll"
            menu-classes="ml-auto"
            style="background-color: white; color: black;top: 30px;"
    >
        <template>
            <router-link style="text-decoration: none;" to="/">
                <img :src="'/img/logo.png'" alt="TokCommerce: Taobao agent" style="width: 200px;" class="rounded" />
            </router-link>
        </template>
        <template slot="navbar-menu">

            <li class="nav-item">
                <a
                        class="nav-link"
                        href="/search"
                >
                    <i class="now-ui-icons shopping_bag-16"></i>
                    <p>{{$t('shopping_agent')}}</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                        class="nav-link"
                        href="/service/forwarding"
                >
                    <i class="now-ui-icons files_box"></i>
                    <p>{{$t('forwarding')}}</p>
                </a>
            </li>

            <drop-down
                    tag="li"
                    :title="$t('services')"
                    icon="now-ui-icons business_briefcase-24"
                    class="nav-item"
            >
                <nav-link to="/service/source">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('expert_buy')}}
                </nav-link>
<!--                <nav-link to="/service/shipping-calculate">-->
<!--                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('shipping_calculator')}}-->
<!--                </nav-link>-->
                <nav-link to="/service/customer-review">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('customer_review')}}
                </nav-link>
                <nav-link to="/service/dropshipping">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('dropshipping')}}
                </nav-link>
                <nav-link to="/service/business-service">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('business_service')}}
                </nav-link>
                <nav-link to="/service/trust-seller">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('trusted_seller')}}
                </nav-link>
                <nav-link to="/blog/list">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('blog')}}
                </nav-link>
            </drop-down>

            <li class="nav-item">
                <a
                        class="nav-link"
                        href="/about-us"
                >
                    <i class="now-ui-icons education_paper"></i>
                    <p>{{$t('about')}}</p>
                </a>
            </li>

            <li class="nav-item">
                <a
                        class="nav-link"
                        href="/message/list"
                >
                    <i class="now-ui-icons ui-1_bell-53"></i>

                    <badge type="warning">{{this.$root.notice_num}}</badge>
                </a>
            </li>

            <li class="nav-item">
                <a
                        class="nav-link"
                        href="/shopping/my-cart"
                >
                    <i class="now-ui-icons shopping_cart-simple"></i>
                    <badge type="success">{{this.$root.cart_num}}</badge>
                </a>
            </li>

            <drop-down
                    v-if="$root.isLogin"
                    tag="li"
                    :title="$t('account')"
                    icon="now-ui-icons users_single-02"
                    class="nav-item"
            >
                <nav-link to="/manage/main?path=Account-MyProfile">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('user_center')}}
                </nav-link>
                <nav-link to="/manage/main?path=orderList-MyOrders">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('my_order')}}
                </nav-link>
                <nav-link to="/manage/main?path=OnlineStore-MyStore">
                    <i class="now-ui-icons business_chart-pie-36"></i> {{$t('my_store')}}
                </nav-link>

                <nav-link @click.stop="">
                    <n-button type="primary" @click="logout" link>
                        <i class="now-ui-icons business_chart-pie-36"></i> {{$t('log_out')}}
                    </n-button>
                </nav-link>
            </drop-down>
            <li class="nav-item" v-else>
                <n-button type="primary" round size="sm" @click="turnToSignIn()">{{$t('sign_in')}}</n-button>
            </li>

        </template>
    </navbar>
</template>

<script>
    import {DropDown, Navbar, NavLink, Badge, Button} from '@/components';
    import FormGroupInput from '../components/Inputs/formGroupInput';
    import {Popover} from 'element-ui';
    import {removeAccountName, removeToken, removeTEMPACCOUNTNO, getToken} from '@/utils/auth'
    import { getMyCartNums } from '@/api/shopping'
    import { getNotificationNum } from '@/api/user'
    import { checkIsMobile } from '@/utils/screen'

    export default {
        name: 'main-navbar',
        props: {
            transparent: Boolean,
            colorOnScroll: Number
        },
        components: {
            DropDown,
            [Button.name]: Button,
            Navbar,
            NavLink,
            [Popover.name]: Popover,
            [Badge.name]: Badge,
            [FormGroupInput.name]: FormGroupInput,
        },
        data() {
            return {

            }
        },
        created() {
            this.getCartNums()
            this.getNoticeNum()

            this.$root.isLogin = (getToken() || '') ? true : false
        },
        methods: {
            logout() {
                removeToken()
                removeAccountName()
                removeTEMPACCOUNTNO()

                this.$message({message: 'Logout Success', type: 'success'});
                this.$root.cart_num = 0
                this.$root.notice_num = 0
                this.$root.isLogin = false

                if (this.$route.path != '/') {
                    this.$router.push('/')
                }
            },
            getCartNums() {
                const token = getToken() || ''
                if (token) {
                    getMyCartNums().then(response => {
                        if (response.code === 0) {
                            this.$root.cart_num = response.data || 0
                        }
                    })
                }
            },
            turnToSignIn() {
                window.open('/login')
            },
            getNoticeNum() {
                const token = getToken() || ''
                if (!token) {
                    return
                }
                getNotificationNum().then(response => {
                    if (response.code === 0) {
                        this.$root.notice_num = response.data.num || 0
                    }
                })
            },
            checkIsMobile() {
                return checkIsMobile()
            },
        }
    };
</script>

<style scoped>

</style>
