<template>
  <div class="section section-nucleo-icons" style="padding-top: 0px; margin-top: -150px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">{{$t('page_home.who_we_are_title')}}</h2>
          <div class="description" style="color: black;">
            <h4>
              {{$t('page_home.who_we_are_line1')}}
            </h4>
            <h4>
              {{$t('page_home.who_we_are_line2')}}
            </h4>
          </div>
          <!-- <div class="nucleo-container">
                    <img v-lazy="'assetsimg/nucleo.svg'" alt="">
                </div> -->
          <a
            href="/search"
            class="btn btn-primary btn-round btn-lg"
            style="margin-right: 20px"
            >{{$t('shopping_agent')}}</a
          >
          <a
            href="/service/forwarding"
            class="btn btn-primary btn-simple btn-round btn-lg"
            >{{$t('forwarding')}}</a
          >
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="icons-container">
            <img :src="'/img/ecommerce-logo-2.png'" alt="Rounded Image" class="rounded" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
