<template>
  <div class="page-header clear-filter" filter-color="orange">
    <div
      class="page-header-image"
      style="background-image: url('/img/login.jpg')"
    ></div>
    <div class="content">
      <div class="container">
        <div class="col-md-5 ml-auto mr-auto">

            <div
                    class="section section-signup"
                    style="background: none;padding-top: 50px;"
            >
                <div class="container">
                    <div>
                        <h1>Email Verification</h1>
                        <p>
                            A verification email has been sent to the registered email address.
                            Please verify your account by clicking on the link enclosed within the verification email.
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
    import { Card, FormGroupInput, Button } from '@/components';
    import { register, googleLoginCheck, facebookLoginCheck, resetLink } from '@/api/user';
    import { setToken, setAccountName, getToken, setTmpAccountNo, getTmpAccountNo, getInvite} from '@/utils/auth';

    export default {
        metaInfo: {
            title: 'Verify Account',
            meta: [
                { hid: 'description', name: 'description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Verify Account' },
                { hid: 'og:description', name: 'og:description', content: 'TokCommerce is a Chinese shopping agent platform.Help customers buy goods from taobao.Buying your favorite items from China has never been more convenient and easier.' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {

        },
        data() {
            return {

            }
        },
        methods: {

        },
    };
</script>


<style></style>
