<template>
  <div class="page-header clear-filter">
    <div class="content">
      <div class="container">
        <div class="col-md-12 ml-auto mr-auto">

            <div style="background: none;padding-top: 10px;">
                <div class="container" style="color: black;">

                    <h2>{{$t('page_parcel_detail.title')}}</h2>

                    <div v-if="currentOrder">

                        <div style="text-align: left;">
                            <p>{{$t('page_my_parcel.delivery_number')}}: {{currentOrder.delivery_number}}</p>
                            <p>{{$t('page_wallet.time')}}: {{currentOrder.pay_time}}</p>
                            <p>{{$t('page_wallet.status')}}: {{currentOrder.order_status_text}}</p>
                            <p>{{$t('page_shopping_payment.total')}}: ${{currentOrder.total_amount}}</p>
                            <p>{{$t('my_warehouse.actual_weight')}}: {{currentOrder.total_weight}} KG</p>
                            <p>{{$t('page_my_order.remark')}}: {{currentOrder.shipping_remark}}</p>
                            <p>{{$t('page_parcel_detail.coupon_code')}}: {{currentOrder.coupon_code}}</p>
                            <p>{{$t('page_parcel_detail.user_remark')}}: {{currentOrder.user_parcel_note}}</p>
                        </div>

                        <h3 style="margin-top: 30px;">{{$t('page_parcel_detail.tracking_info')}}</h3>
                        <vue-markdown :source="currentOrder.tracking_links"></vue-markdown>

                        <el-table
                                empty-text="Your order is empty"
                                :data="order_list"
                                tooltip-effect="dark"
                                style="width: 100%;margin-top: 30px;"
                        >
                            <el-table-column
                                    width="200px;"
                                    prop="order_id"
                                    label="Order No">
                            </el-table-column>
                            <el-table-column
                                    label="Item"
                                    width="120px;">
                                <template slot-scope="scope">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="scope.row.product_image_url"
                                            :preview-src-list="[scope.row.product_image_url]">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="Warehouse Pic"
                                    width="150px;">
                                <template slot-scope="scope">
                                    <el-image
                                            v-if="scope.row.qc_image_url[0]"
                                            style="width: 60px; height: 60px"
                                            :src="scope.row.qc_image_url[0].url || ''"
                                            :preview-src-list="[scope.row.qc_image_url[0].url || '']">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_name"
                                    label="Product Name">
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_sku"
                                    label="Details">
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_remark"
                                    label="Remark">
                            </el-table-column>
                            <el-table-column
                                    prop="product_quantity"
                                    label="Quantity">
                            </el-table-column>
                        </el-table>




                    </div>


                </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
    import { getDeliveryOrderDetail } from '@/api/shopping'
    import VueMarkdown from "vue-markdown"

    export default {
        components: {
            VueMarkdown,
        },
        data() {
            return {
                search_form: {
                    page: 1,
                    page_size: 20,
                    order_status: ''
                },
                orders: [],
                totalOrders: 0,
                order_detail_id: "",
                delivery_number: '',
                order_list: [],
                currentOrder: null,
            }
        },
        created() {
            const delivery_number = this.$route.query.delivery_number || ''
            if (!delivery_number) {
                this.$router.push('/')
            }

            this.delivery_number = delivery_number
            this.getDetail()
        },
        methods: {
            getDetail() {
                this.$message({message: 'Loading now, please wait.', type: 'warning'});
                getDeliveryOrderDetail({delivery_number: this.delivery_number}).then(res => {
                    if (res.code === 0) {
                        this.order_list.push(res.data)
                        this.order_list = res.data.list || []
                        this.currentOrder = res.data || {}
                    } else {
                        this.$message({message: 'Failed to retrieve order details', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Failed to retrieve order details', type: 'error'});
                })

            },

        },
    };
</script>


<style></style>
