<template>
  <div class="guides-page">
    <div>
      <h1>
        TokCommerce as your Shopping Agent
      </h1>
      <p>Posted on 21 April 2022</p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#what">What is a Shopping Agent?</a></li>
        <li><a href="#reason1">Competitive Freight Forwarding Fees</a></li>
        <li><a href="#reason2">Personalized Customer Support</a></li>
        <li><a href="#reason3">One-Stop Solution for Shopping and Forwarding Services</a></li>
      </ol>
    </div>
    <div>
      <b id="what" class="subtitle">
        What is a Shopping Agent?
      </b>
      <p>
        A shopping agent is a third-party platform between you and a store/seller to solve language, payment and
        logistics issues. These can include language barriers, high freight forwarding costs, or limited shipping
        destinations that are available on a store’s/seller’s platform.
      </p>
      <p>
        Here at TokCommerce, we are an up-and-coming shopping agent for China’s E-Commerce websites,
        such as Taobao, Tmall, 1688, etc.
      </p>
      <p>
        We provide an all-English platform to help you overcome the language barrier that you may encounter when using
        Chinese E-Commerce websites.
      </p>
      <p>
        We also ship to many more destinations around the World, so that you can shop from China, wherever you may be.
      </p>
      <p>
        Below are 3 reasons why you should use TokCommerce as your shopping agent for shopping from China's E-Commerce
        websites.
      </p>
    </div>
    <div>
      <b id="reason1" class="subtitle">1. Competitive Freight Forwarding Fees</b>
      <p>
        TokCommerce has a network of shipping experts and veterans with over 20 years of experience in the industry,
        as well as a plethora of shipping lines and companies to choose from.
      </p>
      <p>
        This ensures that our freight forwarding fees are competitive and affordable, while ensuring quality and efficiency in shipping.
      </p>
    </div>
    <div>
      <b id="reason2" class="subtitle">2. Personalized Customer Support</b>
      <p>
        From the moment your product or package arrives that our TokCommerce warehouse to its arrival at your doorstep,
        you will have a customer support agent assigned to you.
      </p>
      <p>
        Your assigned agent will contact and liaise with you via email to aid you through the process of making international
        freight forwarding arrangements and payment.
      </p>
      <p>
        Your assigned agent can also advise you on preferable shipping lines if you are unsure of which to choose for yourself.
      </p>
      <p>
        This way, you are able to get personalized support for all your orders without the hassle of having to contact
        customer support and waiting for an agent to attend to you. At the same time, your assigned agent will have all
        the information about your order to assist you as best as possible, so rest assured that you will be in safe hands.
      </p>
      <p>
        In addition, if you have any special requests that you would like to make for your products or packages,
        feel free to let your assigned agent know. TokCommerce will try to service them to the best of our abilities.
      </p>
    </div>
    <div>
      <b id="reason3" class="subtitle">3. One-Step Solution for Shopping and Forwarding Services</b>
      <p>
        Here at TokCommerce, we provide a one-stop solution for shopping and forwarding services for you, allowing you
        to access to both services with only a single TokCommerce account.
      </p>
      <p>
        This way, you can also consolidate all of your orders, be it shopping or forwarding orders, at our TokCommerce
        warehouse and ship them as one package at your convenience.
      </p>
      <p>
        Alternatively, you can also join any of our social media communities and post your queries there for our
        community members or customer staff members to reply to them.
      </p>
    </div>
    <b class="subtitle">
      Thank you for using TokCommerce and we look forward to serving you again in the future!
    </b>
    <BackToTopAndHomeButtons></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideReasonsShoppingAgent",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'Your Shopping Agent',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: TokCommerce as your Shopping Agent.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Your Shopping Agent' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: TokCommerce as your Shopping Agent.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-reasons-shopping-agent' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
