<template>
  <div>
    <div style="text-align: center">
      <b style="font-size: 30px">{{$t('page_home.contact_use_title')}}</b>
      <p>{{$t('page_home.contact_use_des')}}</p>
    </div>
    <el-form
      :inline="false"
      :model="question_form"
      ref="question_form"
      class="demo-form-inline"
      style="margin: 10px 30%">
      <el-form-item required prop="name">
        <el-input placeholder="Name" v-model="question_form.name"></el-input>
      </el-form-item>
      <el-form-item required prop="email_address">
        <el-input placeholder="Email Address" v-model="question_form.email_address"></el-input>
      </el-form-item>
      <el-form-item required prop="question">
        <el-input
            type="textarea"
            :rows="4"
            placeholder="Question"
            v-model="question_form.question"></el-input>
      </el-form-item>
      <div style="display: flex;justify-content: center;margin-top: 30px;">
        <el-button size="small" plain type="success" @click="submitQuestion('question_form')" style="margin: auto;">{{$t('page_home.submit_question')}}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {submitQuestion} from "../api/customerservice";

export default {
  name: "CustomerServiceSection",
  data() {
    return {
      question_form: {
        name: '',
        email_address: '',
        question: ''
      }
    }
  },
  methods: {
    submitQuestion(form_name){
      this.$message({message: 'Submitting your question, please wait...', type: 'warning'})
      this.$refs[form_name].validate((valid => {
        if (!valid) {
          this.$message({message: 'Please check your input', type: 'warning'});
          return
        }
        submitQuestion(this.question_form).then((res) => {
          if (res.code === 0){
            this.$message({message: 'Question submitted', type: 'success'});
            this.$refs[form_name].resetFields();
          } else {
            this.$message({message: 'Failed to submit question', type: 'error'});
          }
        })
      }))
    }
  }
}
</script>

<style scoped>

</style>
