<template>
  <div id="top" class="guides-page">
    <div>
      <h1>
        TokCommerce User Guide
      </h1>
      <p>Posted on 13 April 2022</p>
    </div>
    <div>
      <h2>
        Table of Contents
      </h2>
      <ol>
        <li><a href="#account-manage">Account Management</a></li>
        <li><a href="#shopping">Shopping Service</a></li>
        <li><a href="#forwarding">Freight Forwarding Service</a></li>
        <li><a href="#calculator">Shipping Calculator</a></li>
        <li><a href="#order-manage">Order Management</a></li>
      </ol>
    </div>
    <div>
      <p>
        TokCommerce is a logistics platform that provides a one-stop solution for importing from the Chinese E-Commerce
        market to the World.
      </p>
      <p>
        This guide aims to familiarize you to the interface of our TokCommerce website, as well as to guide you
        through the various services that we provide on it.
      </p>
    </div>
    <div>
      <b id="account-manage" class="subtitle">
        Account Management
      </b>
      <p>
        In order to start using our services, you must first be a registered user on our TokCommerce platform.
      </p>
      <p>
        If you already have an existing account, you can log into your account <a href="/login">here.</a>
      </p>
      <p>
        Otherwise, you can register for a new account <a href="/register">here.</a>
      </p>
      <p>
        <em>
          Note: Please provide a valid email address since we will be sending a verification email to your email
          address after account registration. Please also make sure that you are active on the provided email address
          as we will be sending you updates and information about your orders in the form of emails to your verified
          email address.
        </em>
      </p>
      <p>
        Once you are a registered user on our platform, you can view information about your account under the
        “Account” tab in the header section of our website.
      </p>
    </div>
    <div>
      <b id="shopping" class="subtitle">
        Shopping Service
      </b>
      <p>
        Navigate to our Shopping Service page by clicking on the “Shopping” tab in the header section of our website.
        You can also directly access the page <a href="/search">here.</a>
      </p>
      <p>
        Once you are on the Shopping Service page, you can follow our step-by-step guide to shopping using TokCommerce
        at this <a href="/guides/guide-taobao-tokcommerce">link.</a>
      </p>
    </div>
    <div>
      <b id="forwarding" class="subtitle">
        Freight Forwarding Service
      </b>
      <p>
        Navigate to our Freight Forwarding Service page by clicking on the “Forwarding” tab in the header section of our website.
        You can also directly access the page <a href="/service/forwarding">here.</a>
      </p>
      <p>
        Once you are on the Freight Forwarding Service page, you can follow our step-by-step guide to freight forwarding using
        TokCommerce at this <a href="/guides/guide-freight-forwarding">link.</a>
      </p>
    </div>
    <div>
      <b id="calculator" class="subtitle">
        Shipping Calculator
      </b>
      <p>
        Our TokCommerce platform also provides an online shipping calculator for you to estimate your freight forwarding
        costs before submitting an order and making payment for it.
      </p>
      <p>
        Navigate to our Shipping Calculator page by clicking on the “Services” tab in the header section of our website and
        selecting “Shipping Calculator” from the dropdown menu.
        You can also directly access the page <a href="/service/shipping-calculate">here.</a>
      </p>
      <p>
        Once you are on the Shipping Calculator page, fill in your destination country and the weight and dimensions
        of your package to estimate your freight forwarding costs.
      </p>
      <p>
        <em>
          Our shipping calculator estimates shipping costs based on data on the most updated weekly shipping rates of
          different shipping companies to different countries. While we aim to provide the most accurate shipping costs
          estimates to our customers, other factors such as sudden fluctuations in shipping rates as well as delays
          in arrival of products or packages at our warehouse can affect some of these estimates and result in certain
          inaccuracies in the final shipping costs calculated upon payment. We seek your understanding with this matter
          and welcome any queries with regards to this matter via email or any one of our social media communities.
        </em>
      </p>
    </div>
    <div>
      <b id="order-manage" class="subtitle">
        Order Management
      </b>
      <p>
        You can manage your orders by selecting “My Orders” from the dropdown menu after clicking the “Account” button
        in the header section of our website. All the information about your orders can be found on this page.
      </p>
      <p>
        Shopping orders that are awaiting the first payment or arrival at our TokCommerce warehouse will appear under
        the “My Orders” tab in the left panel. Shipping orders that have been submitted but have yet to arrive at our
        warehouse will also appear under this tab.
      </p>
      <p>
        Orders that have arrived at our warehouse and are awaiting forwarding arrangements and payment will appear
        under the “In Warehouse” tab.
      </p>
      <p>
        Orders that have been shipped out of our warehouse will appear under the “Int’l Parcels” tab.
      </p>
      <p>
        Orders that have been completed will appear under the “Order History” tab.
      </p>
      <p>
        If you have further queries on how to use our TokCommerce website, you can also join any of our social media
        communities and post your queries there for our community members or customer staff members to reply to them.
      </p>
    </div>
    <b class="subtitle">
      Thank you for using and supporting TokCommerce!
    </b>
    <BackToTopAndHomeButtons top_id="guide-user-guide#top"></BackToTopAndHomeButtons>
  </div>
</template>

<script>
import BackToTopAndHomeButtons from "../components/BackToTopAndHomeButtons";
export default {
  name: "GuideUserGuide",
  components: {BackToTopAndHomeButtons},
  metaInfo: {
    title: 'User Guide',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: How to shop from Taobao using TokCommerce.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'User Guide' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: How to shop from Taobao using TokCommerce.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-user-guide' },
    ],
  },
}
</script>

<style scoped>
.subtitle{
  font-size: 20px;
  /*top: -120px;*/
  /*display: block;*/
  /*position: relative;*/
  /*visibility: hidden;*/
}

.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 25%;
  }
}
</style>
