import request_api from '@/utils/request_api'

// 获取 关于我们 MarkDown 文件
export function getAboutUs(data) {
  return request_api({
      url: '/api/document/get_about_us',
      method: 'post',
      data
  })
}

// 获取 服务条款 MarkDown 文件
export function getTermsOfService(data) {
  return request_api({
      url: '/api/document/get_terms_of_service',
      method: 'post',
      data
  })
}

  // 获取 关于我们 MarkDown 文件
export function getPrivacyPolicy(data) {
  return request_api({
    url: '/api/document/get_privacy_policy',
    method: 'post',
    data
  })
}

export function getMarkdowns(data) {
  return request_api({
    url: '/api/document/get_markdowns',
    method: 'post',
    data
  })
}
