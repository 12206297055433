<template>
  <div>
    <div style="background-color: #ffffff; height: 900px;padding-left: 10px; padding-right: 10px;padding-top: 100px;">
      <h1>{{$t('service_menu')}}</h1>

      <div>

        <router-link to="/search">
          <div class="menu">
            <span>{{$t('shopping_agent')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/service/forwarding">
          <div class="menu">
            <span>{{$t('forwarding')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/service/source">
          <div class="menu">
            <span>{{$t('expert_buy')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
<!--        <router-link to="/service/shipping-calculate">-->
<!--          <div class="menu">-->
<!--            <span>{{$t('shipping_calculator')}}</span>-->
<!--            <span> > </span>-->
<!--          </div>-->
<!--          <el-divider></el-divider>-->
<!--        </router-link>-->
        <router-link to="/service/customer-review">
          <div class="menu">
            <span>{{$t('customer_review')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/service/dropshipping">
          <div class="menu">
            <span>{{$t('dropshipping')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/service/business-service">
          <div class="menu">
            <span>{{$t('business_service')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/service/trust-seller">
          <div class="menu">
            <span>{{$t('trusted_seller')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/blog/list">
          <div class="menu">
            <span>{{$t('blog')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>
        <router-link to="/about-us">
          <div class="menu">
            <span>{{$t('about')}}</span>
            <span> > </span>
          </div>
          <el-divider></el-divider>
        </router-link>


      </div>

    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  metaInfo: {
    title: 'Services Menu - TokCommerce',
    meta: [
      { hid: 'description', name: 'description', content: 'Services Menu' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'Services Menu' },
      { hid: 'og:description', name: 'og:description', content: 'Services Menu' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
    ],
  },
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {

    };
  },
  methods: {

  },

};
</script>
<style>
.guides-page{
  margin: 30px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 30px 25%;
  }
}
  .menu {
    display:flex;justify-content: space-between;
  }
</style>
