<template>
  <div class="guides-page">
    <div>
      <h1 class="centralise-text">
        TokCommerce Guides
      </h1>
      <h5 class="centralise-text">
        Whether you are a new user or an experienced customer, we have a guide for you!
      </h5>
    </div>
    <div class="guides-card-section">
      <h2 class="centralise-text">
        For New Users
      </h2>
      <div class="guides-card-row">
        <GuidesCard
            v-for="user_guide in user_guide_list"
            :key="user_guide.key"
            :title="user_guide.title"
            :link="user_guide.link"
            :description="user_guide.description"
        />
      </div>

      <div class="guides-card-section">
        <h2 class="centralise-text">
          Service Guides
        </h2>
        <div class="guides-card-row">
          <GuidesCard
              v-for="service_guide in service_guide_list"
              :key="service_guide.key"
              :title="service_guide.title"
              :link="service_guide.link"
              :description="service_guide.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuidesCard from "../components/GuidesCard";
export default {
  name: "GuideMain",
  components: {GuidesCard},
  metaInfo: {
    title: 'User Guide',
    meta: [
      { hid: 'description', name: 'description', content: 'TokCommerce: How to shop from Taobao by TokCommerce.' },
      { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
      { hid: 'og:type', name: 'og:type', content: 'website' },
      { hid: 'og:title', name: 'og:title', content: 'User Guide' },
      { hid: 'og:description', name: 'og:description', content: 'TokCommerce: How to shop from Taobao by TokCommerce.' },
      { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
      { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/guides/guide-main' },
    ],
  },
  data(){
    return{
      user_guide_list: [
        {
          title: "TokCommerce User Guide",
          link: "/guides/guide-user-guide",
          description: "Find out more about our TokCommerce platform and services!"
        },
        {
          title: "TokCommerce as your Shopping Agent",
          link: "/guides/guide-reasons-shopping-agent",
          description: "Learn about why TokCommerce is the best shopping agent for you!"
        }
      ],
      service_guide_list: [
        {
          link: "/guides/guide-taobao-tokcommerce",
          title: "Taobao Shopping Guide using TokCommerce",
          description: "A comprehensive step-by-step guide to shopping on Taobao using TokCommerce as your shopping agent!"
        },
        {
          title: "Freight Forwarding Guide using TokCommerce",
          link: "/guides/guide-freight-forwarding",
          description: "An in-depth step-by-step guide to freight forwarding using TokCommerce!"
        },
        {
          title: "Best Taobao Agent Of 2022 | TokCommerce",
          link: "/guides/taobao-agent",
          description: "A high-quality Taobao agent platform. Cheap, convenient, efficient and good service."
        },
        {
          title: "How to choose a Taobao agent to buy from taobao - TokCommerce",
          link: "/guides/how-to-choose-taobao-agent",
          description: "Choose a better shopping agent and save more costs"
        },
        {
          title: "How TokCommerce serves you as a Taobao Agent and Forwarder",
          link: "/guides/guide-tokcommerce-shopping-forwarding",
          description: "One-to-one service to help you buy products from Taobao. Best shopping agent from China."
        },
        {
          title: "Best 1688 Shopping Agent to help you source products easier - TokCommerce",
          link: "/guides/best1688agent",
          description: "1688 Shopping Agent helps you source from the largest E-commerce platforms in China"
        },
        {
          title: "Cheapest Taobao Shopping Agent and Forwarder - TokCommerce",
          link: "/guides/taobao-forwarder-cheapest",
          description: "TokCommerce: Cheapest Taobao Forwarder for global users"
        },
        {
          title: "Taobao Forwarder to the USA of 2022 - TokCommerce",
          link: "/guides/taobao-forwarder-to-usa",
          description: "TokCommerce: Cheapest and Most Efficient Taobao Agent and Forwarder"
        }
      ]
    }
  }
}
</script>

<style scoped>
.centralise-text{
  text-align: center;
}
.guides-card-row{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.guides-card-section{
  margin-top: 40px;
}
.guides-page{
  margin: 120px 10%
}

@media screen and (min-width: 1200px) {
  .guides-page{
    margin: 120px 15%;
  }
  .guides-card-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    margin-left: 30%;
    margin-right: 30%;
  }
}
</style>
