<template>
    <div>
        <el-alert
                title="Orders submitted before April are displayed here. This page is expected to be deprecated in June of this year. All future orders will be displayed in My Order. If you have any questions, please contact us by email."
                type="warning">
        </el-alert>


        <el-table
                empty-text="Your order is empty"
                :data="orders"
                tooltip-effect="dark"
                style="width: 100%;margin-top: 30px;"

                >
            <el-table-column
                    prop="order_id"
                    label="Order No">
            </el-table-column>
            <el-table-column
                    prop="order_type_text"
                    label="Order Type">
            </el-table-column>
            <el-table-column
                    prop="pay_time"
                    label="Pay Time">
            </el-table-column>
            <el-table-column
                    prop="total_amount"
                    label="Total/USD">
            </el-table-column>
            <el-table-column
                    prop="order_status_text"
                    label="Status">
            </el-table-column>
            <el-table-column
                    label="Operate">
                <template slot-scope="scope">

                    <el-button type="danger"  @click="pay_way_visible = true; virtual_order = scope.row.virtual_order; pay_current_order = scope.row;" :aria-disabled="false" v-if="scope.row.virtual_order == 0 && scope.row.order_status == 1 && scope.row.total_amount > 0">
                        Pay Order
                    </el-button>
                    <el-button type="danger" @click="pay_way_visible = true; virtual_order = scope.row.virtual_order; pay_current_order = scope.row;" :aria-disabled="false" v-if="scope.row.virtual_order == 1 && scope.row.pay_status == 0 && scope.row.external_order_price > 0">
                        Pay Order
                    </el-button>

                    <el-link type="primary" :href="'/order-detail?order_id=' + scope.row.order_id" target="_blank">View Order</el-link>
                </template>
            </el-table-column>
        </el-table>


        <!--支付弹框 只支持信用卡-->
        <el-dialog title="Pay Order"  style="margin-top: 20px;" :visible="pay_way_visible" @close="pay_way_visible = false; paypal_pay_btn = false; credit_card_pay_btn = false;">
            <div style="display: flex;justify-content: space-between;margin-top: 40px;">
                <el-card class="box-card">
                    <el-radio v-model="paybtn" label="paypal" border>Paypal</el-radio>
                    <el-radio v-model="paybtn" label="creditcard" border disabled>Credit Card</el-radio>

                    <el-divider></el-divider>

                    <el-button type="success"  @click="paypalConfirm()" class="sf-button--outline order-button" style="margin-top: 10px;" :aria-disabled="false">
                        Pay Order
                    </el-button>
                </el-card>
            </div>
        </el-dialog>
    </div>
</template>
<script>

    import { getOrderList, virtualHandlePayment } from '@/api/orders'
    import { payOrder, getCreditCardSign } from '@/api/orders'

    export default {
        name: 'OrderHistory',
        components: {

        },
        props: {

        },
        data() {
            return {
                search_form: {
                    page: 1,
                    page_size: 20,
                    order_status: ''
                },
                orders: [],
                totalOrders: 0,
                currentOrder: null,
                order_detail_id: "",
                order_id: '',
                pay_way_visible: false,
                pay_way: 1,
                virtual_order: '',//订单类型
                pay_current_order: {},
                sign_data: {},
                credit_card_submit: {
                },
                credit_card_pay_btn: false,
                paypal_pay_btn: false,
                select_pay_btn: '',
                paybtn: 'paypal',
            };
        },
        created() {
            this.getOrders()
        },
        methods: {
            getOrders() {
                getOrderList(this.search_form).then(res => {
                    if (res.code === 0) {
                        this.orders = res.data.list || [];
                        this.totalOrders = res.data.total || 0;
                    } else {
                        this.$message({message: 'Failed to retrieve order history', type: 'error'});
                    }
                })
            },
            payOrder(order_data) {
                payOrder(order_data).then(res => {
                    if (res.code === 0 && res.data != '') {
                        window.location = res.data || ''
                    } else {
                        this.$message({message: 'Failed To Pay', type: 'error'});
                    }
                })
            },
            payVirtualOrder(virtual_order_id) {
                let params = {
                    pay_way: 1,
                    virtual_order_id: virtual_order_id
                }
                virtualHandlePayment(params).then(res => {
                    if (res.code === 0 && res.data != '') {
                        window.location = res.data || ''
                    } else {
                        this.$message({message: 'Failed To Pay', type: 'error'});
                    }
                })
            },
            paypalConfirm() {
                if (this.virtual_order == 0) {
                    this.payOrder(this.pay_current_order)
                } else {
                    this.payVirtualOrder(this.pay_current_order.virtual_order_id)
                }
            },
        }
    };
</script>

<style>


</style>
