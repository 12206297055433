<template>
  <div class="page-header clear-filter">
    <div class="content">
      <div class="container">
        <div class="col-md-12 ml-auto mr-auto">

            <div style="background: none;padding-top: 10px;">
                <div class="container" style="color: black;">

                    <h2>{{$t('page_order_detail.title')}}</h2>

                    <div v-if="currentOrder">

                        <div style="text-align: left;">
                            <p>{{$t('page_shopping_payment.order_no')}} : {{currentOrder.order_id}}</p>
                            <p v-if="currentOrder.external_order_id">Store Order No : {{currentOrder.external_order_id}}</p>

                            <p>{{$t('page_my_order.type')}} : {{currentOrder.order_type_text}}</p>
                            <p>{{$t('page_wallet.time')}} : {{currentOrder.pay_time}}</p>
                            <p>{{$t('page_wallet.status')}} : {{currentOrder.order_status_text}}</p>
                            <p>{{$t('page_shopping_payment.total')}} : ${{currentOrder.total_amount}}</p>
                        </div>

                        <el-table
                                empty-text="Your order is empty"
                                :data="order_list"
                                tooltip-effect="dark"
                                style="width: 100%;margin-top: 30px;"
                        >
                            <el-table-column
                                    :label="$t('page_cart.item')"
                                    width="150px;">
                                <template slot-scope="scope">
                                    {{$t('page_my_order.product_pic')}}<br>
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="scope.row.product_image_url"
                                            :preview-src-list="[scope.row.product_image_url]">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </el-image><br>
                                    {{$t('page_my_order.product_qc')}} <br>
                                    <div v-for="item in scope.row.qc_image_url" :key="item.url" style="display:inline">
                                        <el-image
                                                style="width: 60px; height: 60px;margin-left: 3px;"
                                                :src="item.url"
                                                :preview-src-list="[item.url]">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    :label="$t('page_my_order.cn_track')"
                                    prop="tracking_number_cn"
                                    width="120px;">
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_name"
                                    :label="$t('page_cart.product')">
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_sku"
                                    :label="$t('page_my_order.details')">
                            </el-table-column>
                            <el-table-column
                                    width="200px;"
                                    prop="product_remark"
                                    :label="$t('page_my_order.remark')">
                            </el-table-column>
                            <el-table-column
                                    prop="product_quantity"
                                    :label="$t('page_cart.quantity')">
                            </el-table-column>
                            <el-table-column
                                    :label="$t('page_my_order.type')"
                                    prop="order_type_text"
                            >
                            </el-table-column>
                        </el-table>


                    </div>

<!--                    <SfTab title="Update Address" v-if ="currentOrder">-->
<!--                        <SfHeading-->
<!--                                :level="3"-->
<!--                                :title="'Order ID: ' + order_detail_id"-->
<!--                        />-->
<!--                        <ShippingAddressFormOrder-->
<!--                                :address="address_form"-->
<!--                                :isNew="false"-->
<!--                                :showDefault="false"-->
<!--                                @submit="updateAddress"-->
<!--                        />-->
<!--                    </SfTab>-->

                </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
    import { getShoppingOrderDetail } from '@/api/shopping'

    export default {
        components: {

        },
        data() {
            return {
                search_form: {
                    page: 1,
                    page_size: 20,
                    order_status: ''
                },
                orders: [],
                totalOrders: 0,
                currentOrder: null,
                order_detail_id: "",
                order_id: '',
                order_list: [],
            }
        },
        created() {
            const order_id = this.$route.query.order_id || ''
            if (!order_id) {
                this.$router.push('/')
            }

            this.order_detail_id = order_id
            this.getDetail()
        },
        methods: {
            getDetail() {
                this.order_detail_id = this.$route.query.order_id//要处理的订单详情
                if (!this.order_detail_id) {
                    return
                }
                this.$message({message: 'Loading now, please wait.', type: 'warning'});
                getShoppingOrderDetail({order_id: this.order_detail_id}).then(res => {
                    if (res.code === 0) {
                        this.currentOrder = res.data;
                        this.order_list.push(res.data)
                    } else {
                        this.$message({message: 'Failed to retrieve order details', type: 'error'});
                    }
                }).catch(error => {
                    this.$message({message: 'Failed to retrieve order details', type: 'error'});
                })

            },

        },
    };
</script>


<style></style>
