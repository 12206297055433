<template>
  <div class="page-header clear-filter">
    <div
      class="page-header-image"
      style="background-image: url('/img/login/login-bg.png')"
    ></div>
    <div class="content">
      <div class="">
        <div class="col-md-5 ml-auto mr-auto">

            <div
                    class="section section-signup"
                    style="background: none;padding-top: 50px;"
            >
                <div class="container">
                    <div class="row">
                        <card class="card-signup" header-classes="text-center" color="orange">
                            <template slot="header">
                                <h3 class="card-title title-up">Sign Up</h3>
                            </template>
                            <template>
                                <fg-input
                                        class="no-border"
                                        placeholder="Nick Name..."
                                        addon-left-icon="now-ui-icons users_circle-08"
                                        v-model="register_form.username"
                                >
                                </fg-input>

                                <fg-input
                                        class="no-border"
                                        placeholder="Email..."
                                        addon-left-icon="now-ui-icons ui-1_email-85"
                                        v-model="register_form.email"
                                >
                                </fg-input>

                                <fg-input
                                        class="no-border"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="now-ui-icons text_caps-small"
                                        v-model="register_form.password"
                                >
                                </fg-input>

                                <fg-input
                                        class="no-border"
                                        placeholder="Confirm Password"
                                        type="password"
                                        addon-left-icon="now-ui-icons text_caps-small"
                                        v-model="register_form.confirmPass"
                                >
                                </fg-input>
                            </template>
                            <div class="">
                                <p>{{register_notice}}</p>
                                <n-button type="neutral" v-if="!isSubmitting" round size="lg" @click="Register">SIGN UP</n-button>
                            </div>

                            <div class="col text-center">
                                <router-link
                                        to="/login"
                                        class="btn btn-simple btn-round btn-white btn-lg"
                                >
                                    Sign in
                                </router-link>
                            </div>

                        </card>
                        <div style="margin: auto;">
                            <img :src="'/img/login/singupbonus.png'" alt="TokCommerce: sign up bonus" style="margin-top: 10px;margin-bottom: 50px;width: 350px;" class="rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
    import { Card, FormGroupInput, Button } from '@/components';
    import { register, googleLoginCheck, facebookLoginCheck, resetLink } from '@/api/user';
    import { setToken, setAccountName, getToken, setTmpAccountNo, getTmpAccountNo, getInvite} from '@/utils/auth';

    export default {
        metaInfo: {
            title: 'Register Page - TokCommerce',
            meta: [
                { hid: 'description', name: 'description', content: 'Register Page' },
                { hid: 'fb:app_id', name: 'fb:app_id', content: '1092107274927967' },
                { hid: 'og:type', name: 'og:type', content: 'website' },
                { hid: 'og:title', name: 'og:title', content: 'Register Page' },
                { hid: 'og:description', name: 'og:description', content: 'Register Page' },
                { hid: 'og:image', name: 'og:image', content: 'https://tokcommerce.com/img/logo.png'},
                { hid: 'og:url', name: 'og:url', content: 'https://tokcommerce.com/' },
            ],
        },
        components: {
            Card,
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput
        },
        data() {
            return {
                register_form: {
                    email: '',
                    username: '',
                    password: '',
                    confirmPass: '',
                },
                isSubmitting: false,
                register_notice: '',
            }
        },
        methods: {
            Register() {
                const allFieldsFilled = !(this.register_form.username == "" | this.register_form.password == "" | this.register_form.email == "" | this.register_form.confirmPass == "");
                let params = {
                    invite_code: getInvite(),
                    account_no: this.register_form.email,
                    account_name: this.register_form.username,
                    account_pass: this.register_form.password,
                    account_pass_confirm: this.register_form.confirmPass,
                    app_id: this.app_id || 'app_1',
                    domain_url: window.location.protocol + "//" + window.location.host + '/api/active_user_account',//获取当前域名 + url
                }

                if (this.register_form.password != this.register_form.confirmPass) { // checks if string in password and confirm password are the same
                    //密码不一致
                    this.$message({message: 'The two passwords do not match', type: 'error'});
                    return;
                }
                if (allFieldsFilled){
                    this.$message({message: 'Submit now, please wait', type: 'warning'});

                    this.isSubmitting = true
                    register(params).then(response => {
                        if (response.code == 0) {
                            this.$router.push('/verify_account')

                            this.$message({message: 'Register Success', type: 'success'});
                        } else {
                            this.register_notice = response.msg || 'Registration error'
                            this.$message({message: response.msg || 'Registration error', type: 'error'});
                        }
                        this.isSubmitting = false
                    }).catch(error => {
                        this.$message({message: 'Registration error', type: 'error'});
                        this.isSubmitting = false
                    });
                } else {
                    this.$message({message: 'Please check your input', type: 'error'});
                }
            },
        },
    };
</script>


<style></style>
