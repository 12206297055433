module.exports = {
  app_name: 'TokCommerce',
  shopping_agent: '代购',
  forwarding: '转运',
  services: '服务',
  about: '关于',
  expert_buy: '专业购买',
  shipping_calculator: '运费估算',
  customer_review: '服务评测',
  dropshipping: '一件代发',
  business_service: '商业服务',
  trusted_seller: '靠谱卖家',
  blog: '博客',
  user_guides: '新手教程',
  terms_of_service: '服务条款',
  terms_of_shipping: '发货条款',
  privacy_policy: '隐私条例',
  faq: '常见问答',
  home: '主页',
  notice: '消息',
  cart: '购物车',
  me: '我的',
  service_menu: '服务菜单',
  account: '账号',
  user_center: '用户中心',
  my_order: '我的订单',
  my_store: '我的店铺',
  log_out: '退出',
  sign_in: '登录',
  sign_up: '注册',
  compensation_taxation: '赔偿和清关',
  tok_forum: '社区',
  get_community_help: '加入社区',
  community_des: '加入我们的社区帮助别人和得到问题解答',
  page_home: {
    title: '购买国货, 全球转运',
    description: '最实惠的国货代购平台',
    search_product_text: '请输入产品链接',
    who_we_are_title: '关于我们',
    who_we_are_line1: '我们是一个专业的国货代购平台。帮助全球用户从淘宝、1688、京东等国内电商平台代购和转运。',
    who_we_are_line2: '更加实惠的代购、转运服务商。高效的全球物流服务和专业的服务团队让每一位客户使用得放心。',
    our_services: '我们的服务',
    service_shopping_agent: '从中国最大的电商平台购买国货',
    service_forwarding_agent: '从中国转运你的包裹',
    service_expert_buy: '更加专业的买手为你购买商品',
    service_dropshipping: '为你的线上店铺履约订单',
    shop_now: '开始购买',
    ship_now: '开始转运',
    logistics_service_title: '我们提供了非常高效和实惠的物流服务',
    calculate_now: '查询全球国际物流价格',
    calculate: '查询费用',
    products: '产品服务',
    products_des: '提供多平台海量产品搜索服务',
    warehousing: '仓储服务',
    warehousing_des: '免费30天存储你的全部包裹',
    packing: '包装服务',
    packing_des: '零费用定制产品包装',
    forwarding_des: '全球包裹转运',
    quality_check: '产品质检',
    quality_check_des: '免费拆包质检,质量把关',
    dropshipping_des: '提供一件代发服务，成就你的商业',
    latest_shopping: '最新购买',
    why_tokcommerce: '为什么选择我们?',
    why_tokcommerce_line1: '来自中国更专业的代购服务商。 我们专注于为全球客户提供淘宝/1688/微店/Yupoo代购服务。 一站式的购物体验，让每一位客户使用起来更轻松。 专业的客服和优质的售后服务解决您的一切烦恼。',
    why_tokcommerce_line2: '作为专业的代购服务商，我们帮助你从中国最大的国内在线购物网站以超低的价格购买各种产品。 我们是您从中国主流电子商务平台寻找、购买、检查和交付商品的独家代理。',
    why_tokcommerce_line3: '我们提供高效廉价的国际物流服务，服务于美国、加拿大、英国、澳大利亚、法国、新加坡、比利时、瑞典、南非等多个国家。 无论您身在何处，我们都会将包裹直接送到您家门口。 我们为所有海外货件提供在线跟踪信息。',
    contact_use_title: '联系我们',
    contact_use_des: '提交你的问题，我们通过邮件回复',
    submit_question: '提交问题',
    video_title: '我们的视频',
    customer_support: '客户服务',
    service_email: '客服邮箱',
    b2b_email: '商业客户邮箱',
    destination: '目的国',
    shipping_price: '价格',
    method: '运输方式',
  },
  page_shopping: {
    shopping_des: '最实惠的代购服务商, 从这里开始购买国货',
    shopping_step: '1.查找商品 2.支付商品费用 3.为你购买 4.支付国际物流费 5.发货',
    category: '热门类目',
    load_more_items: '加载更多...',
    note: '注意: 我们的全部商品展示来源于中国大型电商平台, 我们不直接售卖任何商品. 如有质量问题，我们会协助你和第三方卖家进行联系.',
    bottom_text: '作为专业的淘宝代理商，我们帮助您从中国最大的国内在线购物网站以超低的价格购买各种产品。 我们是您的淘宝代理商，可以从淘宝网查找、购买、检查和交付商品。 我们的报价包括从中国采购、购买和国际运输。TokCommerce为从中国到所有国家的国际运输提供最大的折扣，包括美国、加拿大、英国、澳大利亚、法国、新加坡、墨西哥、巴西、智利等。无论您身在何处，我们都将包裹直接送到您家门口。 我们为所有海外运输提供在线跟踪信息。 我们有着专业的客服经理帮助你顺利购物和发货。',
    process_data: '订单进度',
    process_des: '您可以了解有关您的订单的所有信息。 每次您关心时，我们都有数据可以告诉您一切。',
  },
  page_forwarding: {
    title: '国际包裹转运',
    des: '从中国开始转运你的包裹到世界各地',
    forwarding_step: '1.购买商品 2.寄到我们仓库 3.商品质检 4.支付物流费 5.发货',
    country_name: '目的国',
    product_name: '商品名',
    tracking_number: '商品跟踪号',
    tracking_number_des: '淘宝、微店等跟踪号',
    product_limit: '商品图片 (限制1M)',
    submit_text: '提交包裹信息',
    submit_note: '记得将包裹寄到我们的仓库; 我们不再支持超大件商品海运服务, 有需要的客户请联系客服特殊处理.',
    how_to_use: '如何使用我们的转运服务？',
    service_line1: '1. 你需要自己在淘宝、1688、微店等电商平台购买商品并寄到我们的仓库.',
    service_line2: '2. 一旦有了包裹跟踪号，需要在此页面提交一个转运订单.',
    service_line3: '3. 等待包裹进入我们的仓库。我们会进行商品质检.',
    service_line4: '4. 一旦我们检查完你的包裹，我们会给你发一封邮件.',
    service_line5: '5. 提交收件人地址, 支付国际物流费',
    service_line6: '6. 从我们仓库发货',
    cost_note: '在使用我们的服务之前, 请仔细查看我们的物流费用明细.',
    view_forwarding: '查看转运订单',
    warehouse_address: '仓库地址',
    item_category: '可运商品',
    warehouse_phone: '手机号',
    warehouse_contact_name: '收件人',
    warehouse_province: '省份',
    warehouse_city: '城市',
    warehouse_district: '区/县',
    warehouse_street: '街道',
    warehouse_detail: '详细地址',
    warehouse_postcode: '邮编',
    service_name: '转运服务',
    service_des: '我们的货运代理服务是我们在 TokCommerce 为您提供的主要服务之一。 无论您是从像淘宝这样的大型电子商务网站购买，还是您已经在中国有想要运送的产品，TokCommerce 都能满足您的需求。通过与在该行业拥有 40 多年经验的物流公司合作，例如 FedEx、DHL、UPS 等，TokCommerce 旨在以实惠的价格提供高效优质的货运代理。无论您身在世界何处，TokCommerce 都会找到一种方法将您的包裹安全送到您家门口，让您安心。',
  },
  page_expert: {
    des: '无法查找商品? 在此页面提交商品信息, 专员为你购买',
    past_product_name: '请输入商品名称',
    product_url: '商品链接',
    unit_price: '商品单价',
    past_unit_price: '请输入商品价格',
    quality: '数量',
    delivery_fee: '运费',
    delivery_fee_note: '中国本地运费, 不确定请输入0',
    product_remark: '商品备注',
    product_remark_note: '例如: 颜色、尺寸、版本',
    add_to_cart: '加入购物车',
    step_line1: '1. 从淘宝、京东、1688等平台查找商品',
    step_line2: '2. 复制商品信息并加入购物车',
    step_line3: '3. 进入购物车提交订单并支付',
    step_line4: '4. 我们购买并等待包裹入库',
    step_line5: '5. 提交收件人地址并支付国际物流费',
    about_expert_buy: '关于专业购买',
    about_des: '我们的专业购买功能是 TokCommerce 为您提供的代购服务的一部分。 如果我们的代购页面无法通过您的产品 URL 链接获取您产品的数据，您可以使用我们的专业购买功能。 它支持来自所有主要中国电子商务网站的产品 URL 链接，例如淘宝、1688、天猫、微店等等。 如果您能找到它，我们可以为您购买和运送，无论您在世界的哪个地方！ 使用我们的代购指南了解更多关于如何使用我们的专业购买功能的信息。',
    product_shipping_note: '当前页面的运费仅包括卖家发货到我们仓库的费用, 包裹到了仓库需要支付国际物流费',
    product_detail_remark: '输入商品的备注: 如颜色、尺寸、版本等特殊信息',
    expert_btn: '如果无法加入购物车, 请点击#专业购买#按钮',
    product_show_price: '售价',
    product_show_quantity: '数量',
    product_show_shipping: '运费',
  },
  page_calculator: {
    des: '更加实惠的国际运费，更快的物流时效',
    item_category: '商品类目',
    product_weight: '重量',
    product_length: '长',
    product_width: '宽',
    product_height: '高',
    check: '查询费用',
    cost_note: '注意: 对于UPS/DHL/FedEx, 包裹起重量必须是2.5KG. 否则只能使用Tok Line',
    about_service: '我们的运费计算器是我们在 TokCommerce 网站上提供的一项补充功能，是我们的代购和货运代理服务的一部分。 使用我们的运费计算器，您可以根据产品或包裹的尺寸以及目的地国家/地区估算您需要支付的大致运费。 您还可以使用我们的运输计算器来检查可运送到您的目的地国家/地区的不同运输公司，以及它们各自的估计运输时间。',
    service_title: '关于运费试算',
    common_goods: '普通商品',
    brand_goods: '国际品牌',
    liquid_goods: '液体粉末',
    battery_goods: '电子商品',
    shipping_method: '运输方式',
    delivery_time: '运输时间',
    warehouse: '仓库',
    estimated_cost: '预估费用',
    method: '运输方式',
  },
  page_message: {
    title: '消息',
    des: '请留意我们的后台消息和我们的邮件',
  },
  page_cart: {
    title: '购物车',
    item: '图片',
    product: '商品',
    price: '价格',
    quantity: '数量',
    freight: '运费',
    total: '总费用',
    info: '详情',
    operate: '操作',
    pay_now: '去结算',
    confirm_buy: '确认购买吗？',
    yes:'确认',
    no:'取消',
  },
  page_settlement: {
    title: '订单结算',
    information: '注意事项',
    note_line1: '1. 免费的商品质检拍照',
    note_line2: '2. 支付金额包含了商品费用和交易手续费',
    note_line3: '3. 我们会通过邮件告知你全部订单状态的变化',
    note_line4: '4. 一旦你的包裹到了我们仓库, 你需要支付国际物流费',
    note_line5: '5. 支付/退款/退货产生的交易费/杂费由客户承担',
    address_note: '请选择目的国以确保我们知道是否可以将包裹寄给你',
    submit_order: '提交订单',
    confirm_submit: '确认提交？',
  },
  page_shopping_payment: {
    title: '结算',
    des: "注意: 此页面用于支付商品费用, 国际运费支付请到 #我的包裹# 页面",
    total: '总费用',
    order_no: '订单号',
    pay_now: '支付',
    shopping_notice: '注意事项:',
  },
  page_person: {
    account: '账号',
    user_center: '用户中心',
    wallet: '钱包',
    orders: '订单管理',
    my_order: '我的订单',
    my_warehouse: '我的仓库',
    my_parcel: '我的包裹',
    address_book: '地址管理',
    online_store: '线上店铺',
    my_store: '我的店铺',
    activity: '活动',
    invite: '邀请',
    coupons: '优惠券',
  },
  page_seller: {
    title: '靠谱卖家',
    buy_hanfu_title: '从优质靠谱的卖家中购买中国汉服, 我们为你发货',
    buy_sneaker_title: '从靠谱的卖家中购买运动鞋, 我们为你发货',
    buy_cosplay_title: '从靠谱的卖家中购买Cosplay, 我们为你发货',
    step_line1: '1. 从卖家店铺中搜索商品',
    step_line2: '2. 提交购物订单',
    step_line3: '3. 为你发货',
  },
  page_profile: {
    account: '账号',
    email: '邮箱',
    balance: '余额',
  },
  page_wallet: {
    top_up: '充值',
    underpaid: '少付',
    balance_details: '余额明细',
    time: '时间',
    empty: '无记录',
    amount: '金额',
    status: '状态',
    info: '明细',
    pay: '支付',
    paid: '已付',
    confirm: '确认支付',
    discount_info: '优惠明细',
    and_get: '获得',
    get_extra: '额外优惠赠送',
  },
  page_my_order: {
    product_pic: '商品图',
    product_qc: '质检图',
    store_order_no: '店铺订单',
    type: '类型',
    details: '详情',
    remark: '备注',
    process: '进度',
    cn_track: '卖家跟踪号',
    pay_order: '支付订单',
    search: '查询',
  },
  page_order_detail: {
    title: '订单详情',
  },
  my_warehouse: {
    submit_to_ship: '提交发货',
    actual_weight: '实重',
    add_address: '添加收件人地址',
    select_method: '选择运输方式',
    pay_order: '支付物流费',
    consignee_name: '收件人',
    country_code: '目的国',
    consignee_province: '省/洲',
    consignee_city: '城市',
    consignee_address_detail: '详细地址',
    consignee_postcode: '邮编',
    consignee_phone: '手机/电话',
    consignee_email: '邮箱',
    next: '下一步',
    shipping_cost: '物流费',
    method_note: '管理员会为你选择物流方式, 请继续下一步并确认',
    submit_order: '提交地址',
    parcel_remark: '包裹备注: 个性化打包你的包裹',
    such_as_parcel: '(比如: 移除纸盒)',
  },
  page_my_parcel: {
    delivery_number: '包裹单号',
    pay_shipping: '支付物流费',
    address: '地址',
    tracking: '跟踪号',
  },
  page_parcel_detail: {
    title: '我的包裹',
    coupon_code: '优惠码',
    user_remark: '包裹备注',
    tracking_info: '跟踪号',
  },
  page_customer_review: {
    des: '向更多人分享我们的服务',
    submit_link: '提交内容链接',
  },
  page_dropshipping: {
    des: '让你的商业更加成功',
  },
  page_invite: {
    your_share_link: '你的分享链接',
    invitation_service: '邀请好友 - 你将获得更多',
    invitation_analysis: '邀请数据',
    get_cash_benefits: '获得现金: 0.5USD 每位邀请用户',
    cheap_cost: '获得更加优惠的物流价格',
    find_product: '免费为你查找更好的商品',
    invitation_users: '邀请人数',
    invitation_amount: '邀请销售额',
  },
  page_coupon: {
    available: '可用优惠券',
    used: '已使用优惠券',
    expired: '已过期',
  },
  page_taxation: {
    title: '赔偿和清关',
    disclaimer: '免责声明',
    d_line1: '1、由于自然灾害或海关原因等不可抗力造成的邮件丢失不予赔偿',
    d_line2: '2、寄递的物品违反禁寄或限寄规定的，经主管机关没收或依照有关法规处理的不予赔偿',
    d_line3: '3、由于客户的责任或所寄物品本身的原因造成邮件丢失或内件短少的不予赔偿',
    d_line4: '4、国际邮件被寄达国按其国内法令扣留、没收或销毁的不予赔偿',
    d_line5: '5、涉及到交税的包裹，用户自行承担',
    d_line6: '6、非TokCommerce本身的服务问题， 运费不退',
    d_line7: '7、非以上问题, 需要赔偿的包裹最高不超过400RMB',
  },
  meta: {
    index_title: 'TokCommerce: 专业的华人留学生代购、国际转运平台',
    index_des: 'TokCommerce是一个面向海外华人、留学生的代购平台。为海外的用户提供一站式的代购+转运服务。为海外用户代购国货。无服务费、付款方便、物流费低、60天免费仓储。新人注册即可获得接近 ￥650 注册奖励.',
    shopping_agent_title: '国货代购 - TokCommerce',
    shopping_agent_des: 'TokCommerce: 可信任的国货代购平台。为海外华人、留学生代购转运国货。多种支付方式可选。更低的物流价格。更快的国际物流运输服务。免费一对一客服。中国代购选TokCommerce',
    forwarding_title: '国际转运 - 从中国转运到世界各地',
    forwarding_des: '专业的全球转运平台，拥有转运仓库并提供寄往美国,英国,加拿大,德国,澳大利亚,新加坡,墨西哥等多个国家的专业物流服务,转运全球. 淘宝转运, 国货转运. ',
    expert_buy_title: '专业购买 - TokCommerce',
    expert_buy_des: '从代购服务商购买淘宝商品。从中国批发商品。转运到世界各地。更低的物流价格。无服务费。',
    calculator_title: '国际运费预估 - TokCommerce',
    calculator_des: '帮助你预估全球物流费用。实惠的物流服务。更快的物流服务。',
    trusted_seller_title: '优质卖家汇总 - TokCommerce',
    trusted_seller_des: '从优质的卖家店铺购买商品。 我们将包裹寄给你。淘宝卖家，微店卖家，京东卖家。',
  },
}
